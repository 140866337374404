import React, {Component} from 'react';
import { Redirect } from 'react-router-dom'
import userImage from '../../img/user.png'

var config = require('./Config')

export default class Header extends Component {
    constructor(props) {
        super(props);
        
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        console.log(userLogin)
        this.state = {
            firstname : userLogin.firstname,
            lastname : userLogin.lastname,
            menu: ""
        }
        
    }

    renderPage = () => {
        const { menu } = this.state

        if (menu == "profile") {
            localStorage.setItem('menu', "profile");
            return <Redirect to={config.PROFILE} />
        }else if (menu == "logout") {
            localStorage.clear()
            return <Redirect to={config.LOGIN} />
        }
        
    }
    
    handleClick = (e) => {
        e.preventDefault();
        this.setState({
            menu: e.target.id
        })
    }
    
    render(){
        const { firstname,lastname} = this.state;

        return (
            <header className="main-header">
                <a href="" id="redirect_login" hidden></a>
                <a href="" className="logo" style={{backgroundColor : "rgba(237, 27, 47, 0.76)"}}>
                    <span className="logo-mini" ><i className="fa fa-car"></i></span>
                    <span className="logo-lg" ><b>Toyota</b></span>
                </a>
                <nav className="navbar navbar-static-top" style={{backgroundColor : "#ed1b2f"}}>
                    <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
                        <span className="sr-only">Toggle navigation</span>
                    </a>
                    <div className="navbar-custom-menu">
                        <ul className="nav navbar-nav">
                        {this.renderPage()}
                            {/* <li className="dropdown messages-menu">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                    <i className="fa fa-envelope-o"></i>
                                    <span className="label label-success">4</span>
                                </a>
                                <ul className="dropdown-menu">
                                    <li className="header">You have 4 messages</li>
                                    <li>
                                        <ul className="menu">
                                            <li>
                                                <a href="#">
                                                    <div className="pull-left">
                                                        <img src="img/user2-160x160.jpg" className="img-circle" alt="User Image" />
                                                    </div>
                                                    <h4>
                                                        Support Team
                                                        <small><i className="fa fa-clock-o"></i> 5 mins</small>
                                                    </h4>
                                                    <p>Why not buy a new awesome theme?</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li> */}

                            <li className="dropdown user user-menu">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                    <i className="fa fa-gear"></i>
                                </a>
                                
                                <ul className="dropdown-menu">
                                <li className="user-header" style={{backgroundColor : "rgb(237, 27, 47)",cursor : "pointer"}}>
                                    <a href="#" onClick={this.handleClick} id="profile">
                                        <img src={userImage} className="img-circle" alt="User Image" style={{width : "100px"}}/> 
                                        <p><small style={{fontSize : "14px",color:"white"}}>{firstname}  {lastname}</small> </p>
                                    </a>
                                </li>
                                <li className="user-footer">
                                    {/* <div className="pull-left">
                                    <a href="#" className="btn btn-default btn-flat">Profile</a>
                                    </div> */}
                                    <div className="pull-right">
                                    <a href="#" onClick={this.handleClick} id="logout" className="btn btn-default btn-flat">Sign out</a>
                                    </div>
                                </li>
                                </ul>
                            </li>

                        </ul>
                    </div>
                </nav>
            </header>
        )
    }
}