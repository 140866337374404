import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import dateFormat from 'dateformat';
import "react-datepicker/dist/react-datepicker.css";
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import loadgif from '../../img/loading.gif'
import importPng from '../../img/import_csv.png'
import exportPng from '../../img/export_csv.png'
import { CSVLink } from "react-csv";
import excelImage from '../../img/excel.png'

var config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.seriesData){
            window.location = config.LOGIN
        }

        this.state = {
            permission : userLogin.permission.seriesData,
            token : userLogin.token,
            loading:false,
            upload : false,
            roleId : "0",
            searchKey : "",
            sortBy : "createdAt",
            seriesCode : "",
            colorCode :"",
            master : {},
            colors : {},
            csvData : [],
            export_file : "",
            show : "table",
            title : "",
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 50,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            },
          calendarStart : false,
          calendarStop : false,
          startDate : new Date(),
          stopDate :null
        };

        this.onStartDateChange = this.onStartDateChange.bind(this);
    }

    componentDidMount() {
        this.handleFetch(1)
        this.handleGetMaster()
        this.handleGetColor()
    }

    handleFetch(page){
        const {pages,sortBy, token} = this.state;

        if(page < 1 || page > pages.totalPages){
            return
        }
        document.getElementById('search-key').value = ""
        this.setState({loading:true , searchKey : ""})
       
        const url = `${config.BASE_URL}/v1/series?page=${page}&sortBy=${sortBy}&perpage=${pages.perPage}`; 
        //console.log("Fetch")
        //console.log(url)
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                console.log(result.data)
                this.setState({pages: result.data});
                
                setTimeout(() => {
                    this.setState({loading:false ,show : "table"})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });

    }

    handleExport(){
        const {pages,sortBy, token} = this.state;

        const url = `${config.BASE_URL}/v1/series?page=1&sortBy=${sortBy}&perpage=${pages.totalDocs}`; 
        
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            
            if(result.status){
                 console.log(result.data.docs)
                 var data = []
                
                 data.push([
                    'No.',
                    'Series Code' ,
                    'Series Name',
                    'Series Name(Th)',
                    'Model',
                    'SFX',
                    'Description',
                    'Color Code',
                    'Color',
                    'Color LTD',
                    'Sequence',
                    'Start Date',
                    'End Date',
                    'Status'
                  
                 ])
                 console.log( result.data.docs)
                 var i = 1;
                 result.data.docs.forEach(val => {
                    var startDate = ""
                    var endDate = ""
                   

                    if(val.startDate){
                        startDate =val.startDate.split('-').reverse().join('-')
                    }
                    if(val.endDate){
                        endDate = val.endDate.split('-').reverse().join('-')
                    }

                    

                        var obj = [
                            i++,
                            val.seriesCode,
                            val.seriesName,
                            val.seriesNameTH,
                            val.model,
                            val.sfx,
                            val.modelDescription,
                            `'${val.colorCode}`,
                            val.color,
                            val.colorLTD,
                            val.sequence,
                            startDate,
                            endDate,
                            val.status
                           
                        ]
                        //console.log(obj)
                        data.push(obj)
                    })
                    

                var datetime = dateFormat(Date.now(),'ddmmyyyy_HHMMss')
                var export_file = `export_series_${datetime}.csv`
                
               this.setState({ csvData : data ,export_file : export_file})
                setTimeout(() => {
                     document.getElementById("csvdownload").click()
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
            
        }).catch(error => {
            this.handleError(error)
        });
    }


    handleGetMaster(){
        const { token ,seriesCode } = this.state;

        const url = `${config.BASE_URL}/v1/seriesMaster?page1&sortBy=&perpage=2000&status=Active`; 

        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
             
                this.setState({master: result.data.docs , seriesCode : result.data.docs[0].seriesCode});
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });

    }

    handleGetColor(){
        const {token} = this.state;

        const url = `${config.BASE_URL}/v1/color?page=1&sortBy=&perpage=1000`; 
        
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            console.log(result)
            if(result.status){
                
                this.setState({colors: result.data.docs ,colorCode : result.data.docs[0].colorCode});
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });

    }

    handleDownloadClick() {
       
        setTimeout(() => {
          const response = {
            file: `${config.BASE_URL}/v1/csv/series.csv`
          };
         
          window.open(response.file);
         
        }, 100);
    }

    handleSearchClick(page){
        var key = document.getElementById("search-key").value;
        if(key == ""){
            return
        }

        this.setState({loading:true , searchKey : key})

        setTimeout(() => {
            this.handleSearch(1)
          },500);
        
    }

    handleSearch(page){
        const {pages,searchKey,sortBy, roleId,token} = this.state;
        const url = `${config.BASE_URL}/v1/series/search`; 
        this.setState({loading:true })

        var params ={
            "key" : searchKey ,
            "roleId" : roleId ,
            "page" : page ,
            "perPage" : pages.perPage,
            "sortBy" : sortBy
        }
        //console.log("Search")
       // console.log(params)
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false ,show : "table"})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });
    }

    handlePageClick(page, event){ 
        const {searchKey} = this.state;
        //console.log("search " + searchKey)
        if(searchKey){
            
            this.handleSearch(page)
        }
        else{
            this.handleFetch(page)
        }
    
    }

    handleSortClick(page, event){ 
        const {searchKey} = this.state;
        var sortKey = document.getElementById('sort-key').value
        var sortOptions={
            "Series Code" : "seriesCode",
            "Series Name" : "seriesName",
            "Series Name TH" : "seriesNameTH",
            "Model"    : "model",
            "SFX"  : "sfx",
            "Description"  : "modelDescription",
            "Color Code"  : "colorCode",
            "Color"  : "color",
            "Color LTD"  : "colorLTD"
        }
        var sortBy = sortOptions[sortKey]
        this.setState({sortBy:sortBy});
    
        setTimeout(() => {
            if(searchKey){
                this.handleSearch(page)
            }
            else{
                this.handleFetch(page)
            }
          },500);
        
        
    }

    handleCreateClick(value, event){
        const { master ,colors} = this.state

        document.getElementById("seriesId").value = -1
       // document.getElementById("seriesCode").value = ""
        document.getElementById("seriesNameTH").value = master[0].seriesNameTH
        document.getElementById("seriesName").value =  master[0].seriesName
        document.getElementById("model").value =""
        document.getElementById("sfx").value = ""
        document.getElementById("modelDescription").value = ""
       // document.getElementById("colorCode").value = ""
        document.getElementById("colorName").value = colors[0].color
        document.getElementById("colorLTD").value =  colors[0].colorLTD
        document.getElementById("sequence").value = 0
        document.getElementById("status").value = "Active"
       // document.getElementById("startDate").value = dateFormat(Date.now(),'dd-mm-yyyy')
       // document.getElementById("stopDate").value = dateFormat(Date.now(),'dd-mm-yyyy')
        this.setState({startDate :  new Date() ,stopDate : "" })
       
        this.setState({seriesCode : master[0].seriesCode , colorCode : colors[0].colorCode})

        this.setState({show : 'add',title : "Create"})
    
    }

    handleEditClick(id, event){
        const { pages } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
       console.log(result)
        document.getElementById("seriesId").value = result[0]._id
       // document.getElementById("seriesCode").value = result[0].seriesCode
        document.getElementById("seriesNameTH").value = result[0].seriesNameTH
        document.getElementById("seriesName").value = result[0].seriesName
        document.getElementById("model").value = result[0].model
        document.getElementById("sfx").value = result[0].sfx
        document.getElementById("modelDescription").value = result[0].modelDescription
       // document.getElementById("colorCode").value = result[0].colorCode
        document.getElementById("colorName").value = result[0].color
        document.getElementById("colorLTD").value = result[0].colorLTD
        document.getElementById("sequence").value =  result[0].sequence
        document.getElementById("status").value = result[0].status
        if(result[0].startDate){
            this.setState({startDate :  new Date( result[0].startDate ) })
        }

       if(result[0].endDate){
            this.setState({stopDate :  new Date( result[0].endDate ) })
           
       }

        this.setState({seriesCode :  result[0].seriesCode , colorCode :  result[0].colorCode.toString() })
        this.setState({show : 'add',title : "Update"})
    }

    handleSaveClick(value, event){
        const {token,startDate,stopDate ,seriesCode ,colorCode} = this.state;
        
        var id = document.getElementById("seriesId").value
        var seriesNameTH = document.getElementById("seriesNameTH").value
        var seriesName = document.getElementById("seriesName").value
        var model = document.getElementById("model").value
        var sfx = document.getElementById("sfx").value
        var modelDescription = document.getElementById("modelDescription").value
        var color = document.getElementById("colorName").value
        var colorLTD = document.getElementById("colorLTD").value
        var sequence = document.getElementById("sequence").value
        var status = document.getElementById("status").value
        if(seriesCode == "" || seriesName=="" || model=="" || sfx==""){
            alert("Please fill required filed.")
            return;
        }
        
        var formData = new FormData()
        formData.append('id', id)
        formData.append('seriesCode', seriesCode)
        formData.append('seriesNameTH', seriesNameTH)
        formData.append('seriesName', seriesName)
        formData.append('model', model)
        formData.append('sfx', sfx)
        formData.append('modelDescription', modelDescription)
        formData.append('colorCode', colorCode)
        formData.append('color', color)
        formData.append('colorLTD', colorLTD)
        formData.append('sequence', sequence)
        formData.append('startDate', startDate)
        formData.append('endDate', stopDate)
        formData.append('status', status)
        // const browserFiles = document.querySelector('input[name="seriesfile"]')
        // for (const file of browserFiles.files) {
           
        //     formData.append('file',file,file.filename)
            
        // }

        var act = (id == -1) ? 'create' : 'update'
        const url = `${config.BASE_URL}/v1/series/${act}`; 
        
        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`
            },
            body: formData
        }).then((response) => {
            return response.json();
        }).then((result) => {
            //.log(result)
            if(result.status){
                //this.modalAction("modal-add-series","close")
                this.handleFetch(1)
            }
            else{
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.handleError(error)
        });
    }

    

    handleDeleteClick(id, event){
        const {token} = this.state;
        confirmAlert({
            title: 'Confirm',
            message: "Do you want to delete this serie?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                   
                    const url = `${config.BASE_URL}/v1/series/delete`;
                
                    this.setState({ loading: true })
            
                     fetch(url, {
                        method: 'post',
                         headers: {
                            'authorization': `basic ${token}`,
                             'Content-Type': 'application/x-www-form-urlencoded',
                         },
                         body: `id=${id}`
                     }).then(function(response) {
                         return response.json();
                        }).then((result) => {
                            //console.log(result)
                            if(result.status){
                                this.handleFetch(1)
                            }
                            else{
                                this.handleError(result.message)
                            }
                               
                        }).catch(error => {
                            this.handleError(error)
                        });
                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                }
              }
            ]
          })
    }


    onSetResult = (value, result) => {
        
        if (result["status"] == true){
            window.location.reload();
        }else{
            alert('Your request is not complete seriesname or email is already.'+JSON.stringify(result));
        }

        const { loading } = this.state;
        this.setState({ loading: !loading })
    }

  
    
    modalAction(id,status){
        
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }

   

    handleFileUpload(value){
        const {  token } = this.state;
       
        const browserFiles = document.querySelector('input[type="file"]')
      
        const url = `${config.BASE_URL}/v1/series/upload`;
        //console.log(url)
        
        var formData = new FormData()
       
        for (const file of browserFiles.files) {
            //console.log(file)
            formData.append('file',file,file.name)
            
        }
        
        this.setState({ upload: true })

        fetch(url, {
            method: 'POST',
            headers: {
                'authorization': `basic ${token}`
             },
            body: formData
        }).then(function(response) {
            return response.json();
        }).then(result => this.onSetFileResult(value, result)).catch(error => {
            this.setState({ loading: false ,upload : false});
            alert(error)
        });
        
    }

    onSetFileResult = (value, result) => {
        this.setState({ upload: false })
        if (result["status"] == true){

            //alert("Successfully");
            this.modalAction("modal-import","close")
            this.handleFetch(1)
        }else{
            this.handleError(result.message)
        }

    }


    setCalendar(div){
        const { calendarStart,calendarStop} = this.state;
        if(div == "start"){
            this.setState({calendarStart : !calendarStart});
        }
        if(div == "stop"){
            this.setState({calendarStop : !calendarStop});
        }
        if(div == "all"){
            this.setState({calendarStart : false});
            this.setState({calendarStop : false});
        }
    }

    onStartDateChange(date){
        this.setState({startDate : date});
    }

    onStopDateChange(date){
        console.log(date)
        this.setState({stopDate : date});
    }
    
    handleSeriesChange(val){
        const { master } = this.state

        var result = master.filter(obj => {
            return obj.seriesCode == val.value
        })

        document.getElementById("seriesNameTH").value = result[0].seriesNameTH
        document.getElementById("seriesName").value = result[0].seriesName
    
        this.setState({ seriesCode : val.value})
    }

    handleColorChange(val){
        const { colors } = this.state
        var result = colors.filter(obj => {
            return obj.colorCode == val.value
        })
        document.getElementById("colorName").value = result[0].color
        document.getElementById("colorLTD").value = result[0].colorLTD
        this.setState({ colorCode : val.value})
       
    }

    handleShowClick(show){
        this.setState({show : show});
    }
   

    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }
    
    render(){
        const { pages, loading ,searchKey,startDate,stopDate,upload,permission,show,title ,csvData,export_file} = this.state;
        const { master,colors ,seriesCode ,colorCode} = this.state;
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            var contents = null
            if(pages.docs.length > 0){
                contents = []
                for(var index in pages.docs){
                    let item = pages.docs[index]
                    let startDate = ""
                    let endDate = ""
                    let image = ""

                    if(item["image"]){
                        image =  (<img src={ config.BASE_URL + '/v1/image/?path=' +item["image"]} style={{width : "130px"}}/>)
                    }
                    if(item["startDate"]){
                        startDate = item["startDate"].split('-').reverse().join('-')
                    }
                    if(item["endDate"]){
                        endDate = item["endDate"].split('-').reverse().join('-')
                    }

                    contents.push(
                       
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{ parseInt(pages.pageIndex) + parseInt(index)}</td>
                       
                        <td align="center">{item["seriesCode"]}</td>
                        <td align="center">{item["seriesName"]}</td>
                        <td align="center">{item["seriesNameTH"]}</td>
                        <td align="center">{item["model"]}</td>
                        <td align="center">{item["sfx"]}</td>
                        <td align="center">{item["modelDescription"]}</td>
                        <td align="center">{item["colorCode"]}</td>
                        <td align="center">{item["color"]}</td>
                        <td align="center">{item["colorLTD"]}</td>
                        <td align="center">{item["sequence"]}</td>
                        <td align="center">{startDate }</td>
                        <td align="center">{endDate}</td>
                        <td align="center">{item["status"]}</td>

                        <td align="center"className={(permission.edit) ? "" : "hidden"}>
                            
                            <button type="button" onClick={this.handleEditClick.bind(this, item["_id"])} className="btn btn-block btn-warning">Edit</button>

                        </td> 
                        <td align="center" className={(permission.delete) ? "" : "hidden"}>
                        
                            <button type="button" disable={loading.toString()} onClick={this.handleDeleteClick.bind(this, item["_id"])} className="btn btn-block btn-danger">Delete</button>
                        
                        </td>
                    </tr>
                    )
                    
                }
            }
        }
        
        var serie_options = []
        for(var i in master){
            serie_options.push( { value: master[i].seriesCode, label: master[i].seriesCode } )     
        }

        var color_options = []
        for(var i in colors){
            color_options.push( { value: colors[i].colorCode, label: colors[i].colorCode } )     
        }
      

        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>Series Management</h3>

                                <div className={(permission.import) ? "" : "hidden"}>
                                <span style={{ float : "right",cursor: "pointer"}}>    
                                    <button type="button"  className="btn btn-success" style={{ width : "150px",marginTop : "16px"}} onClick={this.handleExport.bind(this)}>
                                      <img src={excelImage}  style={{width : '25px'}}/>
                                        Export
                                    </button> 
                                    <CSVLink id="csvdownload" data={csvData}  filename={export_file}></CSVLink>
                                </span>
                                <span style={{ float : "right",cursor: "pointer",marginRight : "10px"}}>  
                                < a href={`${config.BASE_URL}/v1/csv/series.csv`}>
                                    <button type="button"  className="btn btn-success" style={{ width : "150px",marginTop : "16px"}} >
                                      <img src={exportPng} style={{width : '25px'}}/>
                                        Export Form
                                    </button> 
                                </a>
                                </span>
                                <span style={{ float : "right",cursor: "pointer"}}>
                                    <button type="button"  className="btn btn-primary" style={{ width : "150px",marginTop : "16px",marginRight : "10px"}} onClick={this.modalAction.bind(this,"modal-import","open")}>
                                    <img src={importPng} style={{width : '25px'}}/>
                                        Import
                                    </button> 
                                </span>
                                </div>
                            </div>
                          
                            <div className={show== "table" ? "box" : "hidden"}>
                                <div className="box-header">
                                    <div className={(permission.create) ? "" : "hidden"}>
                                      <div className= 'pull-left' style={{cursor : "pointer"}} onClick={this.handleCreateClick.bind(this)}>
                                          <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px"}}>
                                              <i className="fa fa fa-plus"></i>
                                              <span>&nbsp;Create</span>
                                              <span className="pull-right-container"></span>
                                          </a>
                                      </div>
                                      </div>
                                  </div>

                                  <div className="box-header">
                                  <div className="pull-left">
                                          <div className="input-group mb-3">
                                              <input type="text" id="search-key" style={{height : "34px"}}/>
                                              
                                                  <button className="btn btn-outline-secondary btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                                  <button className="btn btn-outline-secondary btn-reset" onClick={this.handleFetch.bind(this,1)} type="button">Reset</button>
                                          </div>
                                      </div>

                                    <div className="pull-right">
                                          <label>Sort By</label>
                                              <select  id="sort-key" style={{width : "200px",  height : "30px",marginLeft : "5px"}}>
                                                <option>Series Code</option>
                                                <option>Series Name</option>
                                                <option>Series Name TH</option>
                                                <option>Model</option>
                                                <option>SFX</option>
                                                <option>Description</option>
                                                <option >Color Code</option>
                                                <option >Color</option>
                                                <option>Color LTD</option>
                                              </select>
                                              <button className="btn btn-outline-primary btn-submit" onClick={this.handleSortClick.bind(this,1)} type="button">Filter</button>
                                    </div>
                                    
                                </div>
                                <div className="box-header">
                                   <span className={searchKey ? "" : "hidden"}>Seach "{searchKey}" ,please use reset button to refresh query.</span>
                                   <p>
                                   <span>Found <b>{pages.totalDocs}</b> record(s).</span>
                                   </p>
                                </div>

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ loading ? "hidden" : "box-body"} style={{overflowX : 'auto'}}>
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <style>{'th {text-align: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                No.
                                                                </th>

                                                               
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Series Code
                                                                </th>

                                                                <th width="100" className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Series Name
                                                                </th>
                                                                
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Series Name(Th)
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Model
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                SFX
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Description
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Color Code
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Color
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Color LTD
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Sequence
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Start Date
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                End Date
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Status
                                                                </th>

                                                        
                                                                <th  className={(permission.edit) ? "sorting" : "hidden"} tabIndex="3"  rowSpan="1" colSpan="1"  align="center" style={{width : '80px'}}>
                                                                    <span className='fa fa-edit'/> Edit
                                                                </th>
                                                                
                                                                <th   className={(permission.delete) ? "sorting" : "hidden"} tabIndex="4"  rowSpan="1" colSpan="1" style={{width : '80px'}}>
                                                                    <span className='fa fa-bitbucket'/> Delete
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           { contents }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage - 1)} className={currentPage > 10 ? "pageButton" : "hidden"}>
                                                                <i className="fa fa-backward" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage + 1)} className={currentPage < totalPages && pageList.length == 10 && totalPages > 10 ? "pageButton" : "hidden"}>
                                                                <i className="fa fa-forward" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                       
                                        </div>
                                    </div>
                                </div>
                                
                            <div className="modal" id="modal-import" tabIndex="-1" role="dialog">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Import</h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalAction.bind(this,"modal-import","close")} style={{marginTop : '-23px'}}>
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                        </div>
                                                        <div className="modal-body">
                                                       {/* <span className="file btn btn-lg btn-primary upload-div" > */}
							                               
							                                <input type="file" id="csv_file" name="file"/>
                                                           
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.modalAction.bind(this,"modal-import","close")}>Close</button>
                                                            <button type="button" className="btn btn-primary" onClick={this.handleFileUpload.bind(this)}>
                                                            <i className={upload ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>
                                                                Upload
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                            <div className={show== "add" ? "box" : "hidden"}>
                                <div className= "box-header" >
                                   
                                   <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleShowClick.bind(this,"table")}>
                                       <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px",backgroundColor : "black"}}>
                                       <i className="fa fa fa-remove"></i>
                                             <span style={{marginLeft : '5px'}}>Close</span>
                                           <span className="pull-right-container"></span>
                                       </a>
                                   </div>
                                   
                                 </div>

                                <div className="modal-content">
                                    <div className="modal-header">
                                         <h5 className="modal-title"><b>{title}</b></h5>
                                    </div>
                                    <div className="modal-body col-md-12" style={{height : "auto"}} >
                                                   
    
                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label className="label-text">Series Code</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <Select 
                                                                        value={serie_options.filter(option => option.value == seriesCode)}
                                                                        options={serie_options}
                                                                        onChange= {value => this.handleSeriesChange(value)}
                                                                       
                                                                    />
                                                                  
                                                                  
                                                                </div>
                                                                <div className="col-md-6">
                                                                <input type="hidden" className="form-control" id="seriesId"  />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2" >
                                                                    <label className="label-text2">Series Name</label>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <input type="text" className="form-control" id="seriesName"  style={{width : "100%"}} readOnly="readOnly"/>
                                                                </div>
                                                                <div className="col-md-3" >
                                                                    <label className="label-text">Series Name(Th)</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="seriesNameTH"  style={{width : "100%",fontSize : "13px"}}  readOnly="readOnly"/>
                                                                </div>
                                                            </div>


                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label className="label-text">Model</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="model"   style={{width : "100%"}} />
                                                                </div>
                                                                <div className="col-md-2 ">
                                                                    <label className="label-text">Sfx</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="sfx"  style={{width : "100%"}} />
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label className="label-text">Description</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="modelDescription"   style={{width : "90%"}} />
                                                                </div>
                                                            </div>
                                                           

                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label className="label-text">Color Code</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <Select 
                                                                        value={color_options.filter(option => option.value == colorCode)}
                                                                        options={color_options}
                                                                        onChange= {value => this.handleColorChange(value)}
                                                                    />
                                                                    {/* <input type="text" className="form-control" id="colorCode"  style={{width : "50%"}} /> */}
                                                                </div>
                                                                <div className="col-md-6">
                                                                    </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label className="label-text">Color</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="colorName"   style={{width : "100%"}}  readOnly="readOnly"/>
                                                                </div>
                                                                <div className="col-md-2 ">
                                                                    <label className="label-text">Color LTD</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="colorLTD"   style={{width : "100%"}}  readOnly="readOnly"/>
                                                                </div>
                                                            </div>


                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label className="label-text">Sequence</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="sequence"   style={{width : "100%"}} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                 
                                                                </div>
                                                            </div>

                                                          


                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label className="label-text">Start Date</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <DatePicker
                                                                            selected={startDate}
                                                                            onChange={this.onStartDateChange.bind(this)}
                                                                            dateFormat = "dd-MM-yyyy"
                                                                    />
 
                                                                </div>
                                                            </div>

                                                             <div className="row modal-box">
                                                                 <div className="col-md-2 ">
                                                                    <label className="label-text">End Date</label>
                                                                </div>
                                                                <div  className="col-md-10">
                                                                    <DatePicker
                                                                        selected={stopDate}
                                                                        onChange={this.onStopDateChange.bind(this)}
                                                                        dateFormat = "dd-MM-yyyy"
                                                                    />
                                                                  
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                 <div className="col-md-2 ">
                                                                    <label className="label-text">Status</label>
                                                                </div>
                                                                <div  className="col-md-10">
                                                                   <select id="status" className="form-control" style={{width : '20%'}}>
                                                                       <option>Active</option>
                                                                       <option>Inactive</option>
                                                                   </select>
                                                                  
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                    <div className="modal-footer" style={{textAlign : 'center'}}>
                                                            {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleShowClick.bind(this,"table")} style={{width : '150px',marginBottom : '20px'}}>Close</button> */}
                                                            <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : '150px',marginBottom : '20px',marginTop : '30px'}}>
                                                                <i className="fa fa-save"></i>
                                                                <span style={{marginLeft : '5px'}}>Save</span>
                                                            </button>
                                                        </div>
                                
                                </div>
                                                
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}