var isUat = true
var rootPath = isUat ? "":"/dev"
var ROOT_URL = (isUat) ? "https://service.toyotalocator.com" : 'https://service.toyotalocator.com'
var dateDistance = {
    "0": "0-3 เดือน",
    "4": "4-6 เดือน",
    "7": "7-9 เดือน",
    "10": "10-12 เดือน",
    "13": "มากกว่า 12 เดือน",
}

const formatter = function(number, decimal = 2) {
    var price = number //+ ((number * 7) / 100)
    return price.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

const distanceMonth = function(to) {
    const date1 = new Date();
    const date2 = new Date(to);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    const diff = parseInt(diffDays / 30)
    var diffMonth = 0
    if (diff > 0 && diff < 4) {
        diffMonth = 0
    }else if (diff > 3 && diff < 7) {
        diffMonth = 4
    }else if (diff > 6 && diff < 10) {
        diffMonth = 7
    }else if (diff > 9 && diff < 13) {
        diffMonth = 10
    }else {
        diffMonth = 13
    }   
    return dateDistance[`${diffMonth}`]
}

module.exports = {
    distanceMonth: distanceMonth,
    formatter:formatter,
    dateDistance: dateDistance,
    ROOTURL: `${ROOT_URL}`,
    BASE_URL : (isUat) ? `${ROOT_URL}/api` : `${ROOT_URL}/api-dev`,
    DASHBOARD: `${rootPath}/dashboard`,
    MASTER_TLT: `${rootPath}/masterTLT`,
    SUPPLY: `${rootPath}/supply`,
    PERMISSION: `${rootPath}/permission`,
    USER: `${rootPath}/user`,
    TMTUSER: `${rootPath}/tmtuser`,
    DEALERUSER: `${rootPath}/dealeruser`,
    DEALER: `${rootPath}/dealer`,
    COLORS_MANAGER: `${rootPath}/colors`,
    ZONE:`${rootPath}/zone`,
    PROFILE: `${rootPath}/profile`,
    COLOR : `${rootPath}/color`,
    SERIE: `${rootPath}/serie`,
    SERIESMASTER: `${rootPath}/seriesmaster`,
    SERIES: `${rootPath}/series`,
    PRICE: `${rootPath}/price`,
    AGREEMENT: `${rootPath}/agreement`,
    EMAIL_BUYER: `${rootPath}/emailbuyer`,
    EMAIL_SELLER: `${rootPath}/emailseller`,
    EMAIL_USEDCAR: `${rootPath}/emailusedcar`,
    EMAIL_DELIVERY: `${rootPath}/emaildelivery`,
    EMAIL_CONTACT: `${rootPath}/emailcontact`,
    POOL: `${rootPath}/pool`,
    LOCATOR : `${rootPath}/locator`,
    WTS : `${rootPath}/wts`,
    WTB : `${rootPath}/wtb`,
    DEADSTOCK : `${rootPath}/deadstock`,
    TRANSPORT : `${rootPath}/transport`,
    POOL_TRANSACTION : `${rootPath}/poolTransaction`,
    LOCATOR_TRANSACTION : `${rootPath}/locatorTransaction`,
    LOGIN: `${rootPath}/login`,
    ROOT: `${rootPath}`,
}