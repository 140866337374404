import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import loadgif from '../../img/loading.gif'
const config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin){
            window.location = config.LOGIN
        }
        this.state = {
            userId : userLogin.userId,
            token : userLogin.token,
            loading:false,
            update: false,
            user : {},
            setPassword : false
        };

    }

    componentDidMount() {
        this.handleFetch()
    }

    handleFetch(){
        const {userId,token} = this.state;
        this.setState({loading:true})
      
        const url = `${config.BASE_URL}/v1/user/profile?userId=${userId}`; 
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
        }).then((response) => {
            return response.json();
        }).then((json) => {
           
            this.setState({user: json["data"]});
           
            setTimeout(() => {
                this.setState({loading:false})
              },1000);
      
        }).catch(error => {
            this.handleError(error)
        });
    }
    
    handleSetPasswordClick(){
        const {setPassword} = this.state;
        this.setState({setPassword: !setPassword})
    }

    handleSaveClick(value, event){
        const {setPassword,token} = this.state

        var user = {
            userId : document.getElementById("userId").value,
            username : document.getElementById("username").value,
            firstname :document.getElementById("firstname").value,
            lastname : document.getElementById("lastname").value,
            position : document.getElementById("position").value,
            dept : document.getElementById("dept").value || " ",
            tel : document.getElementById("tel").value || " ",
            email : document.getElementById("email").value || " ",
           
        }

        if(setPassword){
            var password = document.getElementById("password").value
            var confirmPassword = document.getElementById("confirm-password").value
            if(password != confirmPassword){
                alert("password and confirm password does not macth!.")
                return
            }
            else{
                user.password = password
            }
        }
        this.setState({update:true})
       // console.log(user)
        const url = `${config.BASE_URL}/v1/user/update`; 

        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(user)
        }).then((response) => {
            return response.json();
        }).then((result) => {
           
            if(result.status){
                setTimeout(() => {
                    this.setState({user : user , update:false})
                   
                    if(setPassword){
                        localStorage.clear()
                        window.location = config.LOGIN
                    }
               },1000)
                
               
            }
            else{
                this.setState({update:false})
                alert('Your request is not complete');
            }
               
        }).catch(error => {
            this.setState({update:false})
            this.handleError(error)
        });
    }

    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    render(){
        const { loading ,setPassword,user ,update } = this.state;
        
        

        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>User Profile</h3>
                                
                            </div>
                          
                            <div >
                                <div className="box">
                                
                                  <div className="box-header">
                                    
                                  </div>

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className= "box-body">
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                           <div className={loading ? "hidden" :"col-md-12"} style={{width : "80%",paddingLeft : "15%"}}>
                                                <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Username</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="username" defaultValue={user.username}  style={{width : "100%"}} readOnly/>
                                                                    <input type="hidden" className="form-control" id="userId" defaultValue={user.userId}  style={{width : "100%"}} />
                                                                </div>
                                                            </div>

                                                          

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>First Name</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="firstname" defaultValue={user.firstname}   style={{width : "100%"}} />
                                                                </div>
                                                            </div>
                                                                
                                                        
                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Last Name</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="lastname" defaultValue={user.lastname}   style={{width : "100%"}} />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box" style={{marginTop : "15px"}}>
                                                                <div className="col-md-2 ">
                                                                   
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="Checkbox" onClick={this.handleSetPasswordClick.bind(this)} style={{ cursor : "pointer"}}/>Set New Password
                                                                </div>
                                                            </div>

                                                            <div style={{border: '1px solid #d6d6d6',paddingLeft: '15px' ,paddingTop: '15px'}} className={setPassword ? "" : "hidden"}>
                                                                <div className="row modal-box">
                                                                    <div className="col-md-2 ">
                                                                        <label>Password</label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <input type="password" className="form-control" id="password"   style={{width : "80%"}} />
                                                                    </div>
                                                                </div>

                                                                <div className="row modal-box">
                                                                    <div className="col-md-2 ">
                                                                        <label>Confirm Password</label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <input type="password" className="form-control" id="confirm-password"  style={{width : "80%"}} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                             <div className="row modal-box" style={{marginTop : "15px"}}>
                                                                <div className="col-md-2 ">
                                                                    <label>Position</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="position" defaultValue={user.position}   style={{width : "100%"}} />
                                                                </div>
                                                            </div>

                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Dept</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="dept"  defaultValue={user.dept}  style={{width : "100%"}} />
                                                                </div>
                                                            </div>

                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Tel</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="tel"  defaultValue={user.tel}  style={{width : "100%"}} />
                                                                </div>
                                                            </div>

                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Email</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="email"  defaultValue={user.email}  style={{width : "100%"}} />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-12" style={{textAlign : "center"}}>
                                                                    
                                                                    <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : "300px",height : "46px"}}>
                                                                    <i className={update ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>
                                                                        Save
                                                                    </button>
                                                                </div> 
                                                            </div>
                                                    
                                                </div>
                                            
                                            

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}