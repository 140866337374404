import React, {Component} from 'react';
import dateFormat from 'dateformat';
import { confirmAlert } from 'react-confirm-alert';
import {Parser} from 'json2csv'
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'

import DateTimePicker from "react-datetime-picker";

import Select from 'react-select'
import loadgif from '../../img/loading.gif'
import importPng from '../../img/import_csv.png'
import exportPng from '../../img/export_csv.png'
import { CSVLink } from "react-csv";
import excelImage from '../../img/excel.png'
var config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.transport){
            window.location = config.LOGIN
        }

        this.state = {
            permission : userLogin.permission.transport,
            token : userLogin.token,
            loading:false,
            upload : false,
            setPassword : false,
            roleId : "1",
            searchKey : "",
            sortBy : "createdAt",
            sortType : -1,
            userId : -1,
            csvData : [],
            export_file : "",
            show : "table",
            date1 : null,
            date2 : null,
            date3 : null,
            date4 : null,
            date5 : null,
            date6 : null,
            date7 : null,
            title : "",
            edit_transport_id:"",
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 50,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            }
        };

    }
  
    sortOptions={
        "Type" : "type",
        "Price" : "price",
        "Status" : "status",
        "Create"    : "create",
    }

    componentDidMount() {
        this.handleFetch(1)
    }

    handleFetch(page){
        const {pages,sortBy,sortType, token} = this.state;

        if(page < 1 || page > pages.totalPages){
            return
        }
        document.getElementById('search-key').value = ""
        this.setState({loading:true , searchKey : ""})
       
        const url = `${config.BASE_URL}/v1/transport?page=${page}&sortBy=${sortBy}&sortType=${sortType}&perPage=${pages.perPage}`; 

        fetch(url,{
            headers: {
                'authorization': `basic ${token}`
            },
        }).then((response) => {
            return response.json();
        }).then((result) => {
            console.log(result)
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false ,show : "table"})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
            
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleModalExport(){
        this.modalCloseAction("modal-export","open")
    }

    handleExport(){
        const {pages,sortBy, token} = this.state;
        const vinno = document.getElementById("vinno").value
        const url = `${config.BASE_URL}/v1/transport?page=1&sortBy=${sortBy}&perpage=${pages.totalDocs}`; 
        
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then(async (result) => {
            
            if(result.status){
                 console.log(result.data.docs)
                 var items = result.data.docs
                if (vinno.length > 0) {
                    items = items.filter(x => {
                        return x["vinNo"] == vinno
                    })
                }
                var data = await Promise.all(items.map((item, index) => {
                    let date1 = (item["receiptDate"]) ? dateFormat(item["receiptDate"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let date2 = (item["recieveDescription"] && item["recieveDescription"]["startDate"]) ? dateFormat(item["recieveDescription"]["startDate"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let date3 = (item["recieveDescription"] && item["recieveDescription"]["finishDate"]) ? dateFormat(item["recieveDescription"]["finishDate"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let date4 = (item["transferShuttle"] && item["transferShuttle"]["datetime"]) ? dateFormat(item["transferShuttle"]["datetime"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let date5 = (item["transferShuttle"] && item["transferShuttle"]["finishDate"]) ? dateFormat(item["transferShuttle"]["finishDate"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let date6 = (item["transferDescription"] && item["transferDescription"]["datetime"]) ? dateFormat(item["transferDescription"]["datetime"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let date7 = (item["starToDealer"]) ? dateFormat(item["starToDealer"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let transferShuttle_callingNo = (item["transferShuttle"] && item["transferShuttle"]["callingNo"]) ? item["transferShuttle"]["callingNo"] : ""
                    let transferShuttle_dn = (item["transferShuttle"] && item["transferShuttle"]["dn"]) ? item["transferShuttle"]["dn"] : ""
                    let recieveDescription_company = (item["recieveDescription"] && item["recieveDescription"]["company"]) ? item["recieveDescription"]["company"] : ""
                    let recieveDescription_dn = (item["recieveDescription"] && item["recieveDescription"]["dn"]) ? item["recieveDescription"]["dn"] : ""
                    let transferDescription_callingNo = (item["transferDescription"] && item["transferDescription"]["callingNo"]) ? item["transferDescription"]["callingNo"] : ""
                    let transferDescription_dn = (item["transferDescription"] && item["transferDescription"]["dn"]) ? item["transferDescription"]["dn"] : ""
                    
                    let params = {
                        'No': index+1,
                        'Receipt Date': date1,
                        'Doc No':`="${item["docNo"]}"`,
                        'VIN No': item["vinNo"],
                        'Dealer Source': item["dealerSource"],
                        'Dealer Destination': item["dealerDestination"],
                        'Yard': item["yard"],
                        'Receive Description Company': recieveDescription_company,
                        'Receive Description StartDate': date2,
                        'Receive Description FinishDate': date3,
                        'Receive Description DN': recieveDescription_dn,
                        'Transfer Shuttle Datetime': date4,
                        'Transfer Shuttle Calling No': transferShuttle_callingNo,
                        'Transfer Shuttle DN': transferShuttle_dn,
                        'Transfer Shuttle FinishDate': date5,
                        'Transfer Description Datetime': date6,
                        'Transfer Description Calling No': transferDescription_callingNo,
                        'Transfer Description DN': transferDescription_dn,
                        'Start To Dealer Datetime': date7,
                        'Status': item["currentStatus"],
                        'Remark': item["remark"] || "-",
                        'Product Code': item["productCode"]
                    }

                    return params
                }))

                const fields = [
                    'No',
                    'Receipt Date',
                    'Doc No' ,
                    'VIN No',
                    'Dealer Source',
                    'Dealer Destination',
                    'Yard',
                    'Receive Description Company',
                    'Receive Description StartDate',
                    'Receive Description FinishDate',
                    'Receive Description DN',
                    'Transfer Shuttle Datetime',
                    'Transfer Shuttle Calling No',
                    'Transfer Shuttle DN',
                    'Transfer Shuttle FinishDate',
                    'Transfer Description Datetime',
                    'Transfer Description Calling No',
                    'Transfer Description DN',
                    'Start To Dealer Datetime',
                    'Status',
                    'Remark',
                    'Product Code'
                 ]
                const opts = { fields };
                
                try {
                    
                    const parser = new Parser(opts);
                    const csv = parser.parse(data);
                    var datetime = dateFormat(Date.now(),'ddmmyyyy_HHMMss')
                    var export_file = `export_transport_${datetime}.csv`

                    var hiddenElement = document.createElement('a');
                    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI("\ufeff"+csv);
                    hiddenElement.target = '_blank';
                    hiddenElement.download = export_file;
                    hiddenElement.click();

                    this.modalCloseAction("modal-export","close")

                    setTimeout(() => {
                        alert("ท่านได้ทำการ Export ข้อมูลสำเร็จ")
                     },500);
                }catch (error) {
                    this.modalCloseAction("modal-export","close")
                }
            }
            else{
                this.handleError(result.message)
            }
           
            
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleSearchClick(page){
        var key = document.getElementById("search-key").value;
        if(key == ""){
            return
        }

        this.setState({loading:true , searchKey : key})

        setTimeout(() => {
            this.handleSearch(1)
          },500);
        
    }

    handleEditClick(id, event){
        const { pages } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
        console.log(result[0].transferShuttle.dn)
        
        document.getElementById("docNo").value = result[0].docNo
        document.getElementById("productCode").value = result[0].productCode.toUpperCase()

        document.getElementById("dealer-source").value = (result[0].dealerSource) ? result[0].dealerSource : ""
        document.getElementById("dealer-destination").value =  (result[0].dealerDestination) ? result[0].dealerDestination : ""
        document.getElementById("vinNo").value = (result[0].vinNo) ? result[0].vinNo : ""
        document.getElementById("yard").value = (result[0].yard) ? result[0].yard : ""
        document.getElementById("receive-description-company").value = (result[0].recieveDescription.company) ? result[0].recieveDescription.company : ""
        document.getElementById("receive-description-dn").value =  (result[0].recieveDescription.dn) ? result[0].recieveDescription.dn : ""
        document.getElementById("transfer-shuttle-calling-no").value = (result[0].transferShuttle.callingNo) ? result[0].transferShuttle.callingNo : ""
        document.getElementById("transfer-shuttle-dn").value =  (result[0].transferShuttle.dn) ? result[0].transferShuttle.dn : ""
        document.getElementById("transfer-description-calling-no").value =  (result[0].transferDescription.callingNo) ? result[0].transferDescription.callingNo : ""
        document.getElementById("transfer-description-dn").value =  (result[0].transferDescription.dn) ? result[0].transferDescription.dn : ""
        document.getElementById("remark").value =  (result[0].remark) ? result[0].remark : ""
        
        var date1 = (result[0].receiptDate) ? this.convertDate(result[0].receiptDate) : ""
        var date2 = (result[0].recieveDescription.startDate) ? this.convertDate(result[0].recieveDescription.startDate) : ""
        var date3  = (result[0].recieveDescription.finishDate) ? this.convertDate(result[0].recieveDescription.finishDate) : ""
        var date4  =  (result[0].transferShuttle.datetime) ? this.convertDate(result[0].transferShuttle.datetime) : ""
        var date5  = (result[0].transferShuttle.finishDate) ? this.convertDate(result[0].transferShuttle.finishDate) : ""
        var date6 =  (result[0].transferDescription.datetime) ? this.convertDate(result[0].transferDescription.datetime) : ""
        var date7 =  (result[0].starToDealer) ? this.convertDate(result[0].starToDealer) : ""
        
        var status =  result[0].currentStatus
        console.log("Status=" + status)
        console.log(date1, "< -- date7")
        this.setState({
            date1 : date1, 
            date2 : date2, 
            date3 : date3, 
            date4 : date4, 
            date5 : date5, 
            date6 : date6, 
            date7 : date7,
            show : "add", 
            title : "Update",
            edit_transport_id : id,
            status: status
        })
    }

    handleSearch(page){
        const {pages,searchKey,sortBy, roleId ,token} = this.state;
        const url = `${config.BASE_URL}/v1/transport/search`; 
        this.setState({loading:true })

        var params ={
            "key" : searchKey ,
            "roleId" : roleId ,
            "page" : page ,
            "perPage" : pages.perPage,
            "sortBy" : sortBy,
            
        }
       // console.log("Search")
       // console.log(params)
        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
      
        }).catch(error => {
            this.handleError(error)
        });
    }

    handlePageClick(page, event){ 
        const {searchKey} = this.state;
        //console.log("search " + searchKey)
        if(searchKey){
            
            this.handleSearch(page)
        }
        else{
            this.handleFetch(page)
        }
    
    }

    handleSortClick(page, event){ 
        const {searchKey} = this.state;
        var sortKey = document.getElementById('sort-key').value
        var sortBy = this.sortOptions[sortKey]
        this.setState({sortBy:sortBy,sortType : 1});
    
        setTimeout(() => {
            if(searchKey){
                this.handleSearch(page)
            }
            else{
                this.handleFetch(page)
            }
          },500);
        
        
    }

    handleDownloadClick() {
       
        setTimeout(() => {
          const response = {
            file: `${config.BASE_URL}/v1/csv/transport.csv`
          };
         
          window.open(response.file);
         
        }, 100);
    }
    
    modalCloseAction(id,status){
        
        if(status == "open"){
            document.getElementById("csv_file").value = "";
            document.getElementById(id).style.display = 'inline'
        } else {
            document.getElementById(id).style.display = 'none'
        }
    }

   

    handleFileUpload(value){
        const { roleId,token } = this.state;
        const browserFiles = document.querySelector('input[type="file"]')
      
        const url = `${config.BASE_URL}/v1/transport/upload`;
        
        
        var formData = new FormData()
        formData.append('roleId',roleId)
        for (const file of browserFiles.files) {
          //  console.log(file)
            formData.append('file',file,file.name)
            
        }
        
        this.setState({upload : true })
        fetch(url, {
            method: 'POST',
            headers: {
                'authorization': `basic ${token}`
             },
            body: formData
        }).then(function(response) {
            return response.json();
        }).then(result => this.onSetFileResult(value, result)).catch(error => {
            this.setState({ upload: false });
            alert(error)
        });
        
    }

    onSetFileResult = (value, result) => {
        
        if (result["status"] == true){
            setTimeout(() => {
                this.setState({upload:false})
                this.modalCloseAction("modal-import","close")
                this.handleFetch(1)
               
           },1000)
        }else{
            this.setState({upload:false})
            this.handleError(result.message)
        }

    }

    handleRemoveClick(value, event){
        const { token, edit_transport_id} = this.state;

        confirmAlert({
            title: `กรุณายืนยันการลบ`,
            message: "คุณต้องการที่จะลบข้อมูล transport นี้หรือไม่?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    //this.handleSave(url,token,obj)
                    const url = `${config.BASE_URL}/v1/transport/remove`; 
                    let params = {
                        "transportId": edit_transport_id
                    }

                    fetch(url, {
                        method: "post",
                        headers: {
                            'authorization': `basic ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(params)
                    }).then((response) => {
                        return response.json();
                    }).then((result) => {
                    // console.log(result)
                        if(result.status){
                            this.handleFetch(1)
                        }
                        else{
                            this.handleError(result.message)
                        }
                        
                    }).catch(error => {
                        this.handleError(error)
                    });
                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                }
              }
            ]
        })
    }

    convertDate(dateString) {
        let day = dateFormat(dateString,"UTC:dd")
        let month = dateFormat(dateString,"UTC:mm")
        let year = dateFormat(dateString,"UTC:yyyy")

        let hour = dateFormat(dateString,"UTC:HH")
        let minute = dateFormat(dateString,"UTC:MM")
        
        return new Date(year,month - 1,day,hour,minute)
    }

    handleSaveClick(value, event){
        const { token ,date1,date2,date3,date4,date5,date6,date7 ,status} = this.state;
        var days = [
            dateFormat(date1,"dd/mm/yyyy HH:MM"),
            dateFormat(date2,"dd/mm/yyyy HH:MM"),
            dateFormat(date3,"dd/mm/yyyy HH:MM"),
            dateFormat(date4,"dd/mm/yyyy HH:MM"),
            dateFormat(date5,"dd/mm/yyyy HH:MM"),
            dateFormat(date6,"dd/mm/yyyy HH:MM"),
            dateFormat(date7,"dd/mm/yyyy HH:MM")
        ]
        let transport =  {
            docNo : document.getElementById("docNo").value,
            productCode : document.getElementById("productCode").value,
            receiptDate : days[0],
            dealerSource : document.getElementById("dealer-source").value,
            dealerDestination : document.getElementById("dealer-destination").value,
            yard : document.getElementById("yard").value,
            vinNo : document.getElementById("vinNo").value,
            recieveDescriptionCompany : document.getElementById("receive-description-company").value,
            recieveDescriptionStartDate : days[1],
            recieveDescriptionFinishDate : days[2],
            recieveDescriptionDN : document.getElementById("receive-description-dn").value,
            transferShuttleDatetime : days[3],
            transferShuttleCallingNo : document.getElementById("transfer-shuttle-calling-no").value,
            transferShuttleDN : document.getElementById("transfer-shuttle-dn").value ,
            transferShuttleFinishDate :days[4],
            transferDescriptionDatetime : days[5],
            transferDescriptionCallingNo : document.getElementById("transfer-description-calling-no").value,
            transferDescriptionDN : document.getElementById("transfer-description-dn").value,
            remark : document.getElementById("remark").value,
            status :status,
            starToDealer: days[6]
        }
        console.log(transport)
        const url = `${config.BASE_URL}/v1/transport/update`; 
        
        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(transport)
        }).then((response) => {
            return response.json();
        }).then((result) => {
           // console.log(result)
            if(result.status){
                this.handleFetch(1)
            }
            else{
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleSetPasswordClick(){
        const {setPassword} = this.state;
        this.setState({setPassword: !setPassword})
    }

    handleStatusChange(val){
    
        this.setState({status : val.value})
    }

    onStartDateChange(key,date){
        if(key == "d1")
            this.setState({date1 : date})
        if(key == "d2")
            this.setState({date2 : date})
        if(key == "d3")
            this.setState({date3 : date})
        if(key == "d4")
            this.setState({date4 : date})
        if(key == "d5")
            this.setState({date5 : date})
        if(key == "d6")
            this.setState({date6 : date})
        if(key == "d7")
            this.setState({date7 : date})
    }

    handleShowClick(show){
        this.setState({show : show});
    }

    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    handleVinnoChange(val){
    
        this.setState({ vinno : val.value})
    }

    render(){
        const { pages, loading ,searchKey,upload,permission ,show,title ,date1,date2,date3,date4,date5,date6,date7,status,csvData,export_file} = this.state;
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            var contents = null
            if(pages.docs.length > 0){
                contents = []
                for(var index in pages.docs){
                    let item = pages.docs[index]
                    let date1 = (item["receiptDate"]) ? dateFormat(item["receiptDate"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let date2 = (item["recieveDescription"] && item["recieveDescription"]["startDate"]) ? dateFormat(item["recieveDescription"]["startDate"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let date3 = (item["recieveDescription"] && item["recieveDescription"]["finishDate"]) ? dateFormat(item["recieveDescription"]["finishDate"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let date4 = (item["transferShuttle"] && item["transferShuttle"]["datetime"]) ? dateFormat(item["transferShuttle"]["datetime"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let date5 = (item["transferShuttle"] && item["transferShuttle"]["finishDate"]) ? dateFormat(item["transferShuttle"]["finishDate"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let date6 = (item["transferDescription"] && item["transferDescription"]["datetime"]) ? dateFormat(item["transferDescription"]["datetime"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let date7 = (item["starToDealer"]) ? dateFormat(item["starToDealer"],'GMT:dd/mm/yyyy HH:MM') : ""
                    let transferShuttle_callingNo = (item["transferShuttle"] && item["transferShuttle"]["callingNo"]) ? item["transferShuttle"]["callingNo"] : ""
                    let transferShuttle_dn = (item["transferShuttle"] && item["transferShuttle"]["dn"]) ? item["transferShuttle"]["dn"] : ""
                    let recieveDescription_company = (item["recieveDescription"] && item["recieveDescription"]["company"]) ? item["recieveDescription"]["company"] : ""
                    let recieveDescription_dn = (item["recieveDescription"] && item["recieveDescription"]["dn"]) ? item["recieveDescription"]["dn"] : ""
                    let transferDescription_callingNo = (item["transferDescription"] && item["transferDescription"]["callingNo"]) ? item["transferDescription"]["callingNo"] : ""
                    let transferDescription_dn = (item["transferDescription"] && item["transferDescription"]["dn"]) ? item["transferDescription"]["dn"] : ""
                    
                    console.log(date7, "<<<<< date7")
                    contents.push(
                       
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{ parseInt(pages.pageIndex) + parseInt(index)}</td>
                        <td align="center">{date1}</td>
                        <td align="center">{`${item["productCode"]}`.toUpperCase()}</td>
                        <td align="center">{item["docNo"]}</td>
                        <td align="center">{item["vinNo"]}</td>
                        <td align="center">{item["dealerSource"]}</td>
                        <td align="center">{item["dealerDestination"]}</td>
                        <td align="center">{item["yard"]}</td>

                        <td align="center">{recieveDescription_company}</td>
                        <td align="center">{date2}</td>
                        <td align="center">{date3}</td>
                        <td align="center">{recieveDescription_dn}</td>

                        <td align="center">{date4}</td>
                        <td align="center">{transferShuttle_callingNo}</td>
                        <td align="center">{transferShuttle_dn}</td>
                        <td align="center">{date5}</td>
                        
                        <td align="center">{date6}</td>
                        <td align="center">{transferDescription_callingNo}</td>
                        <td align="center">{transferDescription_dn}</td>
                     
                        <td align="center">{date7}</td>
                        <td align="center">{item["currentStatus"]}</td>
                        <td align="center">{item["remark"] || "-"}</td>
                        <td align="center"className={(permission.edit) ? "" : "hidden"}>
                            
                            <button type="button" onClick={this.handleEditClick.bind(this, item["_id"])} className="btn btn-block btn-warning">Edit</button>

                        </td> 
                    </tr>
                    )
                    
                }
            }
        }
       
        var status_options = [
            { value: "Start", label: "Start" },
            { value: "Departure", label:  "Departure" },
            { value: "Yard", label:  "Yard"},
            { value: "Arrival", label:  "Arrival" },
            { value: "Complete", label:  "Complete" }
        ]
    
        console.log(status, ' <------ status')
        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>Transport</h3>
                                <div className={(permission.import) ? "" : "hidden"}>
                                    <span style={{ float : "right",cursor: "pointer"}}>    
                                        <button type="button"  className="btn btn-success" style={{ width : "150px",marginTop : "16px"}} onClick={this.handleModalExport.bind(this)}>
                                        <img src={excelImage}  style={{width : '25px'}}/>
                                            Export
                                        </button> 
                                        <CSVLink id="csvdownload" data={csvData}  filename={export_file}></CSVLink>
                                    </span>
                                    <span style={{ float : "right",cursor: "pointer",marginRight : "10px"}}>
                                    <a href={`${config.BASE_URL}/v1/csv/transport.csv`}>
                                        <button type="button"  className="btn btn-success" style={{ width : "150px",marginTop : "16px"}} >
                                        <img src={exportPng} style={{width : '25px'}}/>
                                            Export Form
                                        </button>
                                    </a>
                                    </span>
                                    <span style={{ float : "right",cursor: "pointer"}}>
                                        <button type="button"  className="btn btn-primary" style={{ width : "150px",marginTop : "16px",marginRight : "10px"}} onClick={this.modalCloseAction.bind(this,"modal-import","open")}>
                                        <img src={importPng} style={{width : '25px'}}/>
                                            Import
                                        </button> 
                                    </span>
                                </div>
                            </div>
                          
                            <div className={show== "table" ? "box" : "hidden"}>
                                <div className="box-header">
                                    <div className="pull-left">
                                            <div className="input-group mb-3">
                                                <input type="text" id="search-key" style={{height : "34px"}}/>
                                                
                                                    <button className="btn btn-outline-secondary btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                                    <button className="btn btn-outline-secondary btn-reset" onClick={this.handleFetch.bind(this,1)} type="button">Reset</button>
                                            </div>
                                        </div>
                                </div>    
                                
                                <div className="box-header">
                                    <span className={searchKey ? "" : "hidden"}>Seach "{searchKey}" ,please use reset button to refresh query.</span>
                                    <p>
                                    <span>Found <b>{pages.totalDocs}</b> record(s).</span>
                                    </p>
                                </div>

                                <div className={loading ? "" : "hidden"}>
                                    <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                        <img src={loadgif}/>
                                    </div>
                                </div>

                                <div className="Container">
                                    <div className="Content">    
                                        <div className={ loading ? "hidden" : "box-body"}>
                                            <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                                <div className="row">
                                                    <div className="col-sm-6"></div>
                                                    <div className="col-sm-6"></div>
                                                </div>

                                                <div className={totalDocs ? "row" : "hidden"}>
                                                    <div className="col-sm-12">
                                                        <style>{'th {text-align: center;}'}</style>
                                                        <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                            <thead>
                                                            
                                                                <tr bgcolor="#d3d3d3" role="row">
                                                                    <th width="5%" className="sorting" tabIndex="1"  colSpan="1" rowSpan="2">
                                                                    No
                                                                    </th>
                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" rowSpan="2">
                                                                    วันที่รับใบขอ
                                                                    </th>
                                                                    
                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" rowSpan="2">
                                                                    รหัสสินค้า
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" rowSpan="2">
                                                                    เลขที่เอกสาร
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" rowSpan="2">
                                                                    VIN No
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" rowSpan="2">
                                                                    ชื่อดีลเลอร์ต้นทาง
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" rowSpan="2">
                                                                    ชื่อดีลเลอร์ปลายทาง
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" rowSpan="2">
                                                                    Yard ที่สั่งรับรถกลับ
                                                                    </th>
                                                                    
                                                                    <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="4" >
                                                                    รายละเอียดรับรถกลับ
                                                                    </th>
                                                                    <th  className="sorting" tabIndex="1"  rowSpan="1" colSpan="4" >
                                                                    รายละเอียดจัดส่งรถ กรณีเป็นรถ Shuttle
                                                                    </th>
                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="3" >
                                                                    รายละเอียดจัดส่งรถ
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" rowSpan="2">
                                                                    วัน/เวลา รถออกจาก Hub Yard To Dealer
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" rowSpan="2">
                                                                    Status
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" rowSpan="2">
                                                                    หมายเหตุ
                                                                    </th>
                                                                    <th  className={(permission.edit) ? "sorting" : "hidden"} tabIndex="3"  rowSpan="2" colSpan="1"  align="center" style={{width : '80px'}}>
                                                                        <span className='fa fa-edit'/> Edit
                                                                    </th>
                                                                </tr>
                                                                <tr bgcolor="#d3d3d3" role="row">
                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    บริษัท
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    วัน/เวลา ที่สั่งรับ
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    วันที่/เวลารถถึง VL
                                                                    </th>
                                                                
                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    DN.รับกลับ
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    วันที่จัดงาน
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Calling No.
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    DN.ส่ง
                                                                    </th>
                                                                
                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    วันที่/เวลารถถึง
                                                                    </th>
                                                                
                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    วัน/เวลา ที่จัดงาน
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1">
                                                                    Calling No.
                                                                    </th>

                                                                    <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1">
                                                                    DN.ส่ง
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            { contents }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>{/* End Row Table */}
                                            
                                            
                                                <div className={totalDocs ? "row" : "hidden"}>
                                                    <div className="col-sm-12">
                                                        <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                            <span>Showing {currentPage} of {totalPages} Pages</span>
                                                        
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-sm-12">
                                                        <div className="dataTables_paginate paging_simple_numbers" >
                                                            <ul className="pagination">
                                                                <button type="button" onClick={this.handleFetch.bind(this, currentPage - 10)} className="pageButton">
                                                                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                                </button>
                                                                {pageList}
                                                                <button type="button" onClick={this.handleFetch.bind(this, currentPage + 10)} className="pageButton">
                                                                <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                                </button>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="modal" id="modal-import" tabIndex="-1" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h3 className="modal-title">Import Transport</h3>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalCloseAction.bind(this,"modal-import","close")} style={{marginTop : '-23px'}}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                        </div>
                                        <div className="modal-body">
                                            <input type="file" id="csv_file" name="file"/>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.modalCloseAction.bind(this,"modal-import","close")}>Close</button>
                                            <button type="button" className="btn btn-primary" onClick={this.handleFileUpload.bind(this)}>
                                                <i className={upload ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>   
                                                Upload
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className={show== "add" ? "box" : "hidden"}>
                                <div className= "box-header" >
                                   
                                   <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleShowClick.bind(this,"table")}>
                                       <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px",backgroundColor : "black"}}>
                                       <i className="fa fa fa-remove"></i>
                                             <span style={{marginLeft : '5px'}}>Close</span>
                                           <span className="pull-right-container"></span>
                                       </a>
                                   </div>
                                   
                                 </div>

                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title"><b>{title}</b></h5>        
                                    </div>

                                    <div className="modal-body col-md-12" style={{height : 'auto'}}>
                                                            
                                        <div className="row modal-box">
                                            <div className="col-md-2 ">
                                                <label>Doc No<span className="required-label">*</span></label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="docNo"  readOnly />
                                                
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2 ">
                                                <label>Product Code<span className="required-label">*</span></label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="productCode"  readOnly />
                                                
                                            </div>
                                        </div>
                                                       
                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>Receipt Date</label>
                                            </div>
                                            <div className="col-md-10">
                                                {/* <input type="text" className="form-control" id="receipt-date" /> */}
                                                <DateTimePicker
                                                    value={date1}
                                                    onChange={this.onStartDateChange.bind(this,'d2')}
                                                    format = "dd/MM/yyyy HH:mm"
                                                    clearIcon={null}
                                                    disableClock={true}
                                                    disabled
                                                    />
                                            </div>
                                        </div>
                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>VIN No</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="vinNo" readOnly/>
                                            </div>
                                        </div>
                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>Dealer Source</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="dealer-source" />
                                            </div>
                                        </div>
                                            
                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>Dealer Destination</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="dealer-destination" />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>Yard</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="yard" />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>Receive Description Company</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="receive-description-company" />
                                            </div>
                                        </div>

                                        
                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>Receive Description StartDate</label>
                                            </div>
                                            <div className="col-md-10">
                                                {/* <input type="text" className="form-control" id="receive-description-start-date" /> */}
                                                <DateTimePicker
                                                    value={date2}
                                                    onChange={this.onStartDateChange.bind(this,'d2')}
                                                    format = "dd/MM/yyyy HH:mm"
                                                    clearIcon={null}
                                                    disableClock={true}
                                                    disabled
                                                    />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>Receive Description FinishDate</label>
                                            </div>
                                            <div className="col-md-10">
                                                {/* <input type="text" className="form-control" id="receive-description-finish-date" /> */}
                                                <DateTimePicker
                                                    value={date3}
                                                    onChange={this.onStartDateChange.bind(this,'d3')}
                                                    format = "dd/MM/yyyy HH:mm"
                                                    clearIcon={null}
                                                    disableClock={true}
                                                    disabled
                                                    />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>ReceiveDescriptionDN</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="receive-description-dn" />
                                            </div>
                                        </div>


                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>TransferShuttleDatetime</label>
                                            </div>
                                            <div className="col-md-10">
                                                {/* <input type="text" className="form-control" id="transfer-shuttle-datetime" /> */}
                                                <DateTimePicker
                                                    value={date4}
                                                    onChange={this.onStartDateChange.bind(this,'d4')}
                                                    format = "dd/MM/yyyy HH:mm"
                                                    clearIcon={null}
                                                    disableClock={true}
                                                    disabled
                                                    />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>TransferShuttleCallingNo</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="transfer-shuttle-calling-no" />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>TransferShuttleDN</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="transfer-shuttle-dn" />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>TransferShuttleFinishDate</label>
                                            </div>
                                            <div className="col-md-10">
                                                {/* <input type="text" className="form-control" id="transfer-shuttle-finish-date" /> */}
                                                <DateTimePicker
                                                    value={date5}
                                                    onChange={this.onStartDateChange.bind(this,'d5')}
                                                    format = "dd/MM/yyyy HH:mm"
                                                    clearIcon={null}
                                                    disableClock={true}
                                                    disabled
                                                    />
                                            </div>
                                        </div>


                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>TransferDescriptionDatetime</label>
                                            </div>
                                            <div className="col-md-10">
                                                {/* <input type="text" className="form-control" id="transfer-description-datetime" /> */}
                                                <DateTimePicker
                                                    value={date6}
                                                    onChange={this.onStartDateChange.bind(this,'d6')}
                                                    format = "dd/MM/yyyy HH:mm"
                                                    clearIcon={null}
                                                    disableClock={true}
                                                    disabled
                                                    />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>TransferDescriptionCallingNo</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="transfer-description-calling-no" />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>TransferDescriptionDN</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="transfer-description-dn" />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>StartToDealerDatetime</label>
                                            </div>
                                            <div className="col-md-10">
                                            <DateTimePicker
                                                    value={date7}
                                                    onChange={this.onStartDateChange.bind(this,'d7')}
                                                    format = "dd/MM/yyyy HH:mm"
                                                    clearIcon={null}
                                                    disableClock={true}
                                                    disabled
                                                    />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>Remark</label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="remark" />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2" >
                                                <label>Status</label>
                                            </div>
                                            <div className="col-md-10">
                                            <Select 
                                                    value={status_options.filter(option => option.value == status)}
                                                    options={status_options}
                                                    onChange= {value => this.handleStatusChange(value)}
                                                />
                                            </div>
                                        </div>                 
                                    </div>
                                    <div className="modal-footer" style={{textAlign : 'center'}}>
                                        <button type="button" className="btn btn-danger" onClick={this.handleRemoveClick.bind(this)} style={{width : '150px',marginBottom : '20px',marginTop : '30px'}}>
                                            <i className="fa fa-trash"></i>
                                            <span style={{marginLeft : '5px'}}>Remove</span>
                                        </button>

                                        <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : '150px',marginBottom : '20px',marginTop : '30px'}}>
                                            <i className="fa fa-save"></i>
                                            <span style={{marginLeft : '5px'}}>Save</span>
                                        </button>
                                    </div>
                                </div>          
                            </div>
                        </div>
                    </div>
                </section>
                <div className="modal" id="modal-export" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Export Filter</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalCloseAction.bind(this,"modal-export","close")} style={{marginTop : '-23px'}}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                            </div>
                            
                            <div className="modal-body">
                                <div>Vin No  <span style={{marginLeft : '10px',fontSize : '12px',color : 'red'}}>(optional) If empty will export all.</span></div>
                                <input id="vinno" className="form-control"/>
                            </div>

                            <CSVLink id="csvdownload" data={csvData}  filename={export_file}></CSVLink>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" style={{width : '120px'}} data-dismiss="modal" onClick={this.modalCloseAction.bind(this,"modal-export","close")}>Close</button>
                                <button type="button" className="btn btn-primary" style={{width : '200px'}} onClick={this.handleExport.bind(this)}>
                                    <i className={upload ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>   
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>
                </div>       
            </div>
        )
    }   
}