import React, { Component } from 'react';
import Header from '../share-components/Header';
import Content from './Content';
import SideBar from '../share-components/SideBar';

class Administrator extends Component {

  constructor() {
    super()
    
    this.state = { items: [] };
    localStorage.setItem('menu', "wts");
  }
  
  render() {
    return (
      <div>
        <Header />
        <SideBar />
        <Content /> 
      </div>
    );
  }
}

export default Administrator;
