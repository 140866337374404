import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import loadgif from '../../img/loading.gif'
var config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin){
            window.location = config.LOGIN
        }

        this.state = {
            adminRoleId : userLogin.roleId,
            token : userLogin.token,
            loading:false,
            upload : false,
            searchKey : "",
            sortBy : "",
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 50,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            }
        };

    }


    componentDidMount() {
        this.handleFetch(1)
    }

    handleFetch(page){
        const {pages,sortBy, token} = this.state;

        if(page < 1 || page > pages.totalPages){
            return
        }
        document.getElementById('search-key').value = ""
        this.setState({loading:true , searchKey : ""})
       
        const url = `${config.BASE_URL}/v1/zone?page=${page}&sortBy=${sortBy}&perPage=${pages.perPage}`; 
        //console.log("Fetch")
        //console.log(url)
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });

    }

    handleSearchClick(page){
        var key = document.getElementById("search-key").value;
        if(key == ""){
            return
        }

        this.setState({loading:true , searchKey : key})

        setTimeout(() => {
            this.handleSearch(1)
          },500);
        
    }

    handleSearch(page){
        const {pages,searchKey,sortBy, roleId,token} = this.state;
        const url = `${config.BASE_URL}/v1/zone/search`; 
        this.setState({loading:true })

        var params ={
            "key" : searchKey ,
            "page" : page ,
            "perPage" : pages.perPage,
            "sortBy" : sortBy
        }
        //console.log("Search")
       // console.log(params)
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });
    }

    handlePageClick(page, event){ 
        const {searchKey} = this.state;
        //console.log("search " + searchKey)
        if(searchKey){
            
            this.handleSearch(page)
        }
        else{
            this.handleFetch(page)
        }
    
    }

    

    handleCreateClick(value, event){
        document.getElementById("zoneId").value = -1
        document.getElementById("description").value = ""
      
        this.modalAction("modal-add-series","open")
    
    }

    handleEditClick(id, event){
        const { pages } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
       //console.log(result)
       document.getElementById("zoneId").value = result[0]._id
        document.getElementById("description").value = result[0].description
       
        this.modalAction("modal-add-series","open")
    }

    handleSaveClick(value, event){
        const {token} = this.state;
        var id =  document.getElementById("zoneId").value
        var description = document.getElementById("description").value
       
        var obj = {
            id : id,
            description : description
        }
       
        var act = (id == -1) ? 'create' : 'update'
        const url = `${config.BASE_URL}/v1/zone/${act}`; 
        
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(obj)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            //.log(result)
            if(result.status){
                this.modalAction("modal-add-series","close")
                this.handleFetch(1)
            }
            else{
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.handleError(error)
        });
    }

    

    handleDeleteClick(id, event){
        const {token} = this.state;
        confirmAlert({
            title: 'Confirm',
            message: "Do you want to delete this user?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                   
                    const url = `${config.BASE_URL}/v1/zone/delete`;
                
                    this.setState({ loading: true })
            
                     fetch(url, {
                        method: 'post',
                         headers: {
                            'authorization': `basic ${token}`,
                             'Content-Type': 'application/x-www-form-urlencoded',
                         },
                         body: `id=${id}`
                     }).then(function(response) {
                         return response.json();
                        }).then((result) => {
                            //console.log(result)
                            if(result.status){
                                this.handleFetch(1)
                            }
                            else{
                                this.handleError(result.message)
                            }
                               
                        }).catch(error => {
                            this.handleError(error)
                        });
                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                }
              }
            ]
          })
    }


    onSetResult = (value, result) => {
        
        if (result["status"] == true){
            window.location.reload();
        }else{
            alert('Your request is not complete seriesname or email is already.'+JSON.stringify(result));
        }

        const { loading } = this.state;
        this.setState({ loading: !loading })
    }
    
    modalAction(id,status){
        
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }

   

    handleFileUpload(value){
        const {  token } = this.state;
       
        const browserFiles = document.querySelector('input[type="file"]')
      
        const url = `${config.BASE_URL}/v1/zone/upload`;
        //console.log(url)
        
        var formData = new FormData()
       
        for (const file of browserFiles.files) {
            //console.log(file)
            formData.append('file',file,file.name)
            
        }
        
        this.setState({ upload: true })

        fetch(url, {
            method: 'POST',
            headers: {
                'authorization': `basic ${token}`
             },
            body: formData
        }).then(function(response) {
            return response.json();
        }).then(result => this.onSetFileResult(value, result)).catch(error => {
            this.setState({ loading: false ,upload : false});
            alert(error)
        });
        
    }

    onSetFileResult = (value, result) => {
        this.setState({ upload: false })
        if (result["status"] == true){

            //alert("Successfully");
            this.modalAction("modal-import","close")
            this.handleFetch()
        }else{
            this.handleError(result.message)
        }

    }

   
    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    render(){
        const { pages, loading ,searchKey,adminRoleId,calendarStart,calendarStop,startDate,stopDate,upload} = this.state;
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            var contents = null
            if(pages.docs.length > 0){
                contents = []
                for(var index in pages.docs){
                    let item = pages.docs[index]
                    
                    contents.push(
                       
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{parseInt(pages.pageIndex) + parseInt(index)}</td> 
                        <td align="left"><span style={{marginLeft : '15px'}}>{item["description"]}</span></td>
                     
                        <td align="center" className={adminRoleId  < 2 ? '' : 'hidden'}>
                            <button type="button" onClick={this.handleEditClick.bind(this, item["_id"])} className="btn btn-block btn-warning">Edit</button>
                        </td> 
                        <td align="center" className={adminRoleId  < 2 ? '' : 'hidden'}>
                            <button type="button" disable={loading.toString()} onClick={this.handleDeleteClick.bind(this, item["_id"])} className="btn btn-block btn-danger">Delete</button>
                        </td>
                    </tr>
                    )
                    
                }
            }
        }
        

        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>Zone Management</h3>
                                <span style={{ float : "right",cursor: "pointer"}}>    
                                    <button type="button"  className="btn btn-primary" style={{ width : "150px",marginTop : "16px"}} onClick={this.modalAction.bind(this,"modal-import","open")}>Import</button> 
                                </span>
                          
                            </div>
                          
                            <div >
                                <div className="box">
                                <div className="box-header">
                                      
                                      <div className={adminRoleId  < 2 ? 'pull-left' : 'hidden'} style={{cursor : "pointer"}} onClick={this.handleCreateClick.bind(this)}>
                                          <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px"}}>
                                              <i className="fa fa fa-plus"></i>
                                              <span>&nbsp;Create</span>
                                              <span className="pull-right-container"></span>
                                          </a>
                                      </div>
                                      
                                  </div>

                                  <div className="box-header">
                                  <div className="pull-left">
                                          <div className="input-group mb-3">
                                              <input type="text" id="search-key" style={{height : "34px"}}/>
                                              
                                                  <button className="btn btn-outline-secondary btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                                  <button className="btn btn-outline-secondary btn-reset" onClick={this.handleFetch.bind(this,1)} type="button">Reset</button>
                                          </div>
                                      </div>

                                    <div className="pull-right">
                                        
                                    </div>
                                    
                                </div>
                                <div className="box-header">
                                   <span className={searchKey ? "" : "hidden"}>Seach "{searchKey}" ,please use reset button to refresh query.</span>
                                </div>

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ loading ? "hidden" : "box-body"}>
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-8">
                                                    <style>{'th {text-align: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                No.
                                                                </th>
                                                             
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                               Description
                                                                </th>

                                                                <th  className={adminRoleId  < 2 ? 'sorting' : 'hidden'} tabIndex="3"  rowSpan="1" colSpan="1" aria-label="Engine version: activate to sort column ascending" align="center" style={{width : '80px'}}>
                                                                    <span className='fa fa-edit'/> Edit
                                                                </th>
                                                                
                                                                <th  className={adminRoleId  < 2 ? 'sorting' : 'hidden'} tabIndex="4"  rowSpan="1" colSpan="1" aria-label="CSS grade: activate to sort column ascending" style={{width : '80px'}}>
                                                                    <span className='fa fa-bitbucket'/> Delete
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           { contents }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handleFetch.bind(this, currentPage - 10)} className="pageButton">
                                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handleFetch.bind(this, currentPage + 10)} className="pageButton">
                                                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="modal" id="modal-import" tabIndex="-1" role="dialog">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Import</h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalAction.bind(this,"modal-import","close")} style={{marginTop : '-23px'}}>
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                        </div>
                                                        <div className="modal-body">
                                                       {/* <span className="file btn btn-lg btn-primary upload-div" > */}
							                               
							                                <input type="file" id="csvfile" name="file"/>
                                                           
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.modalAction.bind(this,"modal-import","close")}>Close</button>
                                                            <button type="button" className="btn btn-primary" onClick={this.handleFileUpload.bind(this)}>
                                                            <i className={upload ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>
                                                                Upload
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="modal" id="modal-add-series" tabIndex="-1" role="dialog">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Zone</h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalAction.bind(this,"modal-add-series","close")} style={{marginTop : '-23px'}}>
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                        </div>
                                                        <div className="modal-body col-md-12" style={{height : "100px"}} >

                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Description</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="description"  style={{width : "100%"}} />
                                                                    <input type="hidden"  id="zoneId"  />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.modalAction.bind(this,"modal-add-series","close")} style={{width : '150px',marginBottom : '20px'}}>Close</button>
                                                            <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : '150px',marginBottom : '20px'}}>Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}