import React, {Component} from 'react';
import { CSVLink } from "react-csv";
import dateFormat from 'dateformat';
import DatePicker from "react-datepicker";
import './css/style.css'
import loadgif from '../../img/loading.gif'
import importPng from '../../img/import_csv.png'
import exportPng from '../../img/export_csv.png'
import excelImage from '../../img/excel.png'

var config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.locatorTransaction){
            window.location = config.LOGIN
        }
        
        this.state = {
            permission : userLogin.permission.locatorTransaction,
            token : userLogin.token,
            loading:false,
            upload : false,
            setPassword : false,
            roleId : "1",
            searchKey : "",
            sortBy : "transactionId",
            sortType : -1,
            userId : -1,
            type : "",
            csvData : [],
            export_file : "",
            startDate : "",
            stopDate : "",
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 50,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            }
        };

    }
  
    componentDidMount() {
        this.handleFetch(1)
    }

    onStartDateChange(date){
        this.setState({startDate : date});
    }

    onStopDateChange(date){
        this.setState({stopDate : date});
    }
    
    handleFetch(page){
        const {pages,sortBy,sortType, token,type,searchKey ,startDate ,stopDate} = this.state;

        if(page < 1 || page > pages.totalPages){
            return
        }
        this.setState({loading:true})
        const url = `${config.BASE_URL}/v1/transaction/search`; 
        var params ={
            "key" : searchKey ,
            "page" : page ,
            "perPage" : pages.perPage,
            "sortType" : -1,
            "sortBy" : 'createdAt',
            "startDate" : startDate,
            "stopDate" : stopDate,
            "type" : type
        }

        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            console.log(result)
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
            
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleSearchClick(){
        const {startDate,stopDate} = this.state

        var type = document.getElementById("search-type").value;
        var key = document.getElementById("search-key").value;
        
        if(startDate && !stopDate){
            alert("Stop date is require.")
            return;
        }
        if(!startDate && stopDate){
            alert("Start date is require.")
            return;
        }

        if(type == "all"){
            type = ""
        }        
        this.setState({searchKey : key , type : type})
        setTimeout(() => {
            this.handleFetch(1)
        },500);
    }

    handleResetClick(){
        document.getElementById("search-key").value = ""
        document.getElementById("search-type").value = "all"
        this.setState({searchKey : "" ,startDate : null, stopDate : null , type : ""})
        setTimeout(() => {
            this.handleFetch(1)
        },500);
    }

   
    handlePageClick(page, event){  
        this.handleFetch(page)
    }

    handleExport(){
        const {pages,sortBy,sortType, token,type,searchKey ,startDate ,stopDate} = this.state;

        const url = `${config.BASE_URL}/v1/transaction/search`; 
        var params ={
            "key" : searchKey ,
            "page" : 1 ,
            "perPage" : pages.totalDocs,
            "sortType" : sortType,
            "sortBy" : sortBy,
            "startDate" : startDate,
            "stopDate" : stopDate,
            "type" : type
        }

        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            
            if(result.status){
                 console.log(result.data.docs)
                 var data = []
                 data.push([
                     'No',
                     'Transaction ID',
                     'Transaction Code',
                     'Order Sequence',
                     'Series',
                    //  "Series Name",
                     'Color Code',
                     'Color Name',
                     'SFX',
                     'Model',
                     'Model Description',
                     'Price',
                     'PDI Date',
                     'Type',
                     'Dealer Name',	
                     'Buyer Name',	
                     'Transaction Date'
                    ])
                
                
                 var index = 1
                 var lastNo = 0;
                 console.log(result.data.docs)
                 result.data.docs.forEach(val => {
                    var userInfo = val["userInfo"]
                    var dealerInfo = val["dealerInfo"]
                    var firstname = userInfo["firstname"] || ""
                    var lastname = userInfo["lastname"] || ""
                    var dealer = dealerInfo["dealerNameTH"] || ""
                    var createdAt = val.createdAt

                    val.items.forEach(function(item,i){
                        var no_index = (index != lastNo) ? index : ""
                        var transactionId = val.transactionId
                        var transactionCode = val.transactionCode
                        var dealerName = dealer
                        var buyerName = `${firstname} ${lastname}`
                        
                        let pid_date = (item.type == "pool") ? " " : dateFormat(item.pidDate,'dd-mmm-yyyy')
                        var create_date = (createdAt.replace("T", " ").split("."))[0]
                        
                        lastNo = index;
                        var color = ""
                        for(var k in item["colorInfo"]){
                            if(k == "color")
                                 color = item["colorInfo"][k]
                            
                        }
                        let colorCode = (item.colorCode.substring(0,1) == 0) ? "'" + item.colorCode : item.colorCode
                        var obj = [
                            no_index,
                            transactionId,
                            transactionCode,
                            i+1,
                            item.series,
                            // item.seriesName,
                            colorCode,
                            color,
                            item.sfx,
                            item.model,
                            item.modelDescription,
                            item.price,
                            pid_date,
                            item.type,
                            dealerName,
                            buyerName,
                            create_date
                        ]
                        console.log(obj)
                        data.push(obj)
                    })
                    index++;
                });

                var d = new Date();
                var dd = (d.getDate() >= 10 ) ? d.getDate() : '0' + d.getDate()
                var mm = ((d.getMonth() +1) >= 10) ? d.getMonth() +1 : '0' + d.getMonth() +1
                var yyyy = d.getFullYear()
                var hh = d.getHours()
                var ii = d.getMinutes()
                var ss = d.getSeconds()
                var export_file = `export_${type}_${dd}${mm}${yyyy}_${hh}${ii}${ss}.csv`
                
               this.setState({ csvData : data ,export_file : export_file})
                setTimeout(() => {
                     document.getElementById("csvdownload").click()
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
            
        }).catch(error => {
            this.handleError(error)
        });
    }

    
    handleSortClick(page, event){ 
        const {searchKey} = this.state;
        var sortKey = document.getElementById('sort-key').value
        var sortOptions={
            "Type" : "type",
            "Price" : "price",
            "Status" : "status",
            "Create"    : "create",
        }
        var sortBy = sortOptions[sortKey]
        this.setState({sortBy:sortBy,sortType : 1});
    
        setTimeout(() => {
            if(searchKey){
                this.handleSearch(page)
            }
            else{
                this.handleFetch(page)
            }
          },500);
        
        
    }


    modalAction(id,status){
        
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }


    handleError(error){
        console.log(error)
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    render(){
        const { pages, loading ,searchKey,upload,csvData ,export_file ,startDate,stopDate,permission} = this.state;
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            var contents = null
            if(pages.docs.length > 0){
                contents = []
                for(var index in pages.docs){
                    let main = pages.docs[index]
                    var lastNo = 0
                    var userInfo = main["userInfo"]
                    var dealerInfo = main["dealerInfo"]
                    var firstname = userInfo["firstname"] || ""
                    var lastname = userInfo["lastname"] || ""
                    var dealer = dealerInfo["dealerNameTH"] || ""

                    var createdAt = main.createdAt
                    for(var i in main.items){
                        let item = main.items[i]
                        let no = parseInt(pages.pageIndex) + parseInt(index)
                        let no_index = (no != lastNo) ? no : ""
                        let transactionId = (no != lastNo) ? main["transactionId"] : ""
                        let transactionCode = (no != lastNo) ? main["transactionCode"] : ""
                        let dealerName = dealer
                        let buyerName = firstname + " " + lastname
                        //let transactionType = (no != lastNo) ? main["transactionType"] : ""
                        //let status = (no != lastNo) ? main["transactionStatus"] : ""
                        let status = main["transactionStatus"] 
                    
                        let pid_date = (item["type"] == "pool") ? "-" : dateFormat(item["pidDate"],'dd-mmm-yyyy')
                        
                       // console.log(item)
                        let create_date = (createdAt.replace("T", " ").split("."))[0]
                        let color = (item["colorInfo"] && item["colorInfo"]["color"] !== undefined) ? item["colorInfo"]["color"] : ""
                        lastNo = no;
                        contents.push(
                            
                            <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                            <td align="center">{no_index}</td>
                            {/* <td align="center">{transactionId}</td> */}
                            <td align="center">{transactionCode.toUpperCase()}</td>
                            <td align="center">{parseInt(i) +1 }</td>
                            {/* <td align="center">{transactionType}</td> */}
                            <td align="center">{item["series"]}</td>
                            {/* <td align="center">{item["seriesName"]}</td> */}
                            <td align="center">{item["colorCode"]}</td>
                            <td align="center">{color}</td>
                            <td align="center">{item["sfx"]}</td>
                            <td align="center">{item["model"]}</td>
                            <td align="center">{item["modelDescription"]}</td>
                            <td align="center">{item["price"]}</td>
                            <td align="center">{pid_date}</td>
                            <td align="center">{item["type"]}</td>
                            <td align="center">{dealerName}</td>
                            <td align="center">{buyerName}</td>
                            {/* <td align="center">{status}</td> */}
                            <td align="center">{create_date}</td>
                           

                        </tr>
                        )
                       
                    }
                    
                    
                }
            }
        }
        
       

        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>Transaction</h3>
                                <span style={{ float : "right",cursor: "pointer"}}>    
                                    <button type="button"  className="btn btn-success" style={{ width : "150px",marginTop : "16px"}} onClick={this.handleExport.bind(this)}>
                                      <img src={excelImage}  style={{width : '25px'}}/>
                                        Export
                                    </button> 
                                    <CSVLink id="csvdownload" data={csvData}  filename={export_file}></CSVLink>
                                </span>
                                {/* <span style={{ float : "right",cursor: "pointer"}}>    
                                    <button type="button"  className="btn btn-success" style={{ width : "150px",marginTop : "16px",marginRight : "10px"}} onClick={this.handleDownloadClick.bind(this)}>
                                      <img src={exportPng} style={{width : '25px'}}/>
                                        Export Form
                                    </button> 
                                </span>
                                <span style={{ float : "right",cursor: "pointer"}}>
                                    <button type="button"  className="btn btn-primary" style={{ width : "150px",marginTop : "16px",marginRight : "10px"}} onClick={this.modalAction.bind(this,"modal-import","open")}>
                                    <img src={importPng} style={{width : '25px'}}/>
                                        Import
                                    </button> 
                                </span> */}
                              
                          
                            </div>
                          
                            <div >
                                <div className="box">
                             
                                <div className="box-search-header">
                                <div className="box-header">
                                       
                                       <div className="col-md-4">
                                           <span style={{marginRight : '10px'}}>Type:</span>
                                           <span>
                                               <select id="search-type"> 
                                                    <option>all</option>
                                                    <option>pool</option>
                                                    <option>deadstock</option>
                                                </select>
                                           </span>
                                          
                                        </div>
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4"></div>
                                   </div>
                                    <div className="box-header">
                                       
                                        <div className="col-md-4">
                                            <span style={{marginRight : '10px'}}>Key:</span>
                                            <span>
                                                <input type="text" id="search-key" style={{height : "30px"}}/> 
                                            </span>
                                           
                                         </div>
                                         <div className="col-md-4"></div>
                                         <div className="col-md-4"></div>
                                    </div>
                                    <div className="box-header">
                                       
                                        <div className="col-md-4">
                                            <span  style={{marginRight : '10px'}}>Start</span>
                                            <span>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={this.onStartDateChange.bind(this)}
                                                    dateFormat = "dd-MM-yyyy"
                                                />
                                            </span>
                                        </div>
                                        <div className="col-md-4">
                                            <span  style={{marginRight : '10px',marginLeft : '10px'}}>To</span>
                                            <span>
                                                <DatePicker
                                                    selected={stopDate}
                                                    onChange={this.onStopDateChange.bind(this)}
                                                    dateFormat = "dd-MM-yyyy"
                                                />
                                            </span>
                                            
                                        </div>
                                        <div className="col-md-4"></div>
                                      
                                    </div>
                                    <div className="box-header">
                                         <div className="col-md-12">
                                            <button className="btn btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                            <button className="btn btn-outline-secondary btn-reset" onClick={this.handleResetClick.bind(this,1)} type="button">Reset</button>
                                        </div>
                                    </div>
                                </div>   
                                  {/* <div className="box-header">
                                  <div className="pull-left">
                                          <div className="input-group mb-3">
                                              <input type="text" id="search-key" style={{height : "34px"}}/>
                                              
                                                  <button className="btn btn-outline-secondary btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                                  <button className="btn btn-outline-secondary btn-reset" onClick={this.handleFetch.bind(this,1)} type="button">Reset</button>
                                          </div>
                                      </div>
                                    <div className="pull-right">
                                      
                                          <label>Sort By</label>
                                              <select  id="sort-key"  onClick={this.handleSortClick.bind(this,1)} style={{width : "200px",  height : "30px",marginLeft : "5px"}}>
                                                  <option>Type</option>
                                                  <option>Price</option>
                                                  <option>Status</option>
                                                  <option>Create</option>
                                                  
                                              </select>
                                                <img src={excelImage} onClick={this.handleExport.bind(this)} style={{cursor:'pointer'}}/>
                                              <CSVLink id="csvdownload" data={csvData}  filename={export_file}></CSVLink>
                                    </div>
                                  
                                </div> */}

                                <div className="box-header">
                                   <span className={searchKey ? "" : "hidden"}>Seach "{searchKey}" ,please use reset button to refresh query.</span>
                                   <p>
                                   <span>Found <b>{pages.totalDocs}</b> record(s).</span>
                                   </p>
                                </div>

                                {/* <div className="box-header">
                                    <div className="pull-left"></div>
                                    <div className="pull-right">
                                    <img src={excelImage} onClick={this.handleExport.bind(this)} style={{cursor:'pointer'}}/>
                                              <CSVLink id="csvdownload" data={csvData}  filename={export_file}></CSVLink>
                                    </div>
                                </div>
                             */}

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ loading ? "hidden" : "box-body"} style={{overflowX : 'auto'}}>
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>

                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <style>{'th {text-align: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                                                    
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                No
                                                                </th>
                                                                {/* <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Transaction ID
                                                                </th> */}

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Transaction Code
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Order Sequence
                                                                </th>

                                                                {/* <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Transaction Type
                                                                </th> */}

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Series
                                                                </th>

                                                                {/* <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Series Name
                                                                </th> */}
                                                             
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Color Code
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Color Name
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    SFX
                                                                </th>

                                                              
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Model
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Model Description
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Price
                                                                </th>


                                                                <th width="7%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                PDI Date
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Type
                                                                </th>
                                                                
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                 Dealer Name
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                 Buyer Name
                                                                </th>
{/* 
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                 Status
                                                                </th> */}

                                                                
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Transaction Date
                                                                </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           { contents }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                        
                                           
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handleFetch.bind(this, currentPage - 10)} className="pageButton">
                                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handleFetch.bind(this, currentPage + 10)} className="pageButton">
                                                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                           


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        
        )
    }   
}