import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import "../../containers/Login.css";
import 'react-overlay-loader/styles.css';
import './css/style.css'
import imgLogo from '../../img/toyota.png'

var config = require('../share-components/Config');

class Login extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      email: "",
      password: "",
      loading: false,
      redirect: false
    };

    this.handleSignInClick = this.handleSignInClick.bind(this);
    //localStorage.clear()
  }

  

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handlePress = event => {
     
      if(event.key == "Enter"){
          this.handleSignInClick()
      }
  }

  handleSignInClick(value, event){
    var username = document.getElementById("username").value
    var password = document.getElementById("password").value

    if (username.length == 0 || password.length == 0){
      alert("Please input email and password to sign in.");
    }else{
      const { loading } = this.state;
      this.setState({ loading: !loading })

      const url = `${config.BASE_URL}/v1/authentication`; 
      setTimeout(() => {
       
        fetch(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: "username="+username+"&password="+password
        }).then(function(response) {
          return response.json();
        }).then(result => this.onSetResult(value, result)).catch(error => {
          alert("error:"+error);
          this.setState({ loading: false });
        });

      }, 2000);
    }
}

renderRedirect = () => {
  if (this.state.redirect) {
    return <Redirect to={config.ADMIN} />
  }
}

onSetResult = (value, result) => {
  
  if (result["status"] == true){
    
    const response = result["data"]
    localStorage.setItem('session_login_user',JSON.stringify(response));
   
    localStorage.setItem('menu', "dashboard");
     window.location =  config.DASHBOARD
    // this.setState({
    //   redirect: true
    // })
    
  }else{
    alert("Sign in unsuccess!");
    const { loading } = this.state;
    this.setState({ loading: !loading })
  }
}

  render() {
    const { loading } = this.state;
    return (
      <div className="login-body">
        {/* {this.renderRedirect()} */}
        <div className="login-box">
          <div className="login-logo" style={{color : "rgb(237, 27, 47)",marginBottom : "0px"}}>
             <img src={imgLogo}/>
          </div>
          <div className="login-box-body">
            <p className="login-box-msg"><b>Sign in to manage data</b></p>
 
            {/* onSubmit={this.handleSubmit} */}
            <div className="form-group has-feedback">
                <input id="username" type="text" className="form-control login-input" onChange={this.handleChange} onKeyPress={this.handlePress} disabled={loading} autoFocus placeholder="Username"/>
                <span className="fa fa-user form-control-feedback"></span>
              </div>
              <div className="form-group has-feedback">
                <input id="password" type="password" className="form-control login-input"  onChange={this.handleChange} onKeyPress={this.handlePress} disabled={loading} placeholder="Password"/>
                <span className="fa fa-lock form-control-feedback"></span>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  {/* <a href=""> I forgot my password</a><br/><br/> */}
                  {/* <a type="submit" disabled={loading} className="btn btn-primary btn-block btn-flat"> {loading ? 'Loading...' : 'Sign In'}</a> */}
                  
                  <button type="submit" disabled={loading} onClick={this.handleSignInClick.bind(this)} className="btn  btn-block btn-flat login-btn" style={{backgroundColor : "rgb(237, 27, 47)",color:"white"}}>{loading ? 'Loading...' : 'Sign In'}</button>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

// ReactDOM.render(<Page />,document.getElementById('root'));

export default Login;
