import React, {Component} from 'react';
import dateFormat from 'dateformat';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import loadgif from '../../img/loading.gif'
import importPng from '../../img/import_csv.png'
import exportPng from '../../img/export_csv.png'
import { CSVLink } from "react-csv";
import excelImage from '../../img/excel.png'
var config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.poolData){
            window.location = config.LOGIN
        }
        
        this.state = {
            permission : userLogin.permission.poolData,
            token : userLogin.token,
            loading:false,
            upload : false,
            setPassword : false,
            roleId : "1",
            searchKey : "",
            sortBy : "createdAt",
            type : 'pool',
            sortType : -1,
            userId : -1,
            csvData : [],
            pageIndex: 1,
            export_file : "",
            pages :{
                docs : {},
                totalDocs : 0,
                limit : 50,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            }
        };

    }
  

    componentDidMount() {
        const {pageIndex} = this.state;
        this.handleFetch(pageIndex)
    }

    handleFetch(page){
        const {pages,sortBy,sortType, token,type} = this.state;
        console.log(page)
        if(page < 1 || page > pages.totalPages){
            return
        }
        document.getElementById('search-key').value = ""
        this.setState({loading:true , searchKey : "", pageIndex: page})
       
        const url = `${config.BASE_URL}/v1/product/search?page=${page}&sortBy=${sortBy}&sortType=${sortType}&perPage=${pages.limit}&type=${type}`; 

        fetch(url,{
            headers: {
                'authorization': `basic ${token}`
            },
        }).then((response) => {
            return response.json();
        }).then((result) => {
            console.log(result)
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false})
                  },500);
            }
            else{
                console.log(result)
                this.handleError(result.message)
            }
           
            
        }).catch(error => {
            console.log(error)
            this.handleError(error)
        });
    }

    handleSearchClick(page){
        var key = document.getElementById("search-key").value;
        if(key == ""){
            return
        }

        this.setState({loading:true , searchKey : key})

        setTimeout(() => {
            this.handleSearch(1)
          },500);
        
    }

    handleSearch(page){
        const {pages,searchKey,sortBy, roleId ,token , type} = this.state;
        const url = `${config.BASE_URL}/v1/product/search/key`; 
        this.setState({loading:true })

        var params ={
            "key" : searchKey ,
            "roleId" : roleId ,
            "page" : page ,
            "perPage" : pages.limit,
            "sortBy" : sortBy,
            "type" : type
        }
       // console.log("Search")
       // console.log(params)
        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            console.log(result)
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
      
        }).catch(error => {
            this.handleError(error)
        });
    }

    handlePageClick(page, event){ 
        const {searchKey} = this.state;
        //console.log("search " + searchKey)
        if(searchKey){
            
            this.handleSearch(page)
        }
        else{
            this.handleFetch(page)
        }
    
    }

    handleSortClick(page, event){ 
        const {searchKey} = this.state;
        var sortKey = document.getElementById('sort-key').value
        var sortOptions={
            "Type" : "type",
            "Price" : "price",
            "Status" : "status",
            "Create"    : "create",
        }
        var sortBy = sortOptions[sortKey]
        this.setState({sortBy:sortBy,sortType : 1});
    
        setTimeout(() => {
            if(searchKey){
                this.handleSearch(page)
            }
            else{
                this.handleFetch(page)
            }
          },500);
        
        
    }

    handleCreateClick(value, event){
       
        document.getElementById("postId").value = -1
        document.getElementById("series").value = ""
        document.getElementById("sfx").value = ""
        document.getElementById("model").value =""
        document.getElementById("colorName").value = ""
        document.getElementById("colorCode").value = ""
        document.getElementById("pid_date").value =""
        document.getElementById("carCondition").value = ""
        document.getElementById("bodyNumber").value = ""
        document.getElementById("engineNumber").value = ""
        document.getElementById("blame").value = ""
        document.getElementById("blameDetail").value = ''
        document.getElementById("price").value = ""
        document.getElementById("payCondition").value=""
        document.getElementById("type").value=""
        document.getElementById("remark").value=""
        document.getElementById("status").value=""

        this.setState({userId: -1 ,setPassword : true})
        this.modalAction("modal-add-user","open")
    
    }

    handleEditClick(id, event){
        const { pages } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
    
        document.getElementById("postId").value = result[0]._id
        document.getElementById("series").value = result[0].series
        document.getElementById("sfx").value = result[0].sfx
        document.getElementById("model").value = result[0].model
        document.getElementById("colorName").value = result[0].colorName
        document.getElementById("colorCode").value = result[0].colorCode
        document.getElementById("pid_date").value = result[0].pid_date
        document.getElementById("carCondition").value = result[0].carCondition
        document.getElementById("bodyNumber").value = result[0].bodyNumber
        document.getElementById("engineNumber").value = result[0].engineNumber
        document.getElementById("blame").value = result[0].blame
        document.getElementById("blameDetail").value = result[0].blameDetail
        document.getElementById("price").value = result[0].price
        document.getElementById("payCondition").value= result[0].payCondition
        document.getElementById("type").value= result[0].type
        document.getElementById("remark").value= result[0].remark
        document.getElementById("status").value= result[0].status

        this.modalAction("modal-add-user","open")
    }

    handleSaveClick(value, event){
        
    }

    
    modalAction(id,status){
        
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }

    handleDownloadClick() {
       
        setTimeout(() => {
          const response = {
            file: `${config.BASE_URL}/v1/csv/pool.csv`
          };
         
          window.open(response.file);
         
        }, 100);
    }
    handleDeleteClick(id, event){
        const {token} = this.state;
        const {pageIndex} = this.state;
        console.log(id)
        confirmAlert({
            title: 'Confirm',
            message: "Do you want to delete this product?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                   
                    const url = `${config.BASE_URL}/v1/product/delete`;
                
                    this.setState({ loading: true })
            
                     fetch(url, {
                        method: 'post',
                         headers: {
                            'authorization': `basic ${token}`,
                             'Content-Type': 'application/x-www-form-urlencoded',
                         },
                         body: `id=${id}`
                     }).then(function(response) {
                         return response.json();
                        }).then((result) => {
                            //console.log(result)
                            if(result.status){
                                this.handleFetch(pageIndex)
                            }
                            else{
                                this.handleError(result.message)
                            }
                               
                        }).catch(error => {
                            this.handleError(error)
                        });
                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                }
              }
            ]
          })
    }

    handleExport(){
        const {pages,sortBy,sortType,type, token} = this.state;
        console.log('Export')
        const url = `${config.BASE_URL}/v1/product/search?page1&sortBy=${sortBy}&sortType=${sortType}&perPage=${pages.totalDocs}&type=${type}`; 

        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            
            if(result.status){
                 console.log(result.data.docs)
                 var data = []
                // No	Series	Series Name	SFX	Model			Price	Type	Status	Create
                 data.push([
                    'No',
                    'Series' ,
                    'Series Name',
                    'SFX',
                    'Model',
                    'Color Name',
                    'Color Code',
                    'Price Master',
                    'Price',
                    'Type',
                    'Status',
                    'Create'
                 ])
                var index =1;
                 result.data.docs.forEach(val => {
                    let create_date = dateFormat(val.createdAt,'GMT:dd-mmm-yyyy')
                        var obj = [
                            index,
                            val.series,
                            val.seriesName,
                            val.sfx,
                            val.model,
                            val.colorName,
                            val.colorCode,
                            val.priceMaster,
                            val.price,
                            type,
                            val.statusInfo.status,
                            create_date
                        ]
                        console.log(obj)
                        data.push(obj)
                        index++
                    })
                    

                var d = new Date();
                var dd = (d.getDate() >= 10 ) ? d.getDate() : '0' + d.getDate()
                var mm = ((d.getMonth() +1) >= 10) ? d.getMonth() +1 : '0' + d.getMonth() +1
                var yyyy = d.getFullYear()
                var hh = d.getHours()
                var ii = d.getMinutes()
                var ss = d.getSeconds()
                var export_file = `export_pool_${dd}${mm}${yyyy}_${hh}${ii}${ss}.csv`
                
               this.setState({ csvData : data ,export_file : export_file})
                setTimeout(() => {
                     document.getElementById("csvdownload").click()
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
            
        }).catch(error => {
            this.handleError(error)
        });
    }


    handleFileUpload(value){
        const { roleId,token ,type} = this.state;
        const browserFiles = document.querySelector('input[type="file"]')
      
        const url = `${config.BASE_URL}/v1/product/upload`;
        
        
        var formData = new FormData()
        formData.append('roleId',roleId)
        formData.append('type',type)
        for (const file of browserFiles.files) {
          //  console.log(file)
            formData.append('file',file,file.name)
            
        }
        
        this.setState({upload : true })
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'authorization': `basic ${token}`,
             },
            body: formData
        }).then(function(response) {
            return response.json();
        }).then(result => this.onSetFileResult(value, result)).catch(error => {
            this.setState({ upload: false });
            alert(error)
        });
        
    }

    onSetFileResult = (value, result) => {
        const {pageIndex} = this.state;
        if (result["status"] == true){
            setTimeout(() => {
                this.setState({upload:false})
                this.modalAction("modal-import","close")
                this.handleFetch(pageIndex)
               
           },1000)
        }else{
            this.setState({upload:false})
            this.handleError(result.message)
        }

    }

    handleSetPasswordClick(){
        const {setPassword} = this.state;
        this.setState({setPassword: !setPassword})
    }

    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    render(){
        const { pages, loading ,searchKey,upload,permission,csvData,export_file} = this.state;
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            var contents = null
            if(pages.docs.length > 0){
                console.log(pages.docs)
                contents = []
                for(var index in pages.docs){
                    let item = pages.docs[index]
                    // let pid_date = ""
                    // if(item["pidDate"]){
                    //     pid_date = dateFormat(item["pidDate"],'dd-mmm-yyyy')
                       
                    // }
                    let status = item["statusInfo"]["status"] || item["statusInfo"][0]["status"]
                    let create_date = dateFormat(item["createdAt"],'GMT:dd-mmm-yyyy')
                    let priceMaster = (item["priceMaster"] && item["priceMaster"][0]) ? item["priceMaster"][0] : ""
                    contents.push(
                       
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{ parseInt(pages.pagingCounter) + parseInt(index)}</td>
                        <td align="center">{item["series"]}</td>
                        <td align="center">{item["seriesName"]}</td>
                        <td align="center">{item["sfx"]}</td>
                        <td align="center">{item["model"]}</td>
                        <td align="center">{item["colorInfo"]["color"]}</td>
                        <td align="center">{item["colorCode"]}</td>
                        {/* <td align="center">{ pid_date }</td> */}
                        <td align="center">{priceMaster}</td>
                        <td align="center">{item["price"]}</td>
                       
                        <td align="center">{item["type"]}</td>
                      
                        <td align="center">{status}</td>
                        <td align="center">{create_date}</td>
                       
                        <td align="center" className={(permission.delete) ? "" : "hidden"}>

                            <button type="button" disabled={status == "saled"} onClick={this.handleDeleteClick.bind(this, item["_id"])} className="btn btn-block btn-danger">Delete</button>
                        
                        </td>
                    </tr>
                    )
                    
                }
            }
        }
        
        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>Pool</h3>
                                <div className={(permission.import) ? "" : "hidden"}>
                                <span style={{ float : "right",cursor: "pointer"}}> 
                                <button type="button"  className="btn btn-success" style={{ width: '118px', height: '40px', marginTop: '16px',marginLeft: '8px'}}>
                                    <img src={excelImage} onClick={this.handleExport.bind(this)} style={{cursor:'pointer',width:'29px'}}/>
                                              <CSVLink id="csvdownload" data={csvData}  filename={export_file}></CSVLink>
                                              Export Data
                                    </button> 
                                </span>
                                <span style={{ float : "right",cursor: "pointer"}}>  
                                <a href={`${config.BASE_URL}/v1/csv/pool.csv`}>
                                    <button type="button"  className="btn btn-success" style={{ width : "150px",marginTop : "16px"}}>
                                      <img src={exportPng} style={{width : '25px'}}/>
                                        Export Form
                                    </button> 
                                </a>
                                </span>
                                <span style={{ float : "right",cursor: "pointer"}}>
                                    <button type="button"  className="btn btn-primary" style={{ width : "150px",marginTop : "16px",marginRight : "10px"}} onClick={this.modalAction.bind(this,"modal-import","open")}>
                                    <img src={importPng} style={{width : '25px'}}/>
                                        Import
                                    </button> 
                                </span>
                                </div>
                            </div>
                          
                            <div >
                                <div className="box">
                             

                                  <div className="box-header">
                                  <div className="pull-left">
                                          <div className="input-group mb-3">
                                              <input type="text" id="search-key" style={{height : "34px"}}/>
                                              
                                                  <button className="btn btn-outline-secondary btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                                  <button className="btn btn-outline-secondary btn-reset" onClick={this.handleFetch.bind(this,1)} type="button">Reset</button>
                                          </div>
                                      </div>
                                    <div className="pull-right">
                                          <label>Sort By</label>
                                              <select  id="sort-key"   style={{width : "200px",  height : "30px",marginLeft : "5px"}}>
                                                 
                                                  <option>Price</option>
                                                  <option>Status</option>
                                                  <option>Create</option>
                                                  
                                              </select>
                                              <button className="btn btn-outline-primary btn-submit" onClick={this.handleSortClick.bind(this,1)} type="button">Filter</button>
                                    </div>
                                    
                                </div>
                                <div className="box-header">
                                   <span className={searchKey ? "" : "hidden"}>Seach "{searchKey}" ,please use reset button to refresh query.</span>
                                   <p>
                                   <span>Found <b>{pages.totalDocs}</b> record(s).</span>
                                   </p>
                                </div>

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ loading ? "hidden" : "box-body"} style={{overflowX : 'auto'}}>
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>

                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <style>{'th {text-align: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                        
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                No
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Series
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Series Name
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                 SFX
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                 Model
                                                                </th>
                                                             
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Color Name
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Color Code
                                                                </th>
                                                                
                                                                {/* <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                               PDI Date
                                                                </th> */}

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Price Master
                                                                </th>
                                                            
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Price
                                                                </th>

                                                               

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Type
                                                                </th>


                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Status
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Create
                                                                </th>

                                                                <th className={(permission.delete) ? "sorting" : "hidden"} tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Delete
                                                                </th>
   
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           { contents }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                        
                                           
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage - 1)} className={currentPage > 10 ? "pageButton" : "hidden"}>
                                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handlePageClick.bind(this, stopPage + 1)} className={currentPage < totalPages && pageList.length == 10 ? "pageButton" : "hidden"}>
                                                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="modal" id="modal-import" tabIndex="-1" role="dialog">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Import</h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalAction.bind(this,"modal-import","close")} style={{marginTop : '-23px'}}>
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                        </div>
                                                        <div className="modal-body">
                                                       {/* <span className="file btn btn-lg btn-primary upload-div" > */}
							                               
							                                <input type="file" id="csv_file" name="file"/>
                                                           
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.modalAction.bind(this,"modal-import","close")}>Close</button>
                                                            <button type="button" className="btn btn-primary" onClick={this.handleFileUpload.bind(this)}>
                                                                <i className={upload ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>   
                                                                Upload
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        
        )
    }   
}