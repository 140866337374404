import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select'
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import loadgif from '../../img/loading.gif'
const config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.systemAdmin){
            window.location = config.LOGIN
        }

        this.state = {
            permission : userLogin.permission.systemAdmin,
            token : userLogin.token,
            loading:false,
            upload : false,
            setPassword : false,
            roleId : "1",
            searchKey : "",
            sortBy : "userId",
            userId : -1,
            types : {},
            dealers : {},
            typeId : 0,
            dealerCode : 0,
            perPage :50,
            show : "table",
            title : "",
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 0,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pagingCounter: 1,
                prevPage: null,
                nextPage : 2
            }
        };

    }

   
    componentDidMount() {
        this.handleFetch(1)
        this.handleTypeFetch()
        this.handleDealerFetch()
    }

    handleFetch(page){
        const {pages,sortBy, roleId,token,perPage ,searchKey} = this.state;
        
        if(page < 1 || page > pages.totalPages){
            return
        }

        this.setState({loading:true })
       
        const url = `${config.BASE_URL}/v1/user/admin`; 
    
        var params ={
            "key" : searchKey ,
            "roleId" : roleId ,
            "page" : page ,
            "perpage" : perPage,
            "sortBy" : sortBy
        }
     
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
          console.log(result)
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false ,show : "table"})
                },500);
            }
            else{
                this.handleError(result.message)
            }
            
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleTypeFetch(){
        const {token} = this.state;
        
        const url = `${config.BASE_URL}/v1/userType?page=1&perPage=1000`; 
        //console.log(url)

        fetch(url,{
            headers: {
                'authorization': `basic ${token}`
            },
        }).then((response) => {
            return response.json();
        }).then((result) => {
            //console.log(result)
            if(result.status){
                this.setState({types: result.data.docs});
           
                
            }
            else{
                this.handleError(result.message)
            }
            
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleDealerFetch(){
        const {token} = this.state;

        const url = `${config.BASE_URL}/v1/dealer?&page=1&perpage=5000`; 
      
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            console.log(result)
            if(result.status){
                this.setState({dealers: result.data.docs});
           
             
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });

    }

    handleResetClick(){
        document.getElementById("search-key").value = ""
        this.setState({searchKey : ""})
        setTimeout(() => {
            this.handleFetch(1)
        },500);
    }

    handleSearchClick(){
        var key = document.getElementById("search-key").value;
        if(key == ""){
            return
        }

        this.setState({searchKey : key})

        setTimeout(() => {
            this.handleFetch(1)
        },500);
        
    }

    handlePageClick(page, event){ 
        setTimeout(() => {
            this.handleFetch(page)
        },500);
    }


    handleSortClick(page, event){ 
        const {searchKey} = this.state;
        var sortKey = document.getElementById('sort-key').value
        if(sortKey == "Sort By"){
            return;
        }
        var sortOptions={
            "Username" : "username",
            "Firstname" : "firstname",
            "Type"    : "type",
            "Status"  : "status"
        }
    
        var sortBy = sortOptions[sortKey]
        this.setState({sortBy:sortBy});
    
        setTimeout(() => {
           this.handleFetch(page)
            
        },500);
        
        
    }

    handleCreateClick(value, event){
       
        document.getElementById("userId").value = -1
        document.getElementById("username").value = ""
        document.getElementById("firstname").value =""
        document.getElementById("lastname").value =""
      //  document.getElementById("dealer").value = "0"
        document.getElementById("tel").value =""
        document.getElementById("user_email").value =""
        document.getElementById("status").value ="Activated"
        document.getElementById("user-password").value=""
        document.getElementById("confirm-password").value=""

        this.setState({userId: -1,setPassword : true})
        this.setState({show : 'add',title : "Create"})
    
    }

    handleEditClick(id, event){
        const { pages } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
        console.log(result)
        if (result.length > 0) {
            document.getElementById("userId").value = result[0].userId
            document.getElementById("username").value = result[0].username
            document.getElementById("firstname").value = result[0].firstname
            document.getElementById("lastname").value = result[0].lastname
            // document.getElementById("type").value = result[0].typeId.toString()
            // document.getElementById("dealer").value = result[0].dealerId.toString()
            document.getElementById("tel").value = result[0].tel
            document.getElementById("user_email").value = result[0].email
            document.getElementById("status").value = result[0].status
        
        
            
            document.getElementById("password-check").checked = false;
        // document.getElementById("modal-body").style.height = '430px'
        this.setState({userId : result[0].userId,setPassword : false ,typeId : result[0].typeId,dealerCode : result[0].dealerCode, show : 'add',title : "Update"})
        }
        
    }

    handleTypeChange(val){
        this.setState({typeId : val.value})
    }

    handleDealerChange(val){
        this.setState({dealerCode : val.value})
    }

    handleSaveClick(value, event){
        const { roleId , token ,setPassword ,typeId ,dealerCode,permission} = this.state;
        
        var user = {
            userId : document.getElementById("userId").value,
            username : document.getElementById("username").value,
           
            firstname :document.getElementById("firstname").value || " ",
            lastname : document.getElementById("lastname").value || " ",
            typeId : typeId,
            dealerCode :dealerCode,
            tel : document.getElementById("tel").value || " ",
            email : document.getElementById("user_email").value || " ",
            status : document.getElementById("status").value,
            
            roleId : roleId
        }
        
        if(user.username == ""){
            alert("Please fill in username.")
            return
        }
        
        if(setPassword){
            var password = document.getElementById("user-password").value
            var confirmPassword = document.getElementById("confirm-password").value
            if(password == ""){
                alert("Please fill in password.")
                return
            }
            if(password != confirmPassword){
                alert("Password and confirm password does not macth!.")
                return
            }
            else{
                user.password = password
            }
        }
        console.log(user)
        var act = (user.userId == -1) ? "create" : "update"
        
        const url = `${config.BASE_URL}/v1/user/${act}`; 

        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user)
        }).then((response) => {
            return response.json();
        }).then((result) => {
           // console.log(result)
            if(result.status){
                //this.modalAction("modal-add-user","close")
                this.handleFetch(1)
            }
            else{
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.handleError(error)
        });
    }

    

    handleDeleteClick(id, event){
        const { token } = this.state;
        confirmAlert({
            title: 'Confirm',
            message: "Do you want to delete this user?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                   
                    const url = `${config.BASE_URL}/v1/user/delete`;
                
                    this.setState({ loading: true })
            
                     fetch(url, {
                        method: 'post',
                         headers: {
                            'authorization': `basic ${token}`,
                             'Content-Type': 'application/x-www-form-urlencoded',
                         },
                         body: `id=${id}`
                     }).then(function(response) {
                         return response.json();
                        }).then((result) => {
                            //console.log(result)
                            if(result.status){
                                this.handleFetch(1)
                            }
                            else{
                                alert('Your request is not complete');
                            }
                               
                        }).catch(error => {
                            this.handleError(error)
                        });
                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                }
              }
            ]
          })
    }

    
    modalAction(id,status){
        
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }

   

    handleFileUpload(value){
        const { roleId , token } = this.state;
        const browserFiles = document.querySelector('input[type="file"]')
      
        const url = `${config.BASE_URL}/v1/user/upload`;
        
        
        var formData = new FormData()
        formData.append('roleId',roleId)
        for (const file of browserFiles.files) {
            //console.log(file)
            formData.append('file',file,file.name)
            
        }
        this.setState({upload : true})
        
        fetch(url, {
            method: 'POST',
            headers: {
                'authorization': `basic ${token}`
             },
            body: formData
        }).then(function(response) {
            return response.json();
        }).then(result => this.onSetFileResult(value, result)).catch(error => {
            this.setState({ upload: false });
            this.handleError(error)
        });
        
    }

    onSetFileResult = (value, result) => {
        
        if (result["status"] == true){

            setTimeout(() => {
                this.setState({upload:false})
                this.modalAction("modal-import","close")
                this.handleFetch(1)
               
           },1000)
            
           
        }else{
            this.setState({upload:false})
            this.handleError(result.message)
        }

    }

    handleSetPasswordClick(){
        const {setPassword} = this.state;
        var w = (!setPassword == true) ? '525px' : '430px'
        document.getElementById("modal-body").style.height = w
        this.setState({setPassword: !setPassword})
    }

    handleShowClick(show){
        this.setState({show : show});
    }

    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    render(){
        const { pages, loading ,searchKey,userId,setPassword,types,dealers,typeId,dealerCode,permission,show,title} = this.state;
        
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            if(pages.docs.length > 0){
                contents = []
                pages.docs.map((item, index) => (
                    contents.push(
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{ parseInt(pages.pagingCounter) + parseInt(index)}</td>
                       
                        <td align="center">{item["username"]}</td>
                        <td align="center">{item["firstname"]}</td>
                        <td align="center">{item["lastname"]}</td>
                        <td align="center">{item["typeInfo"]["type"] || "admin"}</td>
                        <td align="center">{item["status"]}</td>
                      
                        <td align="center"className={(permission.edit) ? "" : "hidden"}>
                            
                            <button type="button" onClick={this.handleEditClick.bind(this, item["_id"])} className="btn btn-block btn-warning">Edit</button>

                        </td> 
                        <td align="center" className={(permission.delete) ? "" : "hidden"}>
                        
                            <button type="button" disable={loading.toString()} onClick={this.handleDeleteClick.bind(this, item["_id"])} className="btn btn-block btn-danger">Delete</button>
                        
                        </td>
                    </tr>
                    )
                    
                ))
            }
        }
        
        var type_options = []
        var dealer_options = []
        dealer_options.push( { value: '-1', label: '<-Not Dealer->' } )
                
        for(var i in types){
            type_options.push( { value: types[i].typeId, label: types[i].type } )
        }
        
        for(var i in dealers){
            dealer_options.push( { value: dealers[i].dealerCode, label: dealers[i].dealerNameTH } )     
        }
        
        console.log(JSON.stringify(type_options, undefined, 2))
        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>User Management</h3>
                                <span style={{ float : "right",cursor: "pointer"}}>    
                                    {/* <button type="button"  className="btn btn-primary" style={{ width : "150px",marginTop : "16px"}} onClick={this.modalAction.bind(this,"modal-import","open")}>Import</button>  */}
                                </span>
                          
                            </div>
                          
                           
                                <div  className={show== "table" ? "box" : "hidden"}>
                                    <div className="box-header">
                                    <div className={(permission.create) ? "" : "hidden"}>
                                        <div className= 'pull-left'  style={{cursor : "pointer"}} onClick={this.handleCreateClick.bind(this)}>
                                            <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px"}}>
                                                <i className="fa fa fa-plus"></i>
                                                <span>&nbsp;Create</span>
                                                <span className="pull-right-container"></span>
                                            </a>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="box-header">
                                    <div className="pull-left">
                                            <div className="input-group mb-3">
                                                <input type="text" id="search-key" style={{height : "34px"}}/>
                                                
                                                    <button className="btn btn-outline-secondary btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                                    <button className="btn btn-outline-secondary btn-reset" onClick={this.handleResetClick.bind(this,1)} type="button">Reset</button>
                                            </div>
                                        </div>
                                      <div className="pull-right">
                                                <span style={{fontWeight : 'bold'}}>Sort By</span>
                                                <select  id="sort-key"  style={{width : "200px",  height : "30px",marginLeft : "5px"}}>
                                                    <option>Username</option>
                                                    <option>Firstname</option>
                                                    <option>Type</option>
                                                    <option>Status</option>
                                                </select>
                                                <button className="btn btn-outline-primary btn-submit" onClick={this.handleSortClick.bind(this,1)} type="button">Submit</button>
                                      </div>
                                      
                                  </div>
                                  <div className="box-header">
                                     <span className={searchKey ? "" : "hidden"}>Seach "{searchKey}" ,please use reset button to refresh query.</span>
                                     <p>
                                   <span>Found <b>{pages.totalDocs}</b> record(s).</span>
                                   </p>
                                  </div>

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ loading ? "hidden" : "box-body"} style={{overflowX : 'auto'}}>
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <style>{'th {text-align: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                                                      
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                No.
                                                                </th>
                                                            

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Username
                                                                </th>

                                                                
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                First Name
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Last Name
                                                                </th>


                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Type
                                                                </th>


                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Status
                                                                </th>


                                                               
                                                                <th  className={(permission.edit) ? "sorting" : "hidden"} tabIndex="3"  rowSpan="1" colSpan="1"  align="center" style={{width : '80px'}}>
                                                                    <span className='fa fa-edit'/> Edit
                                                                </th>
                                                                
                                                                <th   className={(permission.delete) ? "sorting" : "hidden"} tabIndex="4"  rowSpan="1" colSpan="1" style={{width : '80px'}}>
                                                                    <span className='fa fa-bitbucket'/> Delete
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           { contents }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                        
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage - 1)} className={currentPage > 10 ? "pageButton" : "hidden"}>
                                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage + 1)} className={currentPage < totalPages && pageList.length == 10 && totalPages > 10 ? "pageButton" : "hidden"}>
                                                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                         
                                        </div>
                                    </div>
                                </div>

                                <div className={show== "add" ? "box" : "hidden"}>
                                    <div className= "box-header" >
                                   
                                        <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleShowClick.bind(this,"table")}>
                                       <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px",backgroundColor : "black"}}>
                                       <i className="fa fa fa-remove"></i>
                                             <span style={{marginLeft : '5px'}}>Close</span>
                                           <span className="pull-right-container"></span>
                                       </a>
                                   </div>
                                   
                                    </div>

                                    <div className="modal-header">
                                        <h5 className="modal-title"><b>{title}</b></h5>                        
                                    </div>
                                    <div id="modal-body" className="modal-body col-md-12" style={{height : 'auto'}}>
                                                            
                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Username<span className="required-label">*</span></label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="username"  style={{width : "100%"}} />
                                                                    <input type="hidden" className="form-control" id="userId"  style={{width : "100%"}} />
                                                                </div>
                                                            </div>

                                                            <div className={userId > -1 ? "row modal-box" :"hidden"} style={{marginTop : "15px"}}>
                                                                <div className="col-md-2 ">
                                                                   
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="Checkbox" id="password-check" onClick={this.handleSetPasswordClick.bind(this)} style={{ cursor : "pointer"}}/><span style={{marginLeft:'5px'}}>Set Password</span>
                                                                </div>
                                                            </div>

                                                            <div className={setPassword ? "" : "hidden"}>
                                                                <div className="row modal-box">
                                                                    <div className="col-md-2 ">
                                                                        <label>Password<span className="required-label">*</span></label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <input type="password" className="form-control" id="user-password"   style={{width : "80%"}} />
                                                                    </div>
                                                                </div>

                                                                <div className="row modal-box">
                                                                    <div className="col-md-2 ">
                                                                        <label>Confirm Password<span className="required-label">*</span></label>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <input type="password" className="form-control" id="confirm-password"  style={{width : "80%"}} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>First Name</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="firstname"    style={{width : "100%"}} />
                                                                </div>
                                                            </div>
                                                                
                                                        
                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Last Name</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="lastname"    style={{width : "100%"}} />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Type</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <Select 
                                                                        value={type_options.filter(option => parseInt(option.value) == parseInt(typeId))[0] || type_options[0]}
                                                                        options={type_options}
                                                                        onChange= {value => this.handleTypeChange(value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        
                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Dealer</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <Select 
                                                                        value={dealer_options.filter(option => option.value == dealerCode)}
                                                                        options={dealer_options}
                                                                        onChange= {value => this.handleDealerChange(value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        

                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Tel</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="tel"   style={{width : "40%"}} />
                                                                </div>
                                                            </div>

                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Email</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="user_email"  style={{width : "100%"}} />
                                                                </div>
                                                            </div>

                                                           
                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Status</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                        <select className="form-control" id="status"  >
                                                                            <option>Activated</option>
                                                                            <option>Inactivated</option>
                                                                        </select>
                                                                </div>
                                                            </div>

                                                             {/* <div className="row modal-box">
                                                                 <div className="col-md-2 ">
                                                                    <label>Action</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="action"   style={{width : "100%"}} />
                                                                </div>
                                                            </div> */}
                                                            
                                                        </div>
                                    <div className="modal-footer" style={{textAlign : 'center'}}>
                                        {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleShowClick.bind(this,"table")} style={{width : '150px',marginBottom : '20px'}}>Close</button> */}
                                        <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : '150px',marginBottom : '20px',marginTop : '30px'}}>
                                            <i className="fa fa-save"></i>
                                            <span style={{marginLeft : '5px'}}>Save</span>
                                        </button>
                                    </div>
                                </div>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}