import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import loadgif from '../../img/loading.gif'
import importPng from '../../img/import_csv.png'
import exportPng from '../../img/export_csv.png'

import ic_car_back from '../../img/color/ic_car_back.png'
import car_black from '../../img/color/car_black.png'
import car_blue from '../../img/color/car_blue.png'
import car_brown from '../../img/color/car_brown.png'
import car_gold from '../../img/color/car_gold.png'
import car_gray from '../../img/color/car_gray.png'
import car_green from '../../img/color/car_green.png'
import car_orange from '../../img/color/car_orange.png'
import car_purple from '../../img/color/car_purple.png'
import car_red from '../../img/color/car_red.png'
import car_skyblue from '../../img/color/car_skyblue.png'
import car_white from '../../img/color/car_white.png'
import car_yellow from '../../img/color/car_yellow.png'
import { isNull } from 'util';

var config = require('../share-components/Config');
var colorInfo = {
    "ic_car_back": ic_car_back,
    "car_black": car_black,
    "car_blue": car_blue,
    "car_brown": car_brown,
    "car_gold": car_gold,
    "car_gray": car_gray,
    "car_green": car_green,
    "car_orange": car_orange,
    "car_purple": car_purple,
    "car_red": car_red,
    "car_skyblue": car_skyblue,
    "car_white": car_white,
    "car_yellow": car_yellow
}
export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.seriesData){
            window.location = config.LOGIN
        }

        this.state = {
            pickers:[],
            showImageInput:false,
            permission : userLogin.permission.seriesData,
            token : userLogin.token,
            loading:false,
            upload : false,
            roleId : "0",
            searchKey : "",
            sortBy : "",
            show : "table",
            title : "Color Management",
            selectPicture: "",
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 50,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            }
        };

    }
  
    componentDidMount() {
        this.getColors()
        this.handleFetch(1)
    }

    getColors(){
        const {token} = this.state;

        const url = `${config.BASE_URL}/v1/color/picker`; 
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pickers: result.data});
            }
        }).catch(error => {
            
        });

    }

    handleFetch(page){
        const {pages,sortBy, token} = this.state;

        if(page < 1 || page > pages.totalPages){
            return
        }
        document.getElementById('search-key').value = ""
        this.setState({loading:true , searchKey : ""})
       
        const url = `${config.BASE_URL}/v1/color?page=${page}&sortBy=${sortBy}&perPage=${pages.perPage}&fixPage=true`; 
        //console.log("Fetch")
        //console.log(url)
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false ,show : "table"})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });

    }

    handleSearchClick(page){
        var key = document.getElementById("search-key").value;
        if(key == ""){
            return
        }

        this.setState({loading:true , searchKey : key})

        setTimeout(() => {
            this.handleSearch(1)
          },500);
        
    }

    handleSearch(page){
        const {pages,searchKey,sortBy, roleId,token} = this.state;
        const url = `${config.BASE_URL}/v1/color/search`; 
        this.setState({loading:true })

        var params ={
            "key" : searchKey ,
            "roleId" : roleId ,
            "page" : page ,
            "perPage" : pages.perPage,
            "sortBy" : sortBy
        }
        //console.log("Search")
       // console.log(params)
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false ,show : "table"})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleDownloadClick() {
       
        setTimeout(() => {
          const response = {
            file: `${config.BASE_URL}/v1/csv/color.csv`
          };
         
          window.open(response.file);
         
        }, 100);
    }

    handleSortClick(page, event){ 
        const {searchKey} = this.state;
        var sortKey = document.getElementById('sort-key').value
        if(sortKey == "Sort By"){
            return;
        }
        var sortOptions={
            "Color Code" : "colorCode",
            "Color" : "color",
            "Color LTD" : "colorLTD",
        }
        var sortBy = sortOptions[sortKey]
        this.setState({sortBy:sortBy});
    
        setTimeout(() => {
            if(searchKey){
                this.handleSearch(page)
            }
            else{
                this.handleFetch(page)
            }
          },500);
    }

    handlePageClick(page, event){ 
        const {searchKey} = this.state;
        //console.log("search " + searchKey)
        if(searchKey){
            
            this.handleSearch(page)
        }
        else{
            this.handleFetch(page)
        }
    
    }

    handleCreateClick(value, event){
        document.getElementById("colorId").value = -1
        document.getElementById("colorCode").value = ""
        document.getElementById("colorName").value = ''
        document.getElementById("colorLTD").value = ""
        document.getElementById("hexColor").value = ""
       
        this.setState({show : 'add', title : "Create Color", showImageInput: true, selectPicture: ""})
    }

    handleEditClick(id, event){
        this.setState({showImageInput: false})
        const { pages } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
        //console.log(result)
        document.getElementById("colorId").value = result[0]._id
        document.getElementById("colorCode").value = result[0].colorCode
        document.getElementById("colorName").value = result[0].color
        document.getElementById("colorLTD").value = result[0].colorLTD
        document.getElementById("hexColor").value = result[0].hexColor
        
        this.setState({show : 'add',title : "Update Color", selectPicture: result[0].colorPicture})
    }

    handleMoreImage(value, event){
        const {showImageInput} = this.state
        this.setState({showImageInput: !showImageInput})
    }

    handleSaveClick(value, event){
        const {token} = this.state;
        var id =  document.getElementById("colorId").value
        var colorCode = document.getElementById("colorCode").value
        var color = document.getElementById("colorName").value
        var colorLTD = document.getElementById("colorLTD").value
        var hexColor = document.getElementById("hexColor").value
        var colorPicture = document.getElementById("color-picture").value
        const browserFiles = document.querySelector('input[name="file"]')
        
        if(colorCode == "" || color== "" || colorLTD=="" || hexColor==""){
            alert("Please fill required filed.")
            return;
        }
        var formData = new FormData()
        formData.append('id', id)
        formData.append('colorCode',colorCode)
        formData.append('color',color)
        formData.append('colorLTD',colorLTD)
        formData.append('hexColor',hexColor)

        if (colorPicture) {
            var colorPickers = document.getElementById("color-picture");
            var pickerId = colorPickers.options[colorPickers.selectedIndex].id
            formData.append('colorPicture',`${pickerId}`)
        }
        
        if (browserFiles) {
            for (const file of browserFiles.files) {
                console.log(file)
                formData.append('file',file,file.name)
                
            }
        }
        
        var act = (id == -1) ? 'create' : 'update'
        const url = `${config.BASE_URL}/v1/color/${act}`; 
        
        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`
            },
            body: formData
        }).then((response) => {
            return response.json();
        }).then((result) => {
            //.log(result)
            if(result.status){
                //this.modalAction("modal-add-series","close")
                this.handleFetch(1)
            }
            else{
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleDeleteClick(id, event){
        const {token} = this.state;
        confirmAlert({
            title: 'Confirm',
            message: "Do you want to delete this user?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                   
                    const url = `${config.BASE_URL}/v1/color/delete`;
                
                    this.setState({ loading: true })
            
                     fetch(url, {
                        method: 'post',
                         headers: {
                            'authorization': `basic ${token}`,
                             'Content-Type': 'application/x-www-form-urlencoded',
                         },
                         body: `id=${id}`
                     }).then(function(response) {
                         return response.json();
                        }).then((result) => {
                            //console.log(result)
                            if(result.status){
                                this.handleFetch(1)
                            }
                            else{
                                this.handleError(result.message)
                            }
                               
                        }).catch(error => {
                            this.handleError(error)
                        });
                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                }
              }
            ]
        })
    }


    onSetResult = (value, result) => {
        
        if (result["status"] == true){
            window.location.reload();
        }else{
            alert('Your request is not complete seriesname or email is already.'+JSON.stringify(result));
        }

        const { loading } = this.state;
        this.setState({ loading: !loading })
    }
    
    modalAction(id,status){
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }

    handleFileUpload(value){
        const {  token } = this.state;
       
        const browserFiles = document.querySelector('input[name="csvfile"]')
      
        const url = `${config.BASE_URL}/v1/color/upload`;
        //console.log(url)
        
        var formData = new FormData()
       
        for (const file of browserFiles.files) {
            //console.log(file)
            formData.append('file',file,file.name)
            
        }
        
        this.setState({ upload: true })

        fetch(url, {
            method: 'POST',
            headers: {
                'authorization': `basic ${token}`
             },
            body: formData
        }).then(function(response) {
            return response.json();
        }).then(result => this.onSetFileResult(value, result)).catch(error => {
            this.setState({ loading: false ,upload : false});
            alert(error)
        });
        
    }

    onSetFileResult = (value, result) => {
        this.setState({ upload: false })
        if (result["status"] == true){

            //alert("Successfully");
            this.modalAction("modal-import","close")
            this.handleFetch(1)
        }else{
            this.handleError(result.message)
        }

    }

    handleShowClick(show){
        this.setState({show : show, title: "Color management"});
    }
   
    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    render(){
        const { pages, pickers, loading ,searchKey,upload ,permission,show,title, selectPicture, showImageInput} = this.state;
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;
        var optiontions = pickers.map(x => {
            if (`${x.colorPicture}` == selectPicture) {
                return (<option key={x.colorLTD} id={x.colorPicture} value={x.colorLTD} selected>{x.colorLTD}</option>)
            }
            return (<option key={x.colorLTD} id={x.colorPicture} value={x.colorLTD}>{x.colorLTD}</option>)
        })
        /*
        var optiontions = Object.keys(colorInfo).filter(x => x != "ic_car_back").map(x => {
            let imageFilePath = `../../img/color/${x}.png`
            if (`${x}.png` == selectPicture) {
                return (<option value={x} selected>{x}</option>)
            }
            return (<option value={x}>{x}</option>)
        })*/
        
        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            var contents = null
            if(pages.docs.length > 0){
                contents = []
                for(var index in pages.docs){
                    let item = pages.docs[index]
                   
                    let image = ""
                    var url = `${config.BASE_URL}/v1/${item["colorPicture"]}`
                    if (!url.includes("icons/colors") && !url.includes("images/colors")) {
                        url = `${config.BASE_URL}/v1/icons/colors/${item["colorPicture"]}`
                    }
                    
                    contents.push(
                       
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{ parseInt(pages.pageIndex) + parseInt(index)}</td> 
                        <td align="center">{item["colorCode"]}</td>
                        <td align="center">{item["color"]}</td>
                        <td align="center">{item["colorLTD"]}</td>
                        <td align="center">{item["hexColor"]}</td>
                        <td align="center">
                            <div className={"car-color"}>
                                <img src={url} style={{width : '25px'}}/>
                                {/* <i className="fa fa-car" aria-hidden="true" style={{ fontSize : "22px",color : item["hexColor"]}}></i> */}
                            </div>
                        </td>
                        <td align="center" className={(permission.edit) ? "" : "hidden"}>
                            
                            <button type="button" onClick={this.handleEditClick.bind(this, item["_id"])} className="btn btn-block btn-warning">Edit</button>

                        </td> 
                        <td align="center" className={(permission.delete) ? "" : "hidden"}>
                        
                            <button type="button" disable={loading.toString()} onClick={this.handleDeleteClick.bind(this, item["_id"])} className="btn btn-block btn-danger">Delete</button>
                        
                        </td>
                    </tr>
                    )
                    
                }
            }
        }
        
        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                            <div className={(permission.import) ? "" : "hidden"}>
                                <h3 style={{float : "left"}}>{title}</h3>
                                {/* <h3 style={{float : "left"}}>Color Management</h3> */}
                                <span style={{ float : "right",cursor: "pointer"}}> 
                                <a href={`${config.BASE_URL}/v1/csv/color.csv`}>
                                    <button type="button"  className="btn btn-success" style={{ width : "150px",marginTop : "16px"}}>
                                      <img src={exportPng} style={{width : '25px'}}/>
                                        Export Form
                                    </button> 
                                </a>
                                </span>
                                <span style={{ float : "right",cursor: "pointer"}}>
                                    <button type="button"  className="btn btn-primary" style={{ width : "150px",marginTop : "16px",marginRight : "10px"}} onClick={this.modalAction.bind(this,"modal-import","open")}>
                                    <img src={importPng} style={{width : '25px'}}/>
                                        Import
                                    </button> 
                                </span>
                                </div>
                            </div>
                          
                            <div  className={show== "table" ? "box" : "hidden"}>
                                <div className="box-header">
                                    <div className={(permission.create) ? "" : "hidden"}>
                                      <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleCreateClick.bind(this)}>
                                          <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px"}}>
                                              <i className="fa fa fa-plus"></i>
                                              <span>&nbsp;Create</span>
                                              <span className="pull-right-container"></span>
                                          </a>
                                      </div>
                                      </div>
                                  </div>

                                  <div className="box-header">
                                  <div className="pull-left">
                                          <div className="input-group mb-3">
                                              <input type="text" id="search-key" style={{height : "34px"}}/>
                                              
                                                  <button className="btn btn-outline-secondary btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                                  <button className="btn btn-outline-secondary btn-reset" onClick={this.handleFetch.bind(this,1)} type="button">Reset</button>
                                          </div>
                                      </div>

                                      <div className="pull-right">
                                     
                                                <select  id="sort-key"  style={{width : "200px",  height : "30px",marginLeft : "5px"}}>
                                                    <option>-- Sort By --</option>
                                                    <option>Color Code</option>
                                                    <option>Color</option>
                                                    <option>Color LTD</option>
                                                </select>
                                                <button className="btn btn-outline-primary btn-submit" onClick={this.handleSortClick.bind(this,1)} type="button">Filter</button>
                                      </div>
                                    
                                </div>
                                <div className="box-header">
                                   <span className={searchKey ? "" : "hidden"}>Seach "{searchKey}" ,please use reset button to refresh query.</span>
                                   <p>
                                   <span>Found <b>{pages.totalDocs}</b> record(s).</span>
                                   </p>
                                   
                                </div>

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ loading ? "hidden" : "box-body"} style={{overflowX : 'auto'}}>
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <style>{'th {text-align: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                No.
                                                                </th>
                                                             
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Color Code
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Color
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Color LTD
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                hexColor
                                                                </th>
                                                                
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Color Picture
                                                                </th>

                                                                 
                                                                <th  className={(permission.edit) ? "sorting" : "hidden"} tabIndex="3"  rowSpan="1" colSpan="1"  align="center" style={{width : '80px'}}>
                                                                    <span className='fa fa-edit'/> Edit
                                                                </th>
                                                                
                                                                <th   className={(permission.delete) ? "sorting" : "hidden"} tabIndex="4"  rowSpan="1" colSpan="1" style={{width : '80px'}}>
                                                                    <span className='fa fa-bitbucket'/> Delete
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           { contents }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage - 1)} className={currentPage > 10 ? "pageButton" : "hidden"}>
                                                            <i className="fa fa-backward" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage + 1)} className={currentPage < totalPages && pageList.length == 10 && totalPages > 10 ? "pageButton" : "hidden"}>
                                                            <i className="fa fa-forward" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

    
                                        </div>
                                    </div>
                                </div>
                            
                            <div className="modal" id="modal-import" tabIndex="-1" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Import</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalAction.bind(this,"modal-import","close")} style={{marginTop : '-23px'}}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                        </div>
                                        <div className="modal-body">
                                        {/* <span className="file btn btn-lg btn-primary upload-div" > */}
                                            
                                            <input type="file" id="csvfile" name="csvfile"/>
                                            
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.modalAction.bind(this,"modal-import","close")}>Close</button>
                                            <button type="button" className="btn btn-primary" onClick={this.handleFileUpload.bind(this)}>
                                            <i className={upload ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>
                                                Upload
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                            <div  className={show== "add" ? "box" : "hidden"}>
                                <div className= "box-header" >
                                   
                                   <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleShowClick.bind(this,"table")}>
                                       <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px",backgroundColor : "black"}}>
                                       <i className="fa fa fa-remove"></i>
                                            <span style={{marginLeft : '5px'}}>Close</span>
                                            <span className="pull-right-container"></span>
                                       </a>
                                   </div>
                                   
                                 </div>

                                 <div className="modal-content">
                                    <div className="modal-body col-md-12" style={{height : "auto"}} >

                                            <div className="row modal-box">
                                            <div className="col-md-2 ">
                                                <label>Color Code<span className="require-label">*</span></label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="colorCode"  />
                                                <input type="hidden"  id="colorId"  />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2 ">
                                                <label>Color<span className="require-label">*</span></label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="colorName"   />
                                            </div>
                                        </div>

                                            <div className="row modal-box">
                                            <div className="col-md-2 ">
                                                <label>Color LTD<span className="require-label">*</span></label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="colorLTD"    />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2 ">
                                                <label>hexColor<span className="require-label">*</span></label>
                                            </div>
                                            <div className="col-md-10">
                                                <input type="text" className="form-control" id="hexColor"    />
                                            </div>
                                        </div>

                                        <div className="row modal-box">
                                            <div className="col-md-2 ">
                                                <label>Color Icon</label>
                                            </div>
                                            <div className="col-md-10">
                                                <select  id="color-picture"  style={{width : "200px",  height : "30px",marginLeft : "5px"}}>
                                                    <option>-- เลือก --</option>
                                                    {optiontions}
                                                </select>
                                                &nbsp;&nbsp;
                                                &nbsp;&nbsp;
                                                {
                                                    title == "Update Color" ? (
                                                        <button type="button" key={"uploadFiles"} onClick={this.handleMoreImage.bind(this)}>
                                                        <i className="fa fa-ellipsis-h"></i>
                                                        </button>
                                                    ):(<div />)
                                                }
                                                
                                            </div>
                                        </div>
                                        {
                                            showImageInput ? (
                                                <div className="row modal-box">
                                                    <div className="col-md-2 ">
                                                        <label>Add new icon</label>
                                                    </div>
        
                                                    <div className="col-md-10">
                                                        <input type="file" id="file" name="file" accept="image/*" style={{display: "true"}}/>
                                                        <div style={{color : 'red'}}>Icon size is 100x100 pixel (.jpg .jpeg .png .bmp)</div>
                                                    </div>
                                                </div>
                                            ):(<div />)
                                        }
                                    </div>
                                    <div className="modal-footer" style={{textAlign : 'center'}}>
                                        {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleShowClick.bind(this,"table")} style={{width : '150px',marginBottom : '20px'}}>Close</button> */}
                                        <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : '150px',marginBottom : '20px',marginTop : '30px'}}>
                                            <i className="fa fa-save"></i>
                                            <span style={{marginLeft : '5px'}}>Save</span>
                                        </button>
                                    </div>
                                
                                </div>
                            </div>
                                             
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}