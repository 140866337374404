import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select'
import { CSVLink } from "react-csv";
import {Parser} from 'json2csv'
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import loadgif from '../../img/loading.gif'
import importPng from '../../img/import_csv.png'
import exportPng from '../../img/export_csv.png'
import excelImage from '../../img/excel.png'
var config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.supply){
            window.location = config.LOGIN
        }

        this.state = {
            permission : userLogin.permission.supply,
            token : userLogin.token,
            loading:false,
            upload : false,
            searchKey : "",
            sortBy : "createdAt",
            zones : [],
            provinces : [],
            show : "table",
            title: "",
            zoneId :1,
            csvData : [],
            export_file : "",
            zone : "",
            provinceId : 2,
            province : "",
            pageIndex: 1,
            editId:"",
            previewItem:null,
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 50,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            }
        };

    }

    componentDidMount() {
        const {pageIndex} = this.state;
        this.handleFetch(pageIndex)
    }

    handleFetch(page){
        const {pages,sortBy, token} = this.state;

        if(page < 1 || page > pages.totalPages){
            return
        }
        document.getElementById('search-key').value = ""
        this.setState({loading:true , searchKey : "", pageIndex: page})
       
        const url = `${config.BASE_URL}/v1/stock/all?&page=${page}&sortBy=${sortBy}&perpage=${pages.perPage}`; 
        let configs = {
            headers: {
                'authorization': `basic ${token}`,
            }
        }
        fetch(url, configs).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pages: result.data});
                setTimeout(() => {
                    this.setState({loading:false , show : "table", title: ""})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
        }).catch(error => {
            this.handleError(error)
        });

    }
    
    handleSortClick(page, event){ 
        const {searchKey} = this.state;
        var sortKey = document.getElementById('sort-key').value
        
        if(sortKey == "Sort By"){
            return;
        }

        var sortOptions={
            "Name" : "name",
            "Status"    : "status",
        }

        var sortBy = sortOptions[sortKey]
        this.setState({sortBy:sortBy});
    
        setTimeout(() => {
            if(searchKey){
                this.handleSearch(page)
            }
            else{
                this.handleFetch(page)
            }
        },500);
    }

    handleSearchClick(page){
        var key = document.getElementById("search-key").value;
        if(key == ""){
            return
        }

        this.setState({loading:true , searchKey : key})

        setTimeout(() => {
            this.handleSearch(1)
          },500);
        
    }

    handleSearch(page){
        const {pages,searchKey,sortBy, roleId,token} = this.state;
        const url = `${config.BASE_URL}/v1/stock/search`; 
        this.setState({loading:true })

        var params ={
            "key" : searchKey,
            "roleId" : roleId,
            "page" : page,
            "perPage" : pages.perPage,
            "sortBy" : sortBy
        }
        
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });
    }

    handlePageClick(page, event){ 
        const {searchKey} = this.state;
        if(searchKey){
            
            this.handleSearch(page)
        }
        else{
            this.handleFetch(page)
        }
    }
    
    handleCreateClick(value, event){
        document.getElementById("stockId").value = -1
        document.getElementById("stockName").value = ""
        document.getElementById("status").value = "Active"
        
        this.setState({show : "add", title: "Create"})
    
    }

    handleEditClick(id, event){
        const { pages,zones,provinces } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
        this.setState({editId: id})
        try {
            document.getElementById("stockId").value = result[0].stockId
            document.getElementById("stockName").value = result[0].name
            document.getElementById("stockIcon").src = `${config.BASE_URL}/v1/${result[0].image}`
            document.getElementById("status").value = result[0].enabled == true ? "Active":"Inactive"
            this.setState({show : "add", title: "Update"})
        } catch (error) {
            
        }
    }

    handleViewClick(id, event){
        const { pages } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
        var data = result[0]
        this.setState({ previewItem: data, show : 'view'})
    }

    handleZoneChange(val){
        this.setState({zoneId : val.value ,zone : val.label})
    }

    handleProvinceChange(val){
        this.setState({provinceId : val.value , province : val.label})
    }
    
    handleReset(){
        this.setState({ sortBy : 'createdAt'})
        const {pageIndex} = this.state;
        this.handleFetch(pageIndex)
    }

    handleSaveClick(value, event){
        const {token} = this.state;
        let stockId = document.getElementById("stockId").value
        let stockName = document.getElementById("stockName").value
        let enabled = document.getElementById("status").value
        console.log(enabled, "<----- enabled")
        let browserFiles = document.querySelector('input[name="file"]')
        
        var formData = new FormData()
        if (stockId != -1) {
            formData.append('stockId', stockId)
        }
        
        formData.append('name',stockName)
        formData.append('enabled', enabled == "Inactive" ? 'false':'true')

        for (const file of browserFiles.files) {
            formData.append('file',file,file.name)
        }
        
        const url = `${config.BASE_URL}/v1/stock/create`; 
        
        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`
            },
            body: formData
        }).then((response) => {
            return response.json();
        }).then((result) => {
            //.log(result)
            if(result.status){
                //this.modalAction("modal-add-series","close")
                this.handleFetch(1)
            }
            else{
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleSave(url,token,user){
        const {editId,pageIndex, pages} = this.state

        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(user)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                setTimeout(() => {
                    const {pageIndex} = this.state;
                    this.handleFetch(pageIndex)
                },500);
            } else {
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleDeleteClick(id, event){
        const {token} = this.state;
        confirmAlert({
            title: 'Confirm',
            message: "Do you want to delete this supply?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    const url = `${config.BASE_URL}/v1/stock/remove`;
                    this.setState({ loading: true })
            
                     fetch(url, {
                        method: 'post',
                         headers: {
                            'authorization': `basic ${token}`,
                             'Content-Type': 'application/x-www-form-urlencoded',
                         },
                         body: `id=${id}`
                     }).then(function(response) {
                         return response.json();
                        }).then((result) => {
                            if(result.status){
                                setTimeout(() => {
                                    const {pageIndex} = this.state;
                                    this.handleFetch(pageIndex)
                                  },500);
                               
                            }
                            else{
                                this.handleError(result.message)
                            }
                               
                        }).catch(error => {
                            this.handleError(error)
                        });
                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                }
              }
            ]
        })
    }

    onSetResult = (value, result) => {
        
        if (result["status"] == true){
            window.location.reload();
        }else{
            alert('Your request is not complete seriesname or email is already.'+JSON.stringify(result));
        }

        const { loading } = this.state;
        this.setState({ loading: !loading })
    }
    
    handleShowClick(show){
        this.setState({show : show, title: ""});
    }

    modalAction(id,status){
        
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }

    onSetFileResult = (value, result) => {
        this.setState({ upload: false })
        if (result["status"] == true){

            //alert("Successfully");
            this.modalAction("modal-import","close")
            const {pageIndex} = this.state;
            this.handleFetch(pageIndex)
        }else{
            this.handleError(result.message)
        }

    }

   
    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    render(){
        const { pages, pageIndex, loading, previewItem, searchKey, upload, zones, provinces, permission, show, title, zoneId, provinceId, zone, csvData, export_file } = this.state;
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;
        
        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={pageIndex == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            var contents = null
            if(pages.docs.length > 0){
                contents = []
                for(var index in pages.docs){
                    let item = pages.docs[index]
                    
                    var url = `${config.BASE_URL}/v1/${item["image"]}`
                    
                    contents.push(
                       
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{parseInt(pages.pageIndex) + parseInt(index)}</td> 
                        <td align="center">{item["name"]}</td>
                        <td align="center">
                            <div className={"car-color"}>
                                <img src={url} style={{width : '25px'}}/>
                            </div>
                        </td>
                        <td align="center">{item["enabled"] == true ? "Activated":"Inactivated"}</td>
                        <td align="center"><a href="#" onClick={this.handleViewClick.bind(this,item["_id"])}>View</a></td>
                        <td align="center"className={(permission.edit) ? "" : "hidden"}>
                            <button type="button" onClick={this.handleEditClick.bind(this, item["_id"])} className="btn btn-block btn-warning">Edit</button>
                        </td> 

                        {/* <td align="center" className={(permission.delete) ? "" : "hidden"}>
                            <button type="button" disable={loading.toString()} onClick={this.handleDeleteClick.bind(this, item["_id"])} className="btn btn-block btn-danger">Delete</button>
                        </td> */}
                    </tr>
                    )
                    
                }
            }
        }
        
        var zone_options = zones.map(result => {
            return { value: result.zoneId, label: result.description }
        })
        
        var zoneValue = zone_options.filter(option => parseInt(option.value) == parseInt(zoneId) || option.label == zone)
        var province_options = []
        for(var i in provinces){
            province_options.push( { value: provinces[i].provinceId, label: provinces[i].name } )     
        }

        var previewContent = previewItem ? (
            <div className="modal-body col-md-12" >
                                            
                <div className="row modal-box">
                    <div className="col-md-10">
                        <span className="view-text-head">Name Master Stock :</span> <span className="view-text">{ previewItem["name"] || "-" }</span>
                    </div>
                    
                </div>

                <div className="row modal-box">
                    <div className="col-md-10" >
                        <span className="view-text-head">Icon Master Stock : </span><span className="view-text">{ "-"}</span>
                    </div>
                </div>

                <div className="row modal-box">
                    <div className="col-md-10" >
                        <span className="view-text-head">Status : </span><span className="view-text">{previewItem["enabled"] == true ? "Activated":"Inactivated"}</span>
                    </div>
                </div>
            </div>
        ) : null

        return (
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                {
                                    title == "" || title == undefined ?(
                                        <h3 style={{float : "left"}}>Master Stock</h3>
                                    ): (
                                        <h3 style={{float : "left"}}>{title} Master Stock</h3>
                                    )
                                }
                                
                            </div>
                          
                            <div >
                                <div className={show == "view" ? "" : "hidden"}  id="modal-view" tabIndex="-1" role="dialog">
                                        <div>
                                            <div className="modal-content">
                                                {previewContent}
                                                <div className="modal-footer" style={{textAlign : 'center'}}>
                                                    <button type="button" className="btn btn-outline-primary btn-submit" data-dismiss="modal" onClick={this.handleShowClick.bind(this,"table")} style={{width : '150px',marginBottom : '20px'}}>Close</button>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                
                                <div  className={show == "table" ? "box" : "hidden"}>
                                    <div className="box-header" >
                                        <div className={(permission.create) ? "" : "hidden"}>
                                            <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleCreateClick.bind(this)}>
                                                <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px"}}>
                                                    <i className="fa fa fa-plus"></i>
                                                    <span>&nbsp;Create</span>
                                                    <span className="pull-right-container"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="box-header">
                                        <div className="pull-left">
                                            <div className="input-group mb-3">
                                                <input type="text" id="search-key" style={{height : "34px"}}/>
                                                <button className="btn btn-outline-secondary btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                                <button className="btn btn-outline-secondary btn-reset" onClick={this.handleReset.bind(this,1)} type="button">Reset</button>
                                            </div>
                                        </div>

                                        <div className="pull-right">
                                            <select  id="sort-key"  style={{width : "200px",  height : "30px",marginLeft : "5px"}}>
                                                <option>-- Sort By --</option>
                                                <option>Name</option>
                                                <option>Status</option>
                                            </select>
                                            <button className="btn btn-outline-primary btn-submit" onClick={this.handleSortClick.bind(this,1)} type="button">Submit</button>
                                        </div>
                                    </div>

                                    <div className="box-header">
                                    <span className={searchKey ? "" : "hidden"}>Seach "{searchKey}" ,please use reset button to refresh query.</span>
                                    <p>
                                    <span>Found <b>{pages.totalDocs}</b> record(s).</span>
                                    </p>
                                    </div>

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    {/* Table Contents  */}
                                    <div className={ loading ? "hidden" : "box-body"} style={{overflowX : 'auto'}}>
                                        <div className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <style>{'th {text-align: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                No.
                                                                </th>
                                                                
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Name Master Stock
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Icon Master Stock
                                                                </th>
                                                                
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Status
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                View
                                                                </th>
                                                                
                                                                <th  className={(permission.edit) ? "sorting" : "hidden"} tabIndex="3"  rowSpan="1" colSpan="1"  align="center" style={{width : '80px'}}>
                                                                    <span className='fa fa-edit'/> Edit
                                                                </th>
                                                                
                                                                {/* <th   className={(permission.delete) ? "sorting" : "hidden"} tabIndex="4"  rowSpan="1" colSpan="1" style={{width : '80px'}}>
                                                                    <span className='fa fa-bitbucket'/> Delete
                                                                </th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { contents }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                        
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage - 1)} className={currentPage > 10 ? "pageButton" : "hidden"}>
                                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage + 1)} className={currentPage < totalPages && pageList.length == 10 && totalPages > 10 ? "pageButton" : "hidden"}>
                                                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Editing View  */}
                                <div  className={show== "add" ? "box" : "hidden"}>
                                    <div className= "box-header" >
                                    
                                    <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleShowClick.bind(this,"table")}>
                                        <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px",backgroundColor : "black"}}>
                                        <i className="fa fa fa-remove"></i>
                                                <span style={{marginLeft : '5px'}}>Close</span>
                                                <span className="pull-right-container"></span>
                                        </a>
                                    </div>
                                    
                                    </div>

                                    <div className="modal-content">
                                        <div className="modal-body col-md-12" style={{height : "auto"}} >
                                            <div className="row modal-box">
                                                <div className="col-md-2 ">
                                                    <label>Name Master Stock<span className="require-label">*</span></label>
                                                </div>
                                                <div className="col-md-10">
                                                    <input type="text" className="form-control" id="stockName"/>
                                                    <input type="text" className="form-control" id="stockId" className={"hidden"}/>
                                                </div>
                                            </div>

                                            <div className="row modal-box">
                                                <div className="col-md-2">
                                                    
                                                    <div className={"hidden"}>
                                                        <img id="stockIcon" style={{width : '25px'}}/>
                                                    </div>
                                                    <label>Icon Master Stock</label>
                                                </div>
                                                
                                                <div className="col-md-10">
                                                    <input type="file" id="file" name="file" accept="image/*" style={{display: "true"}}/>
                                                    <div style={{color : 'red'}}>Icon size is 100x100 pixel (.jpg .jpeg .png .bmp)</div>
                                                </div>
                                            </div>
                                            
                                            <div className="row modal-box">
                                                <div className="col-md-2">
                                                    <label>Status</label>
                                                </div>
                                                <select  id="status"  style={{width : "200px",  height : "30px",marginLeft : "5px"}}>
                                                    <option>Active</option>
                                                    <option>Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="modal-footer" style={{textAlign : 'center'}}>
                                            <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : '150px',marginBottom : '20px',marginTop : '30px'}}>
                                                <i className="fa fa-save"></i>
                                                <span style={{marginLeft : '5px'}}>Save</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}