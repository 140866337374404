import React, {Component} from 'react';
import dateFormat from 'dateformat';
import '../css/style.css'
import loadgif from '../../../img/loading.gif'
import renderHTML from 'react-render-html';
import Select from 'react-select'

const config = require('../../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.email){
            window.location = config.LOGIN
        }

        

        this.state = {
            permission : userLogin.permission.email,
            userId : userLogin.userId,
            token : userLogin.token,
            loading:false,
            update: false,
            pages : null,
            emailType : 'usedcar',
            emailData : {},
            show : "table",
            template : "",
            docNo  : "",
            serie : "",
            model : "",
            bodyNumber : "",
            engineNumber: "",
            color : "",
            email_content : "",
            email : "",
            emails : [],
            now : new Date(),
        };

    }

    componentDidMount() {
        this.handleFetch(1)
        this.handleEmailFetch()
        this.state.template  = `<table width="1000" border="0">
        <tbody>
        <tr>
            <td width="500">
                <img src="${config.BASE_URL}/v1/images/locator/locator.png"/>
            </td>
            <td valign="top" align="left" width="150">
                <div>เอกสารอ้างอิงเลขที่</div>
            </td>
            <td valign="top" align="left" width="150">
                <div>{DOCNO}</div>
            </td>
        </tr>
        </tbody>
    </table>
    <table width="1000" border="0">
        <tbody>
        <tr>
            <td align="center">
                <h4><u>ใบแจ้งการใช้บริการขนส่งด้วยรถเทรลเลอร์</u></h4>
            </td>
        </tr>
        </tbody>
    </table>     
    <table width="1000" border="0"> 
        <tbody>   
        <tr>
            <td align="right">
                <table  border="1" width="200">  
                <tr>
                    <td  align="center" height="30">วันที่</td>
                    <td  align="center">เดือน</td>
                    <td  align="center">พ.ศ.</td>
                </tr>
                 <tr>
                    <td align="center" height="30">{DAY}</td>
                    <td align="center">{MONTH}</td>
                    <td align="center">{YEAR}</td>
                </tr>
                </table>
            </td>
         </tr>
        <tr>
            <td>
            <table width="1000" border="1"> 
                <tr>
                    <td height="50" width="250">ตัวแทนจำหน่ายผู้ขอใช้บริการ (Dealer)</td>
                    <td align="center">
                         {T1}
                    </td>
                    <td width="280">
                         ขอใช้บริการขนส่งบรรทุกรถยนต์จำนวน &nbsp;
                        {T2}
                        &nbsp;
                        คัน
                 </td>
                </tr>
            </table>
            </td>
        </tr>
        </tbody>   
    </table>
    <br/> 
    <table width="1000" border= "1">
         <tr>
            <th rowSpan="3" width="50">ลำดับ</th>
            <th rowSpan="3" width="100">รุ่นรถ</th>
            <th rowSpan="3">แบบรถ</th>
            <th rowSpan="3">หมายเลขตัวถัง</th>
            <th rowSpan="3" width="100">หมายเลขเครื่องยนต์</th>
            <th rowSpan="3" width="100">สีรถ</th>
            <th rowSpan="3" width="100">ทะเบียนรถ</th>
            <th colSpan="4">รายละเอียดการขนส่ง</th>
        </tr>
        <tr>
            <td colSpan="2" align="center">จาก</td>
            <td colSpan="2" align="center">ถึง</td>
        </tr>
         <tr>
            <td width="50" align="center">วันที่</td>
            <td width="50" align="center">สถานที่</td>
            <td width="50" align="center">วันที่</td>
            <td width="50" align="center">สถานที่</td>
        </tr>
        {TABLE_CONTENT}
    </table>
    <br/>
    <table width="800" border= "1">
        <tr>
            <td>
                <table width="800" border= "0">
                    <tr>
                        <td width="100" height="30">
                           <span>หมายเหตุ : </span> 
                        </td>
                        <td width="150">
                           <span>ชื่อผู้ติดต่อประสานงาน</span> 
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td height="30"></td>
                        <td>
                           <span>1. ชื่อผู้ขอใช้บริการ</span> 
                        </td>
                        <td>
                           {C1}
                        </td>
                    </tr>
                    <tr>
                        <td  height="30"></td>
                        <td >
                           <span>2. สังกัด / หน่วยงาน</span> 
                        </td>
                        <td>
                          {C2}
                        </td>
                    </tr>
                    <tr>
                        <td  height="30"></td>
                        <td>
                           <span>3. เบอร์ติดต่อ </span> 
                        </td>
                        <td>
                           {C3}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4" height="30"></td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`
    }

    handleCreateClick(){
        var now = new Date()
        document.getElementById("day").value = now.getDate()
        document.getElementById("month").value = (now.getMonth() + 1)
        document.getElementById("year").value = now.getFullYear()
        document.getElementById("t1").value = ""
        document.getElementById("t2").value = "1"
        document.getElementById("c1").value = ""
        document.getElementById("c2").value = ""
        document.getElementById("c3").value = ""

        for(var i = 1 ; i <= 5 ; i ++){
            for(var k = 0; k < 10 ; k++){
                document.getElementById("f"  + i + k.toString()).value = ""
            }
        }
        this.setState({show : 'create'})
    }

    handleChange(val){
        if(val == "serie"){
            this.setState({serie : document.getElementById("serie").value})
        }
        if(val == "model"){
            this.setState({model : document.getElementById("model").value})
        }
        if(val == "bodyNumber"){
            this.setState({bodyNumber : document.getElementById("body-number").value})
        }
        if(val == "engineNumber"){
            this.setState({engineNumber : document.getElementById("engine-number").value})
        }
        if(val == "color"){
            this.setState({color : document.getElementById("color").value})
        }
    }


    handleFetch(page){
        const {token ,emailType} = this.state;
        this.setState({loading:true})
      
        const url = `${config.BASE_URL}/v1/email?type=${emailType}&page=${page}`; 
       
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`
            },
        }).then((response) => {
            return response.json();
        }).then((json) => {
            
            setTimeout(() => {
                let result = json["data"]
                 
                if(result){
                   
                    this.setState({show : "table",loading:false ,pages : result})
                    
                }
                else{
                    this.setState({show : "table",loading:false})
                }

              },500);
      
        }).catch(error => {
            this.handleError(error)
        });
    }
    
    handleEmailFetch(){
        const {token} = this.state;

        this.setState({loading:true})
       
        const url = `${config.BASE_URL}/v1/contact?&page=1&sortBy=createAt&perpage=1000`; 
        //console.log("Fetch")
        console.log(url)
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            
            if(result.status){
                var docs = result.data.docs
              
                this.setState({emails : docs})
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });

    }

    handleEmailChange(val){
        this.setState({email : val.value})
    }

    handleSaveClick(value, event){
        const {token ,template,emailType} = this.state
        var day = document.getElementById("day").value
        var month = document.getElementById("month").value
        var year = document.getElementById("year").value
        var t1 = document.getElementById("t1").value
        var t2 = document.getElementById("t2").value
        var c1 = document.getElementById("c1").value
        var c2 = document.getElementById("c2").value
        var c3 = document.getElementById("c3").value

        var table_content = ""
        for(var i = 1 ; i <= 5 ; i ++){
            
            table_content += `<tr>
                    <td align="center" height="35">
                       <span>${i}</span>
                    </td>`
                
            for(var k = 0; k < 10 ; k++){
                var val = document.getElementById("f"  + i + k.toString()).value
                table_content +=`<td>${val}</td>`
                
            }
            table_content += "</tr>"
            
        }

        var content = template
                        .replace("{LOCATOR-IMG}",`${config.BASE_URL}/v1/images/locator/locator.png`)
                        .replace("{DAY}",day)
                        .replace('{MONTH}',month)
                        .replace('{YEAR}',year)
                        .replace('{T1}',t1)
                        .replace('{T2}',t2)
                        .replace("{TABLE_CONTENT}",table_content)
                        .replace('{C1}',c1)
                        .replace('{C2}',c2)
                        .replace('{C3}',c3)
                        
        console.log(`content =======> ${content}`)
        
        const url = `${config.BASE_URL}/v1/email/create`; 
       
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify({ type : emailType ,content : content})
        }).then((response) => {
            return response.json();
        }).then((result) => {
                
                 if(result.status){
                    var email_content = result.data.content.replace("{DATE}",dateFormat(result.data.createdAt, "dd/mm/yyyy") ).replace(".png/", ".png")
                    this.setState({show : "save",docNo : result.data.docNo , email_content : email_content})
                 }
                  
                this.setState({loading : false})
                     
        }).catch(error => {
           
            this.handleError(error)
        });
        
    }

    handleViewClick(id){
        const { pages} = this.state
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
        var email_content = result[0].content.replace("{DATE}",dateFormat(result[0].createdDate, "dd/mm/yyyy") ).replace(".png/", ".png").replace(".png/", ".png")
        console.log(email_content)
        /*
 <td width="500">
                <img src=http://147.50.134.10/v1/images/locator/locator.png/>
            </td>
        */
         this.setState({ show : 'view',email_content : email_content})
    }

    handleShowClick(val){
        this.setState({show : val})
    }

    handleSendClick(id){
        const { pages} = this.state
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
        var email_content = result[0].content.replace("{DATE}",dateFormat(result[0].createdDate, "dd/mm/yyyy") ).replace(".png/", ".png")
        this.setState({ show : 'save',email_content : email_content,docNo : result[0].docNo})
    }

    handleResetClick(){
        document.getElementById("search-key").value = ""
        this.handleFetch(1)
    }

    handleSearchClick(){
        const { emailType ,token} = this.state
        var key = document.getElementById("search-key").value;

        const url = `${config.BASE_URL}/v1/email/search`; 
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify({key : key,type : emailType})
        }).then((response) => {
            return response.json();
        }).then((json) => {
            let result = json["data"]
            if(result){
                this.setState({show : "table",loading:false ,pages : result})
            }
            else{
                this.setState({show : "table",loading:false})
            }
                     
        }).catch(error => {
           
            this.handleError(error)
        });
        
    }

    handlePageClick(page, event){   
        this.handleFetch(page)
    }

    handleSendMailClick(docNo){
        const { token ,email } = this.state
       
        const url = `${config.BASE_URL}/v1/email/send`;
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify({ docNo : docNo,mailTo : email})
        }).then((response) => {
            return response.json();
        }).then((result) => {
                 if(result.status){
                    this.setState({email : ""})
                    this.handleFetch(1)
                 }
                  
                this.setState({loading : false})
                     
        }).catch(error => {
           
            this.handleError(error)
        });
    }

    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }
    
    render(){
        const { loading ,pages ,email_content,permission,show ,email,emails,update ,docNo} = this.state;
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(pages.docs.length > 0 &&  currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            var contents = null
            if(pages.docs.length > 0){
                contents = []
                for(var index in pages.docs){
                    let item = pages.docs[index]
                    
                    contents.push(
                       
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{ parseInt(pages.pagingCounter) + parseInt(index)}</td>
                        <td align="center">{item["docNo"]}</td>
                        <td align="center">{item["mailTo"]}</td>
                        <td align="center">{dateFormat(item["createDate"], "GMT:dd/mm/yyyy") }</td>
                        <td align="center">
                            <span className={ item["status"] != "save" ? "" : "hidden"}>
                            <a href={item["status"] == "save" ? "#":`${config.BASE_URL}/v1/xls/${item["docNo"]}`} ><i className="fa fa-download" aria-hidden="true" style={{color: item["status"] == "save" ? "#808080" : "#000000"}}></i></a>
                            </span>
                        </td>
                        <td align="center">
                            <span className={ item["status"] == "save" ? "" : "hidden"}>
                               
                                <button type="button" className="btn btn-success" onClick={this.handleSendClick.bind(this, item["_id"])}>
                                                 
                                     Wait For Send
                                </button>
                            </span>
                            <span className={ item["status"] != "save" ? "" : "hidden"}>
                                Completed
                              
                           </span>
                        </td>
                       

                    </tr>
                    )
                    
                }
            }
    
        }

        var table_contents = []
        var t_size = ["2","15","15","15","15","10","10","5","5","5","5"]
        for(var i = 1 ; i <= 5 ; i ++){
            var row_content = []
                row_content.push(
                    <td align="center" height="35" key={Math.random().toString(36).substring(7)}>
                       <span>{i}</span>
                    </td>
                )
            for(var k = 0; k < 10 ; k++){
                
                row_content.push(
                    <td key={Math.random().toString(36).substring(7)}>
                        <input id={"f" + i + k.toString()} type="text" size={t_size[k]}/>
                    </td>
                )
            }

            table_contents.push(
                <tr key={Math.random().toString(36).substring(7)}>
                  {row_content}
                </tr>
            )
        }

        var email_options = []
     
        for(var i in emails){
            email_options.push( { value: emails[i].email, label: emails[i].email} )     
        }

        return (
            
            <div className="content-wrapper">
               
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>Email Trailer (Used car)</h3>
                            </div>
                          
                            <div >
                                <div className="box">
                                
                                  <div className="box-header">
                                    <div className={(permission.create && show == "table") ? "" : "hidden"}>
                                        <div className='pull-right' style={{cursor : "pointer"}} onClick={this.handleCreateClick.bind(this)}>
                                            <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px"}}>
                                                <i className="fa fa fa-plus"></i>
                                                <span>Create Email</span>
                                                <span className="pull-right-container"></span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className={show == "create" || show == "view" || show == "save" ? "" : "hidden"}>
                                        <div className='pull-left' style={{cursor : "pointer"}}  onClick={this.handleShowClick.bind(this,"table")}>
                                            <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px",backgroundColor : "black"}}>
                                                <i className="fa fa fa-remove"></i>
                                                    <span style={{marginLeft : '5px'}}>Close</span>
                                                <span className="pull-right-container"></span>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="pull-left">
                                        <div className={ show == "table" ? "input-group mb-3" : "hidden"}>
                                            <input type="text" id="search-key" style={{height : "34px",marginLeft:'10px'}}/>
                                            <button className="btn btn-outline-secondary btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                            <button className="btn btn-outline-secondary btn-reset" onClick={this.handleResetClick.bind(this)} type="button">Reset</button>
                                        </div>
                                    </div>
                                  </div>
                                  
                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ show == 'table' ? "box-body" : "hidden"}>
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>
                                            <div>
                                                <div className="col-sm-12">
                                                    <style>{'th {text-align: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                No.
                                                                </th>
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Doc No
                                                                </th>
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Email To
                                                                </th>
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Create
                                                                </th>
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Download
                                                                </th>
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Action
                                                                </th>

                                                            

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           { contents }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                            
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage - 1)} className={currentPage > 10 ? "pageButton" : "hidden"}>
                                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage + 1)} className={currentPage < totalPages && pageList.length == 10 && totalPages > 10 ? "pageButton" : "hidden"}>
                                                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                          
                                        </div>
                                    </div>

                                    <div className={show == "create" ? "box-body" : "hidden"} >
                                        <div  className="dataTables_wrapper form-inline dt-bootstrap">
                                           <div className="col-md-12">
  
                                               <div className="row modal-box">
                                                    <div className="col-md-12 ">
                                                    <table width="1000" border="0">
                                                        <tbody>
                                                        <tr>
                                                            <td width="500">
                                                                <img src={`${config.BASE_URL}/v1/images/locator/locator.png`}/>
                                                            </td>
                                                            <td valign="top" align="left" width="150">
                                                                <div>เอกสารอ้างอิงเลขที่</div>
                                                             
                                                            </td>
                                                            <td valign="top" align="left" width="150">
                                                                <div></div>
                                                              
                                                                
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <table width="1000" border="0">
                                                        <tbody>
                                                        <tr>
                                                            <td align="center">
                                                                <h4><u>ใบแจ้งการใช้บริการขนส่งด้วยรถเทรลเลอร์</u></h4>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>     
                                                    <table width="1000" border="0"> 
                                                        <tbody>   
                                                        <tr>
                                                            <td align="right">
                                                                <table  border="1" width="200"> 
                                                                    <tbody>
                                                                        <tr>
                                                                            <td  align="center" height="30">วันที่</td>
                                                                            <td  align="center">เดือน</td>
                                                                            <td  align="center">พ.ศ.</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="center" height="30">
                                                                                <input id="day" type="text"  size="3"/>
                                                                            </td>
                                                                            <td align="center">
                                                                                <input id="month" type="text" size="3"/>
                                                                            </td>
                                                                            <td align="center">
                                                                                <input id="year" type="text"  size="3"/>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                         </tr>
                                                        <tr>
                                                            <td>
                                                            <table width="1000" border="1"> 
                                                            <tbody>
                                                                <tr>
                                                                    <td height="50" width="250">ตัวแทนจำหน่ายผู้ขอใช้บริการ (Dealer)</td>
                                                                    <td align="center">
                                                                         <input id="t1" type="text"  size="60" />
                                                                    </td>
                                                                    <td width="280">
                                                                         ขอใช้บริการขนส่งบรรทุกรถยนต์จำนวน &nbsp;
                                                                        <select id="t2">
                                                                            <option>1</option>
                                                                            <option>2</option>
                                                                            <option>3</option>
                                                                            <option>4</option>
                                                                            <option>5</option>
                                                                        </select>
                                                                        &nbsp;
                                                                        คัน
                                                                 </td>
                                                           
                                                            
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            </td>
                                                        </tr>
                                                        </tbody>   
                                                    </table>
                                                    <br/>
                                                    
                                                    <table width="1000" border= "1">
                                                        <tbody>
                                                         <tr>
                                                            <th rowSpan="3" width="50">ลำดับ</th>
                                                            <th rowSpan="3" width="100">รุ่นรถ</th>
                                                            <th rowSpan="3">แบบรถ</th>
                                                            <th rowSpan="3">หมายเลขตัวถัง</th>
                                                            <th rowSpan="3" width="100">หมายเลขเครื่องยนต์</th>
                                                            <th rowSpan="3" width="100">สีรถ</th>
                                                            <th rowSpan="3" width="100">ทะเบียนรถ</th>
                                                            <th colSpan="4">รายละเอียดการขนส่ง</th>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2" align="center">จาก</td>
                                                            <td colSpan="2" align="center">ถึง</td>
                                                        </tr>
                                                         <tr>
                                                            <td width="50" align="center">วันที่</td>
                                                            <td width="50" align="center">สถานที่</td>
                                                            <td width="50" align="center">วันที่</td>
                                                            <td width="50" align="center">สถานที่</td>
                                                        </tr>
                                                        { table_contents }
                                                        </tbody>
                                                    </table>
                                                    <br/>
                                                    <table width="800" border= "1">
                                                        <tbody>
                                                        <tr>
                                                            <td>
                                                                <table width="800" border= "0">
                                                                    <tbody>
                                                                    <tr>
                                                                        <td width="100" height="30">
                                                                           <span>หมายเหตุ : </span> 
                                                                        </td>
                                                                        <td width="150">
                                                                           <span>ชื่อผู้ติดต่อประสานงาน</span> 
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td height="30"></td>
                                                                        <td>
                                                                           <span>1. ชื่อผู้ขอใช้บริการ</span> 
                                                                        </td>
                                                                        <td>
                                                                            <input id="c1" type="text" size="60"/>&nbsp;
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  height="30"></td>
                                                                        <td >
                                                                           <span>2. สังกัด / หน่วยงาน</span> 
                                                                        </td>
                                                                        <td>
                                                                            <input id="c2" type="text" size="60"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  height="30"></td>
                                                                        <td>
                                                                           <span>3. เบอร์ติดต่อ </span> 
                                                                        </td>
                                                                        <td>
                                                                            <input id="c3" type="text" size="60"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan="4" height="30"></td>
                                                                        
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                                
                                                </div>
        
                                                <div className="row modal-box">
                                                   <div className="col-md-12" style={{textAlign : "center"}}>
                                                        <div className={(permission.create) ? "" : "hidden"}>
                                                        <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : "300px",height : "46px"}}>
                                                             <i className={update ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>
                                                                Save
                                                        </button>
                                                        </div>
                                                    </div> 
                                                </div>
                                                    
                                            </div>
                                            
                                            

                                        </div>
                                    </div>
                                    <div  className={show == "view" ? "box-body" : "hidden"}>
                                        <div className="col-md-12">
                                        {renderHTML(email_content)}
                                        </div>
                                    </div>

                                    <div  className={show == "save" ? "box-body" : "hidden"} >
                                        <div className="col-md-12" style={{marginBottom: "15px"}}>
                                           
                                        {renderHTML(email_content)}
                                        </div>
                                        <div className="row"  style={{marginBottom: "15px"}}>
                                            <div  style={{textAlign : "center",width : "800px"}}>
                                                <span style={{color:'red'}}>*</span>
                                                <span style={{fontWeight: "bold"}}>Send Email To : </span>
                                               
                                                
                                            </div>
                                           
                                        </div>
                                        <div className="row" >
                                            <div className="col-md-12" style={{textAlign : "center"}}>
                                                <div  style={{textAlign : "left",width : "80%"}}>
                                                <Select 
                                                    value={email_options.filter(option => option.label == email)}
                                                    options={email_options}
                                                    onChange= {value => this.handleEmailChange(value)}
                                                />
                                                </div>    
                                             </div> 
                                            
                                             
                                        </div>
                                       
                                        <div className="row" style={{marginTop : "20px"}}>
                                            
                                            <div className="col-md-12" style={{textAlign : "center"}}>
                                                <div  style={{textAlign : "center",width : "800px"}}>
                                                    <button type="button" className="btn btn-success" onClick={this.handleSendMailClick.bind(this,docNo)} style={{width : "300px",height : "46px"}}>
                                                        <i className="fa fa-envelope-open-o" aria-hidden="true" style={{marginRight : "5px"}}></i>
                                                            Send
                                                        <i className="fa fa-arrow-circle-o-right" aria-hidden="true" style={{marginLeft : "5px"}}></i>
                                                    </button>
                                                </div>    
                                             </div> 
                                             
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}