import React, {Component} from 'react';
import { Chart } from 'react-google-charts';
import './css/style.css'
const config = require('../share-components/Config');


export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        var permission = userLogin.permission

        if(!userLogin){
            window.location = config.LOGIN
        }
        
        this.state = {
            token : userLogin.token,
            permission:permission,
            transactions: {
                deadstock : 0,
                pool : 0
            },
            posts: {
                wts : 0,
                pool : 0,
                wtb : 0,
                deadstock : 0
            }
            
        };

        
    }

    componentDidMount() {
        const {transactions,posts ,token} = this.state

        const url = `${config.BASE_URL}/v1/dashboard`; 
        fetch(url,{
            headers: {
                'authorization': `basic ${token}`
            },
        }).then((response) => {
            return response.json();
        }).then((result) => {
           console.log(result)
           if(result.status){
                for (var key in result.data.transactions) {
                    transactions[key] = result.data.transactions[key]
                }
                for (var key in result.data.posts) {
                    posts[key] = result.data.posts[key]
                }
                 this.setState({ransactions : transactions ,posts : posts});
            }
            else{
                this.handleError(result.message)
            }
            
        }).catch(error => {
            this.handleError(error)
        });
        
    }

    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    render(){
        const {transactions, posts, permission} = this.state

        const pieOptions = {
            title: "",
            pieHole: 0.6,
            slices: [
              {
                color: "#f39c12"
              },
              {
                color: "#dd4b39"
              }
            ],
            legend: {
              position: "bottom",
              alignment: "center",
              textStyle: {
                color: "233238",
                fontSize: 14
              }
            },
            tooltip: {
              showColorCode: true
            },
            chartArea: {
              left: 0,
              top: 0,
              width: "100%",
              height: "80%"
            },
            fontName: "Roboto"
          };         
          const pieOptions2 = Object.assign({},pieOptions)
          const pieOptions3 =  Object.assign({},pieOptions)
          pieOptions2.slices = [ {   color: "#f39c12"} , { color: "#00a65a"} ]
          pieOptions3.slices = [ {   color: "#605ca8"} , { color: "#dd4b39"} ]
        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <h1>
                        Dashboard
                        {/* <small>Preview page</small> */}
                    </h1>
                 
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="small-box bg-aqua">
                                <div className="inner">
                                    <h3>{ transactions.pool + transactions.deadstock}</h3>

                                    <p className="dashboard-topic">All Transaction</p>
                                </div>
                                <div className="icon">
                                    <i className="fa fa-car"></i>
                                </div>
                                <a href="#" className="small-box-footer">
                                    &nbsp;
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="small-box bg-blue">
                                <div className="inner">
                                    <h3>{transactions.pool}</h3>

                                    <p className="dashboard-topic">Pool Transaction</p>
                                </div>
                                <div className="icon">
                                    <i className="fa fa-car"></i>
                                </div>
                                {
                                    permission.poolData && permission.poolData.view == true ? 
                                    (
                                        <a href={config.POOL_TRANSACTION} className="small-box-footer">
                                            More info <i className="fa fa-arrow-circle-right"></i>
                                        </a>
                                    ):(
                                        <a href="#" className="small-box-footer">More info</a>
                                    )
                                }
                                </div>
          
                        </div>

                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="small-box bg-teal">
                                <div className="inner">
                                    <h3>{transactions.locator}</h3>

                                    <p className="dashboard-topic">Locator Transaction</p>
                                </div>
                                <div className="icon">
                                    <i className="fa fa-car"></i>
                                </div>
                                {
                                    permission.locatorTransaction && permission.locatorTransaction.view == true ? (
                                        <a href={config.LOCATOR_TRANSACTION} className="small-box-footer">
                                            More info <i className="fa fa-arrow-circle-right"></i>
                                        </a>
                                    ):(
                                        <a href="#" className="small-box-footer">More info</a>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="small-box bg-yellow">
                                <div className="inner">
                                    <h3>{posts.pool}</h3>

                                    <p className="dashboard-topic">Pool</p>
                                </div>
                                <div className="icon">
                                    <i className="fa fa-car"></i>
                                </div>
                                {
                                    permission.poolData && permission.poolData.view == true ?
                                    (
                                        <a href={config.POOL} className="small-box-footer">
                                            More info <i className="fa fa-arrow-circle-right"></i>
                                        </a>
                                    ):(<a href="#" className="small-box-footer">More info</a>)
                                }
                            </div>
          
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="small-box bg-green">
                                <div className="inner">
                                    <h3>{posts.deadstock}</h3>

                                    <p className="dashboard-topic">Deadstock</p>
                                </div>
                                <div className="icon">
                                    <i className="fa fa-car"></i>
                                </div>
                                {
                                    permission.poolData && permission.poolData.view == true ?
                                    (
                                        <a href={config.DEADSTOCK} className="small-box-footer">
                                            More info <i className="fa fa-arrow-circle-right"></i>
                                        </a>
                                    ):(<a href="#" className="small-box-footer">More info</a>)
                                }
                                
                            </div>
          
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="small-box bg-purple">
                                <div className="inner">
                                    <h3>{posts.wts}</h3>

                                    <p className="dashboard-topic">WTS</p>
                                </div>
                                <div className="icon">
                                    <i className="fa fa-car"></i>
                                </div>
                                {
                                    permission.locatorData && permission.locatorData.view == true ?
                                    (
                                    <a href={config.WTS} className="small-box-footer">
                                        More info <i className="fa fa-arrow-circle-right"></i>
                                    </a>
                                    ):(<a href="#" className="small-box-footer">More info</a>)
                                }
                            </div>
          
                        </div>

                        <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="small-box bg-red">
                                <div className="inner">
                                    <h3>{posts.wtb}</h3>

                                    <p className="dashboard-topic">WTB</p>
                                </div>
                                <div className="icon">
                                    <i className="fa fa-car"></i>
                                </div>
                                {
                                    permission.locatorData && permission.locatorData.view == true ?
                                    (
                                        <a href={config.WTB} className="small-box-footer">
                                            More info <i className="fa fa-arrow-circle-right"></i>
                                        </a>
                                    ):(<a href="#" className="small-box-footer">More info</a>)
                                }
                            </div>
          
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="App">
                                <Chart
                                chartType="PieChart"
                                data={[["Age", "Weight"], ["Pool", posts.pool], ["Locator",(posts.wtb + posts.wts)]]}
                                options={pieOptions}
                                graph_id="PieChart"
                                 width={"100%"}
                                height={"400px"}
                                legend_toggle
                            />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="App">
                                <Chart
                                chartType="PieChart"
                                data={[["Age", "Weight"], ["Pool", posts.pool], ["Deadstock", posts.deadstock]]}
                                options={pieOptions2}
                                graph_id="PieChart2"
                                 width={"100%"}
                                height={"400px"}
                                legend_toggle
                            />
                            </div>
          
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="App">
                                <Chart
                                chartType="PieChart"
                                data={[["Age", "Weight"], ["WTS", posts.wts], ["WTB", posts.wtb]]}
                                options={pieOptions3}
                                graph_id="PieChart3"
                                 width={"100%"}
                                height={"400px"}
                                legend_toggle
                            />
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        )
    }
}