import React, {Component} from 'react';
import {Parser} from 'json2csv'
import { CSVLink } from "react-csv";
import dateFormat from 'dateformat';
import DatePicker from "react-datepicker";
import './css/style.css'
import styled from 'styled-components'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'


import loadgif from '../../img/loading.gif'
import importPng from '../../img/import_csv.png'
import exportPng from '../../img/export_csv.png'
import excelImage from '../../img/excel.png'

var config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.locatorTransaction){
            window.location = config.LOGIN
        }

        let imagePositions = {
            "FC": "มุมหน้า",
            "BC": "มุมหลัง",
            "F": "ด้านหน้า",
            "B": "ด้านหลัง",
            "E": "เครื่องยนต์",
            "TL": "กระโปรงท้าย",
            "FR": "หน้าขวา",
            "FL": "หน้าซ้าย",
            "BS": "เบาะหลัง",
            "C": "คอนโซล",
            "CLF": "คอนโซล ซ้าย/ขวา",
            "G": "เกียร์",
            "FI": "ฟอร์มตรวจรถ",
        }
        
        this.state = {
            imagePositions: imagePositions,
            permission : userLogin.permission.locatorTransaction,
            cancelPermission : userLogin.permission.cancelTransaction,
            token : userLogin.token,
            loading:false,
            upload : false,
            setPassword : false,
            roleId : "1",
            searchKey : "",
            sortBy : "transactionId",
            sortType : -1,
            userId : -1,
            type : "",
            csvData : [],
            export_file : "",
            startDate : "",
            stopDate : "",
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 50,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            },
            showItems:{},
            cancelId: null,
            preview : false
        };

    }
  
    componentDidMount() {
        this.handleFetch(1)
    }

    onStartDateChange(date){
        this.setState({startDate : date});
    }

    onStopDateChange(date){
        this.setState({stopDate : date});
    }
    
    handleFetch(page){
        const {pages, token,searchKey ,startDate ,stopDate} = this.state;

        if(page < 1 || page > pages.totalPages){
            return
        }
        this.setState({loading:true})
        const url = `${config.BASE_URL}/v1/product/search/locator`; 
        var params ={
            "key" : searchKey ,
            "page" : page ,
            "perPage" : 100,
            "startDate" : startDate,
            "stopDate" : stopDate,
        }

        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            console.log(result)
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
            
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleSearchClick(){
        const {startDate,stopDate} = this.state

        var key = document.getElementById("search-key").value;
        
        if(startDate && !stopDate){
            alert("Stop date is require.")
            return;
        }
        if(!startDate && stopDate){
            alert("Start date is require.")
            return;
        }

               
        this.setState({searchKey : key })
        setTimeout(() => {
            this.handleFetch(1)
        },500);
    }

    handleResetClick(){
        document.getElementById("search-key").value = ""
        this.setState({searchKey : "" ,startDate : null, stopDate : null})
        setTimeout(() => {
            this.handleFetch(1)
        },500);
    }

   
    handlePageClick(page, event){  
        this.handleFetch(page)
    }

    handleModalExport(){
        this.modalCloseAction("modal-export","open")
    }
    
    handleExport(){
        const {pages, token,searchKey ,startDate ,stopDate} = this.state;
        
        var sortKey = document.getElementById('export-key').value
        var keyword = "ALL"
        if (sortKey == "SALED") {
            keyword = "saled"
        }else if (sortKey == "CANCEL") {
            keyword = "cancel"
        }else if (sortKey == "REJECT") {
            keyword = "reject"
        }else if (sortKey == "COMPLETE") {
            keyword = "accept"
        }
        const url = `${config.BASE_URL}/v1/product/search/locator`; 
        console.log(url)
        var params ={
            "key" : searchKey ,
            "page" : 1 ,
            "perPage" : pages.totalDocs,
            "startDate" : startDate,
            "stopDate" : stopDate
        }

        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then(async (result) => {
            
            if(result.status){
                var index = 1
                let items = keyword == "ALL" ? result.data.docs : result.data.docs.filter(object => {
                    return object["statusInfo"]["status"] == keyword
                })

                if (items.length == 0) {
                    
                    this.modalCloseAction("modal-export","close")
                    setTimeout(() => {
                        alert("ไม่มีข้อมูล")
                     },500);
                    return
                }
                let data = await Promise.all(items.map((val, index) =>{
                    var create_date = (val.pidDate.replace("T", " ").split("."))[0]
                    let color = (val["colorInfo"] && val["colorInfo"]["color"] !== undefined) ? val["colorInfo"]["color"] : ""
                    let user = val["user"]["firstname"] + " " + val["user"]["lastname"]
                    
                    let buyerCode = val["buyer_dealerInfo"] != undefined ? val["buyer_dealerInfo"]["dealerCode"] || "-" : "-"
                    let buyer = val["buyer"]["firstname"] + " " + val["buyer"]["lastname"]

                    let sellerCode = val["seller_dealerInfo"] != undefined ? val["seller_dealerInfo"]["dealerCode"] || "-" : "-"
                    let seller = val["seller"]["firstname"] + " " + val["seller"]["lastname"]

                    let offer = val["offer"]["firstname"] + " " + val["offer"]["lastname"]
                    let vinNo = val["bodyNumber"] || '-'
                    let engineNo = val["engineNumber"] || '-'
                    let dealer = val["dealerInfo"] || {}
                    
                    let stockName = val["stockInfo"] != undefined ? val["stockInfo"]["name"]:'-'
                    let paymentName = val["customerPayment"] != undefined ? val["customerPayment"]["name"]:val["paymentInfo"] != undefined ? val["paymentInfo"]["name"]:'-'
                    
                    let dateDistance = parseFloat(val["dateDistance"] || "0")
                    var dateRange = this.rangeOfMonth(dateDistance)

                    var obj = {
                        'No':index+1,
                        'Vin No': `="${vinNo}"`,
                        'Engine No': `="${engineNo}"`,
                        'Series': val.series,
                        'Model': val.model,
                        'SFX': `="${val.sfx}"`,
                        'Color Code':`="${val.colorCode}"`,
                        'Color Name': color,
                        'Price':  config.formatter(parseInt(val["price"]), 0) || 0,
                        'Dlr Buyer Code': `="${buyerCode || "-"}"`,
                        'Buyer': buyer.trim().length == 0 ? "-":buyer,
                        'Dlr Seller Code':`="${val["user"]["dealerCode"] || dealer["dealerCode"] || "-"}"`,
                        'Seller': user,
                        'Invoice Date': `${val["pid_date"] || "-"} ( ${dateRange} )`,
                        "Status": `${val["statusInfo"]["status"]}`.toUpperCase(),
                        'Create Date': dateFormat(val["createdAt"], "GMT:dd-mmm-yyyy"),
                        'Buy Date': dateFormat(val["buyDate"], "GMT:dd-mmm-yyyy"),
                        'Stock Status': stockName || '-',
                        'Payment Chanel': paymentName || '-',
                        'Accept Date': val.transportInfo && val.transportInfo.status.complete != undefined ? dateFormat(val.transportInfo.status.complete, "GMT:dd-mmm-yyyy") || '-' : '-',
                        'Cancel Remark': val.admin_cancel_remark || "-",
                        'Reject Remark': val.statusInfo != undefined && val.statusInfo.status == "reject" ? val.remark:'-',
                    }
                    //transportInfo
                    return obj
                    
                 }))
                 
                const fields = [
                    'No',
                    'Vin No',
                    'Engine No',
                    'Series',
                    'Model',
                    'SFX',
                    'Color Code',
                    'Color Name',
                    'Price',
                    'Dlr Buyer Code',
                    'Buyer',
                    'Dlr Seller Code',
                    'Seller',
                    'Invoice Date',
                    'Status',
                    'Create Date',
                    'Buy Date',
                    'Stock Status',
                    'Payment Chanel',
                    'Accept Date',
                    'Cancel Remark',
                    'Reject Remark'
                ]
                const opts = { fields };
                
                try {
                    
                    const parser = new Parser(opts);
                    const csv = parser.parse(data);
                    
                    var d = new Date();
                    var dd = (d.getDate() >= 10 ) ? d.getDate() : '0' + d.getDate()
                    var mm = ((d.getMonth() +1) >= 10) ? d.getMonth() +1 : '0' + d.getMonth() +1
                    var yyyy = d.getFullYear()
                    var hh = d.getHours()
                    var ii = d.getMinutes()
                    var ss = d.getSeconds()
                    var filename = `export_${dd}${mm}${yyyy}_${hh}${ii}${ss}.csv`
                    
                    var hiddenElement = document.createElement('a');
                    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI("\ufeff"+csv);
                    hiddenElement.target = '_blank';
                    hiddenElement.download = filename;
                    hiddenElement.click();
                    
                    this.modalCloseAction("modal-export","close")

                    setTimeout(() => {
                        alert("ท่านได้ทำการ Export ข้อมูลสำเร็จ")
                     },500);
                } catch (err) {
                    console.error(err);
                }
            }
            else{
                this.handleError(result.message)
            }
           
            
        }).catch(error => {
            this.handleError(error)
        });
    }

    
    handleSortClick(page, event){ 
        const {searchKey} = this.state;
        var sortKey = document.getElementById('sort-key').value
        var sortOptions={
            "Type" : "type",
            "Price" : "price",
            "Status" : "status",
            "Create"    : "create",
        }
        var sortBy = sortOptions[sortKey]
        this.setState({sortBy:sortBy,sortType : 1});
    
        setTimeout(() => {
            if(searchKey){
                this.handleSearch(page)
            }
            else{
                this.handleFetch(page)
            }
        },500);
    }


    modalAction(id,status){
        
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }


    handleError(error){
        console.log(error)
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    requestToCancel() {
        const { token, pages, cancelId } = this.state;
        var cancelRemark = document.getElementById('cancel-key').value
        if (cancelRemark == "กรุณาเลือก") return
        var params = {}
        params["admin_cancel_remark"] = cancelRemark
        params["postId"] = cancelId

        const url = `${config.BASE_URL}/v1/transaction/cancel`
        
        fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status == true){
                this.modalCloseAction("modal-cancel","close")
                this.handleFetch(pages.currentPage)
            } else{
                this.handleError(result.message)
            }
        }).catch(error => {
            this.handleError(error)
        })
    }

    cancelClick(value, event) {
        this.setState({cancelId: value})
        this.modalCloseAction("modal-cancel","open")
    }

    displayDetailClick(value, event) {
        const { token , seriesCode, pages } = this.state;
        var params = pages.docs[value] || {}
        
        const url = `${config.BASE_URL}/v1/product/information?id=${pages.docs[value]["_id"]}`; 

        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.statusCode == 200){
                let images = result["data"][0]["imageInfo"] || []
                var showItems = images.map(x => {
                    return {
                        original: `${config.ROOTURL}${x["imagePath"]}`,
                        thumbnail: `${config.ROOTURL}${x["imagePath"]}`,
                        positionCode: x["positionCode"]
                    }
                })
                
                params["customerPayment"] = result["data"][0]["customerPayment"] || {}
                params["imageInfo"] = showItems
                params["dealerInfo"] = result["data"][0]["dealerInfo"] || {}
                params["buyer_dealerInfo"] = result["data"][0]["buyer_dealerInfo"] || {}
                params["buyer"] = result["data"][0]["buyer"] || {}
                console.log(result)
                
                this.setState({showItems: params});
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });
    }

    modalCloseAction(id,status){
        
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }

    rangeOfMonth(distance = 0) {
        if (distance >= 0 && distance <= 3) {
            return "0-3 เดือน"
        }else if (distance > 3 && distance <= 6) {
            return "4-6 เดือน"
        }else if (distance > 6 && distance <= 9) {
            return "7-9 เดือน"
        }else if (distance > 9 && distance <= 12) {
            return "10-12 เดือน"
        }

        return ">12 เดือน"
    }

    render(){
        
        const { pages, imagePositions, preview, showItems, loading ,searchKey,upload,csvData ,export_file ,startDate,stopDate,permission, cancelPermission} = this.state;
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;

        const HoverText = styled.p`
            opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
            background: #eee;
            margin: 10px;
            padding: 10px;
            border-radius: 2px; 
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            :hover {
                cursor: pointer;
                opacity: 0.9;
            }
        `

        let dealerInfo = showItems["dealerInfo"] || {}
        let dateDistance = parseFloat(showItems["dateDistance"] || "0")
        var displayDateRange = this.rangeOfMonth(dateDistance)

        var images = showItems["imageInfo"] || []
        let displayImages = images.map(x=> {
            return (
                <div key={Math.random().toString(36).substring(7)}>
                    <img src={x.original}/>
                    <HoverText>{imagePositions[`${x.positionCode}`]}</HoverText>
                </div>
            )
        })

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }

            var cancel_dialog = (
                <div className="modal" id="modal-cancel" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancel Transaction</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalCloseAction.bind(this,"modal-cancel","close")} style={{marginTop : '-23px'}}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                            </div>
                            
                            <div className="modal-body">
                                <select  id="cancel-key"  style={{width : "100%",  height : "30px",marginLeft : "5px"}}>
                                    <option>กรุณาเลือก</option>
                                    <option>คนขาย ขายรถผ่านช่องทางอื่นไปแล้ว</option>
                                    <option>คนซื้อไม่สามารถชำระเงินได้ตามข้อตกลง</option>
                                    <option>ประสบปัญหา ระหว่างขนส่ง</option>
                                    <option>สภาพรถไม่พร้อมส่งมอบ</option>
                                    <option>รถเป็น RA Stock</option>
                                    <option>รถส่งลูกค้าไม่ทันกำหนดการ</option>
                                    <option>ลูกค้าเปลี่ยนรุ่น, เปลี่ยนสีรถ</option>
                                    <option>เลือกกดผิดรุ่น, ผิดสี</option>
                                    <option>เอกสารชุดจดทะเบียนไม่สมบูรณ์</option>
                                    <option>ทำการตกลงแลกเปลี่ยนซื้อขายกันเอง นอกระบบ</option>
                                    <option>อื่นๆ</option>
                                </select>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" style={{width : '120px'}} data-dismiss="modal" onClick={this.modalCloseAction.bind(this,"modal-cancel","close")}>Close</button>
                                <button type="button" className="btn btn-primary" style={{width : '120px'}} onClick={this.requestToCancel.bind(this)}>
                                    <i className={upload ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>   
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div> 
            )

            var export_dialog = (
                <div className="modal" id="modal-export" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Export Transaction</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalCloseAction.bind(this,"modal-export","close")} style={{marginTop : '-23px'}}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                            </div>
                            
                            <div className="modal-body">
                                <select  id="export-key"  style={{width : "100%",  height : "30px",marginLeft : "5px"}}>
                                    <option>ALL</option>
                                    <option>SALED</option>
                                    <option>CANCEL</option>
                                    <option>REJECT</option>
                                    <option>COMPLETE</option>
                                </select>
                            </div>

                            <CSVLink id="csvdownload" data={csvData}  filename={export_file}></CSVLink>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" style={{width : '120px'}} data-dismiss="modal" onClick={this.modalCloseAction.bind(this,"modal-export","close")}>Close</button>
                                <button type="button" className="btn btn-primary" style={{width : '200px'}} onClick={this.handleExport.bind(this)}>
                                    <i className={upload ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>   
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>
                </div> 
            )

            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            var contents = null
            if(pages.docs.length > 0){
                contents = []
                for(var index in pages.docs){
                    let item = pages.docs[index]
                       // let pid_date = (item["pidDate"]) ? "-" : dateFormat(item["pidDate"],'dd-mm-yyyy HH:MM:ss')
                        let engineNo = item["engineNumber"] || '-'
                        let vinNo = item["bodyNumber"] || '-'
                        let d = item["pidDate"].split("T")
                        let d1 = d[0].split('-').reverse().join('-')
                        let t1 = d[1].split('.')
                        let create_date = d1 + " " + t1[0]
                        let color = (item["colorInfo"] && item["colorInfo"]["color"] !== undefined) ? item["colorInfo"]["color"] : ""
                        let user = item["user"]["firstname"] + " " + item["user"]["lastname"]
                        
                        let buyerCode = item["buyer_dealerInfo"] != undefined ? item["buyer_dealerInfo"]["dealerCode"] || "-" : "-"
                        let buyer = item["buyer"]["firstname"] + " " + item["buyer"]["lastname"]
                        
                        let sellerCode = item["seller_dealerInfo"] != undefined ? item["seller_dealerInfo"]["dealerCode"] || "-" : "-"
                        let seller = item["seller"]["firstname"] + " " + item["seller"]["lastname"]
                        let offer = item["offer"]["firstname"] + " " + item["offer"]["lastname"]
                        let dealer = item["dealerInfo"] || {}

                        let dateDistance = parseFloat(item["dateDistance"] || "0")
                        var dateRange = this.rangeOfMonth(dateDistance)
        
                        contents.push(
                            
                            <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                            <td align="center">{parseInt(pages.pagingCounter) + parseInt(index)}</td>
                            <td align="left">{vinNo.toUpperCase()}</td>
                            <td align="left">{engineNo.toUpperCase()}</td>
                            <td align="center">{item["seriesName"]}</td>
                            <td align="left">{item["model"]}</td>
                            <td align="center">{item["sfx"]}</td>
                            <td align="center">{item["colorCode"]}</td>
                            <td align="center">{color}</td>
                            <td align="center">{config.formatter(parseInt(item["price"]), 0) || 0}</td>
                            
                            <td align="center">{buyerCode.trim().length == 0 ? "-":buyerCode}</td> 
                            <td align="center">{buyer.trim().length == 0 ? "-":buyer}</td>

                            <td align="left">{item["user"]["dealerCode"] || "-"}</td> 
                            <td align="center">{user}</td> 
                                
                            <td align="center">{dateFormat(item["createdAt"], "GMT:dd-mmm-yyyy")}</td>
                            <td align="center">{dateFormat(item["buyDate"], "GMT:dd-mmm-yyyy")}</td>
                            <td align="center">{`${item["pid_date"] || "-"} ( ${dateRange} )`}</td>
                            <td align="center">{`${`${item["statusInfo"]["status"]}`.toUpperCase()}`}</td>
                            <td align="left">{ item.admin_cancel_remark || "-"}</td>
                            <td align="left">{ item.statusInfo != undefined && item.statusInfo.status == "reject" ? item.remark: "-"}</td>
                            <td align="center">
                                <button type="button" id={item["_id"]} onClick={this.displayDetailClick.bind(this, index)} className="btn btn-default" data-toggle="modal" data-target="#modal-default">
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                </button>
                            </td>
                            <td align="center"className={(cancelPermission != undefined ? cancelPermission.edit:false) ? "" : "hidden"}>
                            {
                                item["statusInfo"]["status"] == "saled" ? (
                                    <button type="button" onClick={this.cancelClick.bind(this, item["_id"])} className="btn btn-block btn-warning">Cancel</button>
                                ):(
                                    <button type="button" onClick={this.cancelClick.bind(this, item["_id"])} className="btn btn-block btn-warning" disabled>Cancel</button>
                                )
                            }
                            </td>
                        </tr>
                        )  
                    }
            }
        }
        
        if (showItems) {
            
            var showPayment = showItems["paymentInfo"] == undefined ? undefined :`${showItems["paymentInfo"]["name"]}` || "-"
            if (showItems["paymentInfo"] && showItems["paymentInfo"]["paymentId"] == 2) {
                showPayment = `${showPayment} (${showItems["customerPayment"]["name"]})`
            }
        }
        
        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>Transaction</h3>
                                <span style={{ float : "right",cursor: "pointer"}}>    
                                    <button type="button"  className="btn btn-success" style={{ width : "150px",marginTop : "16px"}} onClick={this.handleModalExport.bind(this)}>
                                      <img src={excelImage}  style={{width : '25px'}}/>
                                        Export
                                    </button> 
                                    <CSVLink id="csvdownload" data={csvData}  filename={export_file}></CSVLink>
                                </span>
                          
                            </div>
                            <div >
                                <div className="box">
                             
                                <div className="box-search-header">
                               
                                    <div className="box-header">
                                        <div className="col-md-4">
                                            <span style={{marginRight : '10px'}}>Keywards:</span>
                                            <span>
                                                <input type="text" id="search-key" style={{height : "30px"}}/> 
                                            </span>
                                           
                                         </div>
                                    </div>
                                    <div className="box-header">
                                        <div className="col-md-12">
                                            <span  style={{marginRight : '10px'}}>Start Date: </span>
                                            <span>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={this.onStartDateChange.bind(this)}
                                                    dateFormat = "dd-MM-yyyy"
                                                />
                                            </span>

                                            <span  style={{marginRight : '10px',marginLeft : '10px'}}>To Date:</span>
                                            <span>
                                                <DatePicker
                                                    selected={stopDate}
                                                    onChange={this.onStopDateChange.bind(this)}
                                                    dateFormat = "dd-MM-yyyy"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="box-header">
                                         <div className="col-md-12"><br/>
                                            <button className="btn btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                            <button className="btn btn-outline-secondary btn-reset" onClick={this.handleResetClick.bind(this,1)} type="button">Reset</button>
                                        </div>
                                    </div>
                                </div>   

                                <div className="box-header">
                                   <span className={searchKey ? "" : "hidden"}>Seach "{searchKey}" ,please use reset button to refresh query.</span>
                                   <p>
                                   <span>Found <b>{pages.totalDocs}</b> record(s).</span>
                                   </p>
                                </div>

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ loading ? "hidden" : "box-body"} style={{overflowX : 'auto'}}>
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>

                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <style>{'th {textAlign: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                                                    
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="3%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    No
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Vin No.
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Engine No.
                                                                </th>
                                                            
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Series
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Model
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    SFX
                                                                </th>
                                                             
                                                                <th width="7%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Color Code
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Color Name
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Price
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Dlr Buyer Code
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Buyer
                                                                </th>
                                                                
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Dlr Seller Code
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Seller
                                                                </th>

                                                                <th width="7%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Create Date
                                                                </th>

                                                                <th width="7%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Buy Date
                                                                </th>
                                                                
                                                                <th width="15%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Invoice Date
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Status
                                                                </th>

                                                                <th style={{"textAlign":"center"}} className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Cancel Remark
                                                                </th>

                                                                <th style={{"textAlign":"center"}} className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Reject Remark
                                                                </th>

                                                                <th style={{"textAlign":"center"}} className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    View
                                                                </th>

                                                                <th style={{"textAlign":"center"}} className={(cancelPermission != undefined ? cancelPermission.edit:false) ? "sorting" : "hidden"} tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           { contents }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                        
                                           
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handleFetch.bind(this, currentPage - 10)} className="pageButton">
                                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handleFetch.bind(this, currentPage + 10)} className="pageButton">
                                                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* start of dialog */}
                                <div className={preview ? "modal fade in":"modal fade"} id="modal-default" style={{display: preview ? 'block':'none'}}>
                                    <div className="modal-dialog" style={{width:"45%"}}>
                                        <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span></button>
                                            <h3 className="modal-title">รายละเอียด</h3>
                                        </div>
                                        
                                        <div className="modal-body">
                                            <h4><b><u>ข้อมูลผู้ขาย</u></b></h4>
                                            <p><b>User name : </b> {showItems["user"] == undefined ? "-":showItems["user"]["username"]}</p>
                                            <p><b>Dealer : </b> {dealerInfo["dealerCode"] == undefined ? "-":`(${dealerInfo["dealerCode"]}) ${dealerInfo["dealerNameTH"]}`}</p>
                                            <br/>
                                            <h4><b><u>ข้อมูลผู้ซื้อ</u></b></h4>
                                            <p><b>User name : </b> {showItems["buyer"] == undefined ? "-":showItems["buyer"]["username"].length == 0 ? "ไม่มี":showItems["buyer"]["username"]}</p>
                                            <p><b>Dealer : </b> {showItems["buyer_dealerInfo"] == undefined ? "ไม่มี":`(${showItems["buyer_dealerInfo"]["dealerCode"]}) ${showItems["buyer_dealerInfo"]["dealerNameTH"]}`}</p>
                                            <br/>
                                            <h4><b><u>ข้อมูลรายละเอียดรายการ</u></b></h4>
                                            <p><b>Series : </b> {showItems["series"] || "-"}</p>
                                            <p><b>Model : </b> {showItems["model"] || "-"}</p>
                                            <p><b>SFX : </b> {showItems["sfx"] || "-"}</p>
                                            <p><b>Color : </b> {showItems["colorInfo"] == undefined ? undefined :`${showItems["colorInfo"]["color"]}(${showItems["colorInfo"]["colorCode"]})` || "-"}</p>
                                            <p><b>หมายเลขตัวถัง : </b> {showItems["bodyNumber"] || "-"}</p>
                                            <p><b>หมายเลขเครื่องยนต์ : </b> {showItems["engineNumber"] || "-"}</p>
                                            <p><b>วันที่ออกรถ (Invoice Date) : </b> {`${showItems["pid_date"] || "-"} ( ${displayDateRange} )`}</p>
                                            
                                            <p><b>สภาพรถ : </b> {showItems["carCondition"] || "-"}</p>
                                            <p><b>จำนวนตำหนิ : </b> {parseInt(showItems["blame"]) == 0 ? "ไม่มี":`${parseInt(showItems["blame"])} ตำแหน่ง`}</p>
                                            <p><b>รายละเอียดตำหนิ : </b> {showItems["blameDetail"] || "ไม่ระบุ"}</p>
                                            <p><b>รูปถ่าย : </b></p>
                                            {
                                                displayImages.length != 0 ? (
                                                    <div style={{width: "100%"}}>
                                                        <Carousel onChange={this.handlePhotoOnChange} dynamicHeight={true} showIndicators={true} showThumbs={false}>
                                                            {displayImages}
                                                        </Carousel>
                                                    </div>
                                                ):(
                                                    <div align="center" style={{"background-color":"#f9f9f9", "height": "250px", "width": "100%", "display": "table"}}> 
                                                    <p style={{"textAlign": "center", "verticalAlign": "middle", "display": "table-cell"}}>ไม่มีข้อมูลรูปภาพ</p>
                                                    </div>
                                                )
                                            }
                                            <p><b>ช่องทางการชำระเงิน :</b> {showPayment}</p>
                                            <p><b>สถานะ Supply :</b> {showItems["stockInfo"] == undefined ? undefined :`${showItems["stockInfo"]["name"]}` || "-"}</p>
                                            <p><b>หมายเหตุ : </b><br/> {showItems["remark"] || "-"}</p>
                                            <p><b>ราคาขาย/บาท : </b> {config.formatter(parseInt(showItems["price"]), 0) || "-"}</p>
                                            <p><b>เงื่อนไขการชำระเงิน : </b> {showItems["payCondition"] || "-"}</p>
                                            <p><b>วันเวลาที่สร้าง : </b> { showItems["createdAt"] != undefined ? dateFormat(showItems["createdAt"], 'GMT:dd-mmm-yyyy HH:MM'): "-"}</p>
                                            {
                                                showItems["admin_cancel_remark"] != undefined ? (
                                                    <p><b>สถานะ :</b> {showItems["statusInfo"] != undefined ? `${showItems["statusInfo"]["status"].toUpperCase()} ( ${showItems['admin_cancel_remark']} )`:'-'}</p>
                                                ):(
                                                    <p><b>สถานะ :</b> {showItems["statusInfo"] != undefined ? `${showItems["statusInfo"]["status"].toUpperCase()} ${showItems["cancelRemark"] == undefined || showItems["cancelRemark"].length == 0 ? "":`( ${showItems["cancelRemark"]} )`}`:undefined || "-"}</p>
                                                )
                                            }
                                            
                                            <p><b>วันเวลาสถานะ : </b> {showItems["statusInfo"] != undefined ? dateFormat(showItems["updatedAt"],'GMT:dd-mmm-yyyy HH:MM'):undefined || "-"}</p>
                                        </div>
                                        {/* <div className="modal-footer">
                                            <button type="button" className="btn btn-primary">Save changes</button>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* end of dialog */}
                            </div>
                        </div>
                    </div>
                </section>
                {export_dialog}
                {cancel_dialog}
            </div>
        
        )
    }   
}