import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import loadgif from '../../img/loading.gif'
import checkImg from '../../img/checkbox.png'
import uncheckImg from '../../img/uncheck.png'
import viewImg from '../../img/view.png'
const config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.systemAdmin){
            window.location = config.LOGIN
        }

        let noneDeleteItems = [
            { title: "view", keyword: "view"},
            { title: "create", keyword: "create"},
            { title: "edit", keyword: "edit"}
        ]
        
        let selectItems = [
            { title: "view", keyword: "view" },
            { title: "create", keyword: "create" },
            { title: "edit", keyword: "edit" },
            { title: "delete", keyword: "delete" }
        ]

        this.state = {
            permission : userLogin.permission.systemAdmin,
            token : userLogin.token,
            loading:false,
            upload : false,
            roleId : "1",
            searchKey : "",
            sortBy : "typeId",
            userId : -1,
            typeId : 0,
            view : false,
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 50,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            },
            noneDeleteItems: noneDeleteItems,
            selectItems: selectItems
        };

    }

    sortOptions={
        "Type" : "type",
        "Typename" : "typename"
    }

    componentDidMount() {
        this.handleFetch(1)
    }

    handleFetch(page){
        const {pages,sortBy, roleId,token} = this.state;
        
        if(page < 1 || page > pages.totalPages){
            return
        }

        document.getElementById('search-key').value = ""
        this.setState({loading:true , searchKey : ""})
       
        const url = `${config.BASE_URL}/v1/userType?page=${page}&sortBy=${sortBy}&perPage=${pages.perPage}`; 
        //console.log(url)

        fetch(url,{
            headers: {
                'authorization': `basic ${token}`
            },
        }).then((response) => {
            return response.json();
        }).then((result) => {
          
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false})
                },500);
            }
            else{
                this.handleError(result.message)
            }
            
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleSearchClick(page){
        var key = document.getElementById("search-key").value;
        if(key == ""){
            return
        }

        this.setState({loading:true , searchKey : key})

        setTimeout(() => {
            this.handleSearch(1)
          },500);
        
    }

    handleSearch(page){
        const {pages,searchKey,sortBy,token} = this.state;
        const url = `${config.BASE_URL}/v1/userType/search`; 
        this.setState({loading:true })

        var params ={
            "key" : searchKey ,
            "page" : page ,
            "perPage" : pages.perPage,
            "sortBy" : sortBy
        }
     
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false})
                },500);
            }
            else{
                this.handleError(result.message)
            }
        }).catch(error => {
            this.handleError(error)
        });
    }

    handlePageClick(page, event){ 
        const {searchKey} = this.state;
        //console.log("search " + searchKey)
        if(searchKey){
            
            this.handleSearch(page)
        }
        else{
            this.handleFetch(page)
        }
    
    }

    handleSortClick(page, event){ 
        const {searchKey} = this.state;
        var sortKey = document.getElementById('sort-key').value
        if(sortKey == "Sort By"){
            return;
        }

        var sortBy = this.sortOptions[sortKey]
        this.setState({sortBy:sortBy});
    
        setTimeout(() => {
            if(searchKey){
                this.handleSearch(page)
            }
            else{
                this.handleFetch(page)
            }
          },500);
        
        
    }

    handleCreateClick(value, event){
       
        document.getElementById("typeId").value = -1
        document.getElementById("type").value = ""
        document.getElementById("typename").value = ""
        document.getElementById("status").value = "Active"
    
        this.modalAction("modal-add-user-type","open")
    
    }

    handleEditClick(id, event){
        const { pages } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
        let user = result[0]
        console.log(user)
        document.getElementById("typeId").value = result[0].typeId
        document.getElementById("type").value = result[0].type
        document.getElementById("typename").value = result[0].typename
        document.getElementById("status").value = result[0].status
        
        this.modalAction("modal-add-user-type","open")
    }

    handleSetPermissionClick(typeId, event){
        const { pages } = this.state
        this.setState({typeId : typeId})

        var data = pages.docs.filter(obj => {
            return obj.typeId == typeId
        })

        var result = data[0]
        
        this.setupPermission("dealer-data", result.permission.dealerData)
        this.setupPermission("series-data", result.permission.seriesData)
        this.setupPermission("pool-data", result.permission.poolData)
        this.setupPermission("locator-data", result.permission.locatorData)
        this.setupPermission("pool-transaction", result.permission.poolTransaction)
        this.setupPermission("locator-transaction", result.permission.locatorTransaction)
        this.setupPermission("transport", result.permission.transport)
        this.setupPermission("agreement", result.permission.agreement, false)
        this.setupPermission("email", result.permission.email)
        this.setupPermission("user-data", result.permission.userData)
        this.setupPermission("system-admin", result.permission.systemAdmin)
        this.setupPermission("supply-update", result.permission.supply)
        this.setupPermission("tlt-update", result.permission.tlt)
        this.setupPermission("blacklist-update", result.permission.blacklist)
        this.setupPermission("cancel-transaction-update", result.permission.cancelTransaction)

        this.modalAction("modal-set-permission","open")
    
    }

    setPermission(keyword, items = [{title:String, key:String, enabled:Boolean}]) {
        items.forEach(item => {
            let key = item.key == ''? `${item.title}`.toLowerCase():`${item.title}-${item.key}`.toLowerCase()
            if (keyword == "cancel-transaction-update") {
                console.log(keyword, key)
            }
            document.getElementById(key).checked = item.enabled
        })
    }

    setupPermission(keyword, result = {view: true, create:true, edit:true, delete:true}, deleteEnabled = true) {
        let checkAll = result.view == true && result.create == true && result.edit == true
        if (deleteEnabled == true) checkAll = checkAll && result.delete == true
        
        let updateItem = [
            { title: keyword, key: "", enabled: checkAll},
            { title: keyword, key: "view", enabled: result.view},
            { title: keyword, key: "create", enabled: result.create},
            { title: keyword, key: "edit", enabled: result.edit}
        ] 

        if (deleteEnabled == true) {
            updateItem.push( { title: keyword, key: "delete", enabled: result.delete})
        }
        this.setPermission(keyword, updateItem)
    }

    displayPermission(keyword, items = [{title:String, key:String, enabled:Boolean}]) {
        items.forEach(item => {
            let key = `${item.title}-${item.key}`.toLowerCase()
            if (document.getElementById(key) != undefined) {
                document.getElementById(key).src  = (item.enabled == true) ? checkImg : uncheckImg
            }
            
        })
    }

    display(keyword, result, deleteEnabled = true, imgEnabled = false) {
        let updateItem = imgEnabled ? [
            { title: keyword, key: "view-img", enabled: result.view},
            { title: keyword, key: "create-img", enabled: result.create},
            { title: keyword, key: "edit-img", enabled: result.edit}
        ]:[
            { title: keyword, key: "view", enabled: result.view},
            { title: keyword, key: "create", enabled: result.create},
            { title: keyword, key: "edit", enabled: result.edit}
        ] 

        if (deleteEnabled == true) {
            updateItem.push( { title: keyword, key: imgEnabled ? "delete-img":"delete", enabled: result.delete})
        }
        this.displayPermission(keyword, updateItem)
    }
   
    handleViewClick(typeId, event){
        const { pages } = this.state
        this.setState({typeId : typeId})

        var data = pages.docs.filter(obj => {
            return obj.typeId == typeId
        })

        var result = data[0]
        
        this.display("supply", result.permission.supply)
        this.display("tlt", result.permission.tlt)
        this.display("cancel-transaction", result.permission.cancelTransaction)
        this.display("blacklist", result.permission.blacklist)
        this.display("dealer-data", result.permission.dealerData, true, true)
        this.display("series-data", result.permission.seriesData, true, true)
        this.display("pool-data", result.permission.poolData, true, true)
        this.display("locator-data", result.permission.locatorData, true, true)
        this.display("pool-transaction", result.permission.poolTransaction, true, true)
        this.display("locator-transaction", result.permission.locatorTransaction, true, true)
        this.display("transport", result.permission.transport, true, true)
        this.display("agreement", result.permission.agreement, false, true)
        this.display("email", result.permission.email, false, true)
        this.display("user-data", result.permission.userData, true, true)
        this.display("system-admin", result.permission.systemAdmin, true, true)

        this.modalAction("modal-permission-view","open")
    
    }

    handleSaveClick(value, event){
        const { roleId , token } = this.state;
        
        var obj = {
            typeId : document.getElementById("typeId").value,
            type : document.getElementById("type").value,
            typename : document.getElementById("typename").value,
            status : document.getElementById("status").value,
            
        }
        
        if(obj.type == ""){
            alert("Please fill in type.")
            return
        }
        
        var act = (obj.typeId == -1) ? "create" : "update"
        const url = `${config.BASE_URL}/v1/userType/${act}`; 

        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        }).then((response) => {
            return response.json();
        }).then((result) => {
           // console.log(result)
            if(result.status){
                this.modalAction("modal-add-user-type","close")
                this.handleFetch(1)
            }
            else{
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleCheckAllClick(value, event){
        console.log(value)
        var mainChecked = document.getElementById(value).checked 

        document.getElementById(value + "-view").checked  = mainChecked
        document.getElementById(value + "-create").checked = mainChecked
        document.getElementById(value + "-edit").checked = mainChecked
        
        if(value != "agreement" && value != "email"){
             document.getElementById(value + "-delete").checked = mainChecked

        }

       
    }

    handleSavePermissionClick(value, event){
        const { roleId , token ,typeId } = this.state;                                                
        var obj = {
            typeId : typeId,
            permission : {
                dealerData : { 
                    view :  document.getElementById("dealer-data-view").checked ,
                    create : document.getElementById("dealer-data-create").checked,
                    import :document.getElementById("dealer-data-create").checked,
                    edit :document.getElementById("dealer-data-edit").checked,
                    delete : document.getElementById("dealer-data-delete").checked
                },
                seriesData :   { 
                    view :  document.getElementById("series-data-view").checked ,
                    create : document.getElementById("series-data-create").checked,
                    import :document.getElementById("series-data-create").checked,
                    edit :document.getElementById("series-data-edit").checked,
                    delete : document.getElementById("series-data-delete").checked
                },
                poolData :  { 
                    view :  document.getElementById("pool-data-view").checked ,
                    create : document.getElementById("pool-data-create").checked,
                    import : document.getElementById("pool-data-create").checked,
                    edit :document.getElementById("pool-data-edit").checked,
                    delete : document.getElementById("pool-data-delete").checked
                },
                locatorData :   { 
                    view :document.getElementById("locator-data-view").checked ,
                    create : document.getElementById("locator-data-create").checked,
                    import : document.getElementById("pool-data-create").checked,
                    edit :document.getElementById("locator-data-edit").checked,
                    delete : document.getElementById("locator-data-delete").checked
                },                                            
                poolTransaction :   { 
                    view : document.getElementById("pool-transaction-view").checked ,
                    create : document.getElementById("pool-transaction-create").checked,
                    import :false,
                    edit :document.getElementById("pool-transaction-edit").checked,
                    delete : document.getElementById("pool-transaction-delete").checked
                },
                locatorTransaction :  { 
                    view :  document.getElementById("locator-transaction-view").checked ,
                    create : document.getElementById("locator-transaction-create").checked,
                    import :false,
                    edit :document.getElementById("locator-transaction-edit").checked,
                    delete : document.getElementById("locator-transaction-delete").checked
                },
                transport :   { 
                    view : document.getElementById("transport-view").checked ,
                    create : document.getElementById("transport-create").checked,
                    import :document.getElementById("transport-create").checked,
                    edit :document.getElementById("transport-edit").checked,
                    delete : document.getElementById("transport-delete").checked
                },
                agreement :  { 
                    view :  document.getElementById("agreement-view").checked ,
                    create : document.getElementById("agreement-create").checked,
                    import :false,
                    edit :document.getElementById("agreement-edit").checked,
                    delete : false
                },
                email :   { 
                    view : document.getElementById("email-view").checked ,
                    create : document.getElementById("email-create").checked,
                    import :false,
                    edit :document.getElementById("email-edit").checked,
                    delete : document.getElementById("email-delete").checked
                },
                userData :  { 
                    view :  document.getElementById("user-data-view").checked ,
                    create : document.getElementById("user-data-create").checked,
                    import :document.getElementById("user-data-create").checked,
                    edit :document.getElementById("user-data-edit").checked,
                    delete : document.getElementById("user-data-delete").checked
                },
                systemAdmin :   { 
                    view : document.getElementById("system-admin-view").checked ,
                    create : document.getElementById("system-admin-create").checked,
                    import :false,
                    edit :document.getElementById("system-admin-edit").checked,
                    delete : document.getElementById("system-admin-delete").checked
                },
                supply :   { 
                    view : document.getElementById("tlt-update-view").checked ,
                    create : document.getElementById("tlt-update-create").checked,
                    import :false,
                    edit :document.getElementById("tlt-update-edit").checked,
                    delete : document.getElementById("tlt-update-delete").checked
                },
                tlt :   { 
                    view : document.getElementById("tlt-update-view").checked ,
                    create : document.getElementById("tlt-update-create").checked,
                    import :false,
                    edit :document.getElementById("tlt-update-edit").checked,
                    delete : document.getElementById("tlt-update-delete").checked
                },
                blacklist :   { 
                    view : document.getElementById("blacklist-update-view").checked ,
                    create : document.getElementById("blacklist-update-create").checked,
                    import :false,
                    edit :document.getElementById("blacklist-update-edit").checked,
                    delete : document.getElementById("blacklist-update-delete").checked
                },
                cancelTransaction :   { 
                    view : document.getElementById("cancel-transaction-update-edit").checked,
                    create : document.getElementById("cancel-transaction-update-edit").checked,
                    import :document.getElementById("cancel-transaction-update-edit").checked,
                    edit :document.getElementById("cancel-transaction-update-edit").checked,
                    delete : document.getElementById("cancel-transaction-update-edit").checked
                }
            }
        }
        
        const url = `${config.BASE_URL}/v1/userType/permission?typeId=${typeId}`; 

        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        }).then((response) => {
            return response.json();
        }).then((result) => {
           // console.log(result)
            if(result.status){
                this.modalAction("modal-set-permission","close")
                this.handleFetch(1)
            }
            else{
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleDeleteClick(id, event){
        const { token } = this.state;
        confirmAlert({
            title: 'Confirm',
            message: "Do you want to delete this user?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                   
                    const url = `${config.BASE_URL}/v1/userType/delete`;
                
                    this.setState({ loading: true })
            
                     fetch(url, {
                        method: 'post',
                         headers: {
                            'authorization': `basic ${token}`,
                             'Content-Type': 'application/x-www-form-urlencoded',
                         },
                         body: `id=${id}`
                     }).then(function(response) {
                         return response.json();
                        }).then((result) => {
                            //console.log(result)
                            if(result.status){
                                this.handleFetch(1)
                            }
                            else{
                                alert('Your request is not complete');
                            }
                               
                        }).catch(error => {
                            this.handleError(error)
                        });
                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                }
              }
            ]
          })
    }

    modalAction(id,status){
        
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }

    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    capitalize(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    renderWithEdit(title, keyword, items = [{title:String, keyword:String}], imgEnabled = false, ext = "", hideBody = false) {
        let objects = items
        let body = objects.map(item =>{
            let key = item.title == 'create'? `${this.capitalize(item.title)}${ext}`:this.capitalize(item.title)
            let itemId = `${keyword}-${item.keyword}`
            
            if (imgEnabled == true) {
                itemId = `${itemId}-img`
            }
            return (
                <span className="permission-list" key={key}>
                    <input className="permission-checkbox" id={itemId} type="checkbox"  style={{cursor : "pointer"}}/> &nbsp; {key}
                </span>
                // <span className="permission-list">View</span>
            )
        })

        return (
            <div>
                <div className="row modal-box">
                    <div className="col-md-12">
                    <input className="permission-checkbox" type="checkbox" id={keyword} onClick={this.handleCheckAllClick.bind(this, keyword)}  style={{cursor : "pointer"}}/>
                        <span className="permission-head">{title}</span>
                    </div>
                </div>

                <div className={`row modal-box ${hideBody ? "hidden":""}`}>
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                        {body}
                    </div>
                </div>
            </div>
        )
    }

    renderWithView(title, keyword, items = [{title:String, keyword:String}], imgEnabled = false, ext = "", hideBody = false) {
        let objects = hideBody ? items.filter(x => x.keyword == "edit"):items
        let body = objects.map(item =>{
            let key = item.title == 'create'? `${this.capitalize(item.title)}${ext}`:this.capitalize(item.title)
            let itemId = `${keyword}-${item.keyword}`
            if (imgEnabled == true) {
                itemId = `${itemId}-img`
            }
            
            return (
                <span className="permission-list" key={key}>
                    <img className="check-img" id={itemId} src=""/>
                    &nbsp; {key}
                </span>
            )
        })
        return (
            <div>
                <div className="row modal-box">
                    <div className="col-md-12">
                        <span className="permission-head">{title}</span>
                    </div>
                </div>

                <div className={`row modal-box`}>
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                    <img className="check-img" id={keyword} src=""/>
                        {body}
                    </div>
                </div>
            </div>
        )
    }

    render(){
        const { pages, loading ,searchKey, permission, selectItems, noneDeleteItems} = this.state;
        
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;
            
            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
                
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            
            
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            if(pages.docs.length > 0){
                contents = []
                // console.log(pages.docs)
                pages.docs.map((item, index) => (
                    contents.push(
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{item["type"]}</td>
                        <td align="center">{item["typename"]}</td>
                        <td align="center">{item["status"]}</td>
                        <td align="center"><span onClick={this.handleViewClick.bind(this,item["typeId"])} style={{cursor : "pointer"}}><img src={viewImg} style={{width : '20px'}}/></span></td>
                        <td align="center" className={(permission.edit) ? "" : "hidden"}> 
                            <span onClick={this.handleSetPermissionClick.bind(this,item["typeId"])} style={{cursor : "pointer"}}><i  className='fa fa-cog' style={{color : 'blue'}}></i></span>
                        </td>
                        <td align="center" className={(permission.edit) ? "" : "hidden"}>
                            <button type="button" onClick={this.handleEditClick.bind(this, item["_id"])} className="btn btn-block btn-warning">Edit</button>
                        </td> 
                        <td align="center" className={(permission.delete) ? "" : "hidden"}>
                            <button type="button" disable={loading.toString()} onClick={this.handleDeleteClick.bind(this, item["_id"])} className="btn btn-block btn-danger">Delete</button>
                        </td>
                    </tr>
                    )
                ))
            }
        }

        console.log(selectItems)
        let supply = this.renderWithView("สถานะ Supply", "supply", selectItems)
        let supplyEdit = this.renderWithEdit("สถานะ Supply", "supply-update", selectItems)

        let tlt = this.renderWithView("Master TLT Data", "tlt", selectItems)
        let tltEdit = this.renderWithEdit("Master TLT Data", "tlt-update", selectItems)

        let systemAdmin = this.renderWithView("System Admin", "system-admin", selectItems, true)
        let systemAdminEdit = this.renderWithEdit("System Admin", "system-admin", selectItems)

        let userData = this.renderWithView("User Data", "user-data", selectItems, true)
        let userDataEdit = this.renderWithEdit("User Data", "user-data", selectItems)

        let email = this.renderWithView("Email", "email", selectItems, true)
        let emailEdit = this.renderWithEdit("Email", "email", selectItems)

        let agreement = this.renderWithView("Agreement", "agreement", noneDeleteItems, true)
        let agreementEdit = this.renderWithEdit("Agreement", "agreement", noneDeleteItems)

        let transport = this.renderWithView("Transport", "transport", selectItems, true)
        let transportEdit = this.renderWithEdit("Transport", "transport", selectItems)

        let locatorTransaction = this.renderWithView("Locator Transaction", "locator-transaction", selectItems, true)
        let locatorTransactionEdit = this.renderWithEdit("Locator Transaction", "locator-transaction", selectItems)

        let poolTransaction = this.renderWithView("Pool Transaction", "pool-transaction", selectItems, true)
        let poolTransactionEdit = this.renderWithEdit("Pool Transaction", "pool-transaction", selectItems)
        
        let locatorData = this.renderWithView("Locator Data", "locator-data", selectItems, true, "/import")
        let locatorDataEdit = this.renderWithEdit("Locator Data", "locator-data", selectItems, false, "/import")
        
        let poolData = this.renderWithView("Pool Data", "pool-data", selectItems, true, "/import")
        let poolDataEdit = this.renderWithEdit("Pool Data", "pool-data", selectItems, false, "/import")
        
        let seriesData = this.renderWithView("Series Data", "series-data", selectItems, true, "/import")
        let seriesDataEdit = this.renderWithEdit("Series Data", "series-data", selectItems, false, "/import")

        let dealerData = this.renderWithView("Dealer Data", "dealer-data", selectItems, true, "/import")
        let dealerDataEdit = this.renderWithEdit("Dealer Data", "dealer-data", selectItems, false, "/import")

        let blacklist = this.renderWithView("Blacklist", "blacklist", selectItems, false, "", true)
        let blacklistEdit = this.renderWithEdit("Blacklist", "blacklist-update", selectItems, false, "", true)

        let cancelTransaction = this.renderWithView("Cancel Transaction", "cancel-transaction", selectItems, false, "", true)
        let cancelTransactionEdit = this.renderWithEdit("Cancel Transaction", "cancel-transaction-update", selectItems, false, "", true)

        let editModal = (
            <div className="modal" id="modal-set-permission" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"><i  className='fa fa-cog'/> Permission Setting</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalAction.bind(this,"modal-set-permission","close")} style={{marginTop : '-23px'}}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body col-md-12" style={{height : "500px",overflowY: "auto"}}>
                            {dealerDataEdit}
                            {seriesDataEdit}
                            {poolDataEdit}
                            {locatorDataEdit}
                            {poolTransactionEdit}
                            {locatorTransactionEdit}
                            {transportEdit}
                            {agreementEdit}
                            {emailEdit}
                            {userDataEdit}
                            {systemAdminEdit}
                            {supplyEdit}
                            {tltEdit}
                            {blacklistEdit}
                            {cancelTransactionEdit}
                        </div>
                        <div className="modal-footer">
                            <div className="col-md-12" style={{float : 'right'}} align="center">
                                <button type="button" className="btn btn-primary" onClick={this.handleSavePermissionClick.bind(this)} style={{width : '150px',marginTop : '15px'}}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        let previewModal = (
            <div className="modal" id="modal-permission-view" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"><img src={viewImg} style={{width : '20px'}}/>Permission View</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalAction.bind(this,"modal-permission-view","close")} style={{marginTop : '-23px'}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                        </div>
                        <div className="modal-body col-md-12" style={{height : "500px",overflowY: "auto"}}>
                            {dealerData}
                            {seriesData}
                            {poolData}
                            {locatorData}
                            {poolTransaction}
                            {locatorTransaction}
                            {transport}
                            {agreement}
                            {email}
                            {userData}
                            {systemAdmin}
                            {supply}
                            {tlt}
                            {blacklist}
                            {cancelTransaction}
                        </div>
                        <div className="modal-footer">
                        </div>
                    </div>
                </div>
            </div>
        )
        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>Permission</h3>
                                <span style={{ float : "right",cursor: "pointer"}}>    
                                    {/* <button type="button"  className="btn btn-primary" style={{ width : "150px",marginTop : "16px"}} onClick={this.modalAction.bind(this,"modal-import","open")}>Import</button>  */}
                                </span>
                          
                            </div>
                          
                            <div >
                                <div className="box">
                                    <div className="box-header">
                                    <div className={(permission.create) ? "" : "hidden"}>
                                        <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleCreateClick.bind(this)}>
                                            <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px"}}>
                                                <i className="fa fa fa-plus"></i>
                                                <span>&nbsp;Create</span>
                                                <span className="pull-right-container"></span>
                                            </a>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="box-header">
                                        <div className="pull-left">
                                            <div className="input-group mb-3">
                                                <input type="text" id="search-key" style={{height : "34px"}}/>
                                                
                                                    <button className="btn btn-outline-secondary btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                                    <button className="btn btn-outline-secondary btn-reset" onClick={this.handleFetch.bind(this,1)} type="button">Reset</button>
                                            </div>
                                        </div>
                                        <div className="pull-right">  
                                            <select  id="sort-key"  style={{width : "200px",  height : "30px",marginLeft : "5px"}}>
                                                <option>-- Sort By --</option>
                                                <option>Type</option>
                                                <option>Typename</option>
                                            </select>
                                            <button className="btn btn-outline-primary btn-submit" onClick={this.handleSortClick.bind(this,1)} type="button">Filter</button>
                                        </div>
                                    </div>

                                    <div className="box-header">
                                     <span className={searchKey ? "" : "hidden"}>Seach "{searchKey}" ,please use reset button to refresh query.</span>
                                     <p>
                                   <span>Found <b>{pages.totalDocs}</b> record(s).</span>
                                   </p>
                                    </div>

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ loading ? "hidden" : "box-body"} style={{overflowX : 'auto'}}>
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <style>{'th {text-align: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Type
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Typename
                                                                </th>
                                                              

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Status
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                View
                                                                </th>
  
                                                                <th  className={(permission.edit) ? "sorting" : "hidden"} tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Permission
                                                                </th>
                                                                
                                                                <th  className={(permission.edit) ? "sorting" : "hidden"} tabIndex="3"  rowSpan="1" colSpan="1"  align="center" style={{width : '80px'}}>
                                                                    <span className='fa fa-edit'/> Edit
                                                                </th>
                                                                
                                                                <th   className={(permission.delete) ? "sorting" : "hidden"} tabIndex="4"  rowSpan="1" colSpan="1" style={{width : '80px'}}>
                                                                    <span className='fa fa-bitbucket'/> Delete
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                           { contents }
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                        
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage - 1)} className={currentPage > 10 ? "pageButton" : "hidden"}>
                                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage + 1)} className={currentPage < totalPages && pageList.length == 10 && totalPages > 10 ? "pageButton" : "hidden"}>
                                                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Header */}
                                            <div className="modal" id="modal-add-user-type" tabIndex="-1" role="dialog">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Permission</h5>
                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalAction.bind(this,"modal-add-user-type","close")} style={{marginTop : '-23px'}}>
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>

                                                        <div className="modal-body col-md-12" style={{height : "auto"}}>
                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Type<span className="required-label">*</span></label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="type"  style={{width : "100%"}} />
                                                                    <input type="hidden" className="form-control" id="typeId"  style={{width : "100%"}} />
                                                                </div>
                                                            </div>

                                                           
                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>TypeName</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="typename"    style={{width : "100%"}} />
                                                                </div>
                                                            </div>
                                                                
                                                        
                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Status</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                        <select className="form-control" id="status"  >
                                                                            <option>Active</option>
                                                                            <option>Inactive</option>
                                                                        </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.modalAction.bind(this,"modal-add-user-type","close")} style={{width : '150px',marginBottom : '20px'}}>Close</button>
                                                            <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : '150px',marginBottom : '20px'}}>Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {editModal}
                                            {previewModal}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}