import React, {Component} from 'react';
import ReactQuill from 'react-quill';
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import './css/quill.snow.css';
import loadgif from '../../img/loading.gif'
const config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.agreement){
            window.location = config.LOGIN
        }

        var toolbarOptions = [
            //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            //['blockquote', 'code-block'],
           // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, {'indent': '-1'}, {'indent': '+1'}],
            //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            ['link', 'image'],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']                                         // remove formatting button
          ];

        this.state = {
            permission : userLogin.permission.agreement,
            userId : userLogin.userId,
            token : userLogin.token,
            loading:false,
            update: false,
            content : "",
            modules : {
                toolbar: toolbarOptions,
            },
            formats : [
                'header',
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet', 'indent','align',
                'link', 'image','color','background'
            ]

        };

    }

    componentDidMount() {
        this.handleFetch()
    }

    handleFetch(){
        const {token} = this.state;
        this.setState({loading:true})

        const url = `${config.BASE_URL}/v1/agreement`; 
        
        console.log(url)
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`
            },
        }).then((response) => {
            return response.json();
        }).then((result) => {
            console.log(result)
             if(result.status){

                setTimeout(() => {
                    this.setState({loading:false})
                    if(result.data){
                        this.setState({content : result.data.description})
                    }
                       // document.getElementById("description").value = result.data.description
                    
                  },1000);
          
             }
             else{
               
                this.handleError(result.message)
             }
            
        }).catch(error => {
            this.handleError(error)
        });
    }
    
    onEditorStateChange (value) {
         this.setState({content : value})
     };
 
    handleSaveClick(value, event){
        const {token,content} = this.state
        
        this.setState({update:true})
       
        const url = `${config.BASE_URL}/v1/agreement/update`; 
        console.log(url)
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify({description : content})
        }).then((response) => {
            return response.json();
        }).then((result) => {
           
            if(result.status){
                setTimeout(() => {
                    this.setState({ update:false})
               },1000)
                
            }
            else{
                this.setState({update:false})
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.setState({update:false})
            this.handleError(error)
        });
    }

    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    render(){
        const { loading ,update ,modules,formats,content ,permission} = this.state;
        
        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>Agreement</h3>
                                
                            </div>
                          
                            <div >
                                <div className="box">
                                
                                  <div className="box-header">
                                    
                                  </div>

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className= "box-body">
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                           <div className={loading ? "hidden" :"col-md-12"}>
                                               
                                                            
                                                        
                                                            <div className="row modal-box">
                                                                <div className="col-md-12 ">
                                                                    {/* <textarea id="description"  className="form-control" style={{width : "100%",height : "450px"}}></textarea> */}
                                                                    <ReactQuill 
                                                                        value={content}
                                                                        modules={modules}
                                                                        formats={formats}
                                                                        onChange={this.onEditorStateChange.bind(this)}/>
            
                                                                </div>

                                                                
                                                            </div>


                                                            <div className="row modal-box">
                                                                <div className="col-md-12" style={{textAlign : "center"}}>
                                                                    <div className={(permission.create) ? "" : "hidden"}>
                                                                    <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : "300px",height : "46px"}}>
                                                                    <i className={update ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>
                                                                        Save
                                                                    </button>
                                                                    </div>
                                                                </div> 
                                                            </div>
                                                    
                                                </div>
                                            
                                            

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}