import React, { Component } from 'react';
var config = require('../share-components/Config');

class Login extends Component {
  constructor(props) {
    super(props);
    var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
    if(userLogin){
        var menu = localStorage.getItem('menu')
        var routers = {
          'dashboard' : config.DASHBOARD,
          'agreement' : config.AGREEMENT,
          'color' : config.COLOR,
          'deadstock': config.DEADSTOCK,
          'dealer': config.DEALER,
          'dealer_user' : config.DEALERUSER,
          'email' : config.EMAIL,
          'locator_transaction' : config.LOCATOR_TRANSACTION,
          'permission' :config.PERMISSION,
          'pool' : config.POOL,
          'pool_transaction' : config.POOL_TRANSACTION,
          'price' : config.PRICE,
          'series' : config.SERIES,
          'series_master' : config.SERIESMASTER,
          'tmt_user' : config.TMTUSER,
          'transport' : config.TRANSPORT,
          'user' : config.USER,
          'wtb' : config.WTB,
         'wts' : config.WTS
        }
     
        window.location = (routers[menu] !== undefined) ? routers[menu] : routers['dashboard']
       
    }
    else{
        window.location = config.LOGIN
    }
   
    this.state = {
     
    };

    //localStorage.clear()
  }


  render() {
    return (
      <div></div>
     
    );
  }
}

export default Login;
