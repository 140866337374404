import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select'
import { CSVLink } from "react-csv";
import {Parser} from 'json2csv'
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import loadgif from '../../img/loading.gif'
import importPng from '../../img/import_csv.png'
import exportPng from '../../img/export_csv.png'
import excelImage from '../../img/excel.png'
var config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.dealerData){
            window.location = config.LOGIN
        }
        console.log(userLogin.permission)
        this.state = {
            permission : userLogin.permission.dealerData,
            blacklist : userLogin.permission.blacklist,
            token : userLogin.token,
            loading:false,
            upload : false,
            searchKey : "",
            sortBy : "createdAt",
            zones : [],
            provinces : [],
            show : "table",
            title : "",
            zoneId :1,
            csvData : [],
            export_file : "",
            zone : "",
            provinceId : 2,
            province : "",
            pageIndex: 1,
            editId:"",
            dealer :  {
                dealerCode : "",
                dealerNameTH : "",
                dealerNameEN : "",
                zone : "",
                juristicId : "",
                address : "",
                tumbol : "",
                amphoe : "",
                province : "",
                postcode : "",
                road : "",
                tel : "",
                fax : "",
                status : ""
            },
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 50,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            }
        };

    }

    componentDidMount() {
        
        const {pageIndex} = this.state;
        this.handleFetch(pageIndex)
        this.getZone()
        this.getProvince()
    }

    handleFetch(page){
        const {pages,sortBy, token} = this.state;

        if(page < 1 || page > pages.totalPages){
            return
        }
        document.getElementById('search-key').value = ""
        this.setState({loading:true , searchKey : "", pageIndex: page})
       
        const url = `${config.BASE_URL}/v1/dealer?&page=${page}&sortBy=${sortBy}&perpage=${pages.perPage}&fixPage=true`; 
        let configs = {
            headers: {
                'authorization': `basic ${token}`,
            }
        }
        fetch(url, configs).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false , show : "table"})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
        }).catch(error => {
            this.handleError(error)
        });

    }

    getZone(){
        const { token } = this.state;
        document.getElementById('search-key').value = ""
        this.setState({loading:true , searchKey : ""})
       
        const url = `${config.BASE_URL}/v1/zone`; 
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({zones : result.data})
            }
        }).catch(error => {
            this.handleError(error)
        });

    }

    getProvince(){
        const { token } = this.state;


        document.getElementById('search-key').value = ""
        this.setState({loading:true , searchKey : ""})
       
        const url = `${config.BASE_URL}/v1/province`; 
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            
            if(result.status){
                this.setState({provinces : result.data});
           
            }
           

        }).catch(error => {
            this.handleError(error)
        });

    }

    handleSortClick(page, event){ 
        const {searchKey} = this.state;
        var sortKey = document.getElementById('sort-key').value
        if(sortKey == "Sort By"){
            return;
        }

        var sortOptions={
            "Dealer Code" : "dealerCode",
            "Dealer Name TH" : "dealerNameTH",
            "Dealer Name EN" : "dealerNameEN",
            "Zone"    : "zone",
        }

        var sortBy = sortOptions[sortKey]
        this.setState({sortBy:sortBy});
    
        setTimeout(() => {
            if(searchKey){
                this.handleSearch(page)
            }
            else{
                this.handleFetch(page)
            }
          },500);
        
        
    }

    handleSearchClick(page){
        var key = document.getElementById("search-key").value;
        if(key == ""){
            return
        }

        this.setState({loading:true , searchKey : key})

        setTimeout(() => {
            this.handleSearch(1)
          },500);
        
    }

    handleSearch(page){
        const {pages,searchKey,sortBy, roleId,token} = this.state;
        const url = `${config.BASE_URL}/v1/dealer/search`; 
        this.setState({loading:true })

        var params ={
            "key" : searchKey ,
            "roleId" : roleId ,
            "page" : page ,
            "perPage" : pages.perPage,
            "sortBy" : sortBy
        }
        
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });
    }

    handlePageClick(page, event){ 
        const {searchKey} = this.state;
        if(searchKey){
            
            this.handleSearch(page)
        }
        else{
            this.handleFetch(page)
        }
    }
    
    handleCreateClick(value, event){
        document.getElementById("dealerId").value = -1
        document.getElementById("dealerCode").value = ""
        document.getElementById("dealerNameTH").value = ""
        document.getElementById("dealerNameEN").value = ""
        document.getElementById("dealerNameAbb").value = ""
      //  document.getElementById("zone").selectedIndex = 0
        document.getElementById("juristicId").value = ""
        document.getElementById("address").value = ""
        document.getElementById("tumbol").value = ""
        document.getElementById("amphoe").value = ""
       // document.getElementById("province").selectedIndex = 0
        document.getElementById("postcode").value = ""
        document.getElementById("road").value = ""
        document.getElementById("tel").value = ""
        document.getElementById("fax").value = ""
        document.getElementById("status").value = "Activated"
        
        document.getElementById("bankingName").value = ""
        document.getElementById("bookingNumber").value = ""
        document.getElementById("accountHolder").value = ""
        document.getElementById("office").value = ""

        document.getElementById("contact_name").value = ""
        document.getElementById("contact_phonenumber").value = ""
        document.getElementById("contact_remark").value = ""

        this.setState({show : "add",title : "Create"})
        //this.modalAction("modal-add-user","open")
    
    }

    handleEditClick(id, event){
        const { pages,zones,provinces } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
        this.setState({editId: id})
        try {
            document.getElementById("dealerId").value = result[0]._id
            document.getElementById("dealerCode").value = result[0].dealerCode
            document.getElementById("dealerNameTH").value = (result[0].dealerNameTH) ? result[0].dealerNameTH.trim() : ""
            document.getElementById("dealerNameEN").value =  (result[0].dealerNameEN) ? result[0].dealerNameEN.trim(): ""
            document.getElementById("dealerNameAbb").value = (result[0].dealerNameAbb) ? result[0].dealerNameAbb.trim() : ""
            //document.getElementById("zone").value = result[0].zone
            document.getElementById("juristicId").value = (result[0].juristicId) ? result[0].juristicId.trim() : ""
            document.getElementById("address").value = (result[0].address) ? result[0].address.trim() : ""
            document.getElementById("tumbol").value = (result[0].tumbol) ?result[0].tumbol.trim() : ""
            document.getElementById("amphoe").value = (result[0].amphoe) ? result[0].amphoe.trim() : ""
            //document.getElementById("province").value = result[0].province
            document.getElementById("postcode").value = (result[0].postcode) ? result[0].postcode.trim() : ""
            document.getElementById("road").value = (result[0].road) ? result[0].road.trim() : ""
            document.getElementById("tel").value = (result[0].tel) ? result[0].tel.trim() : ""
            document.getElementById("fax").value = (result[0].fax) ? result[0].fax.trim() : ""
            document.getElementById("status").value = result[0].status || "Active"

            document.getElementById("contact_name").value = result[0].contact || ""
            document.getElementById("contact_phonenumber").value = result[0].phonenumber || ""
            document.getElementById("contact_remark").value = result[0].remark || ""

            let bankingInfo = result[0]["bankingInfo"] || {}

            document.getElementById("bankingName").value = bankingInfo.bankingName || ""
            document.getElementById("bookingNumber").value = bankingInfo.bookingNumber || ""
            document.getElementById("accountHolder").value = bankingInfo.accountHolder || ""
            document.getElementById("office").value = bankingInfo.office || ""

            var zoneId = result[0].zoneId
            var zone = result[0].zone
            if( result[0].zoneId === undefined){
                var allZone = zones.filter(obj => {
                    return obj.description == zone
                })
                zone = allZone[0].zone
                zoneId = allZone[0].zoneId || 0
            }
            var provinceId = result[0].provinceId
            if( result[0].provinceId === undefined){
                var province = provinces.filter(obj => {
                    return obj.name == result[0].province
                })
                provinceId = province[0].provinceId
            }

            this.setState({zoneId : zoneId , zone: zone, provinceId : provinceId, show : "add" , title : "Update"})
        } catch (error) {
            
        }
    }

    handleViewClick(id, event){
        const { pages } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
        var data = result[0]
        var dealer = {
            dealerCode : data.dealerCode,
            dealerNameTH : (data.dealerNameTH) ? data.dealerNameTH : "",
            dealerNameEN : (data.dealerNameEN) ? data.dealerNameEN : "",
            dealerNameAbb : (data.dealerNameAbb) ? data.dealerNameAbb : "",
            zone : data.zone,
            juristicId : (data.juristicId) ? data.juristicId : "",
            address : (data.address) ? data.address : "",
            amphoe : (data.amphoe) ? data.amphoe : "",
            tumbol : (data.tumbol) ? data.tumbol : "",
            province : data.province,
            postcode : (data.postcode) ? data.postcode : "",
            road : (data.road) ? data.road : "",
            tel : (data.tel) ? data.tel : "",
            fax : (data.fax) ? data.fax : "",
            status : data.status,
            bankingInfo: data.bankingInfo,
            contact : (data.contact) ? data.contact : "",
            phonenumber : (data.phonenumber) ? data.phonenumber : "",
            remark : (data.remark) ? data.remark : "",
            blocked : data.blocked || false
        }
          this.setState({ dealer : dealer ,show : 'view'})
        //this.modalAction("modal-view","open")
    }

    handleZoneChange(val){
        this.setState({zoneId : val.value ,zone : val.label})
    }

    handleProvinceChange(val){
        this.setState({provinceId : val.value , province : val.label})
    }
    
    exportHandler(value, event){
        console.log(value, event)
        const {pages,sortBy, token} = this.state;
        const url = `${config.BASE_URL}/v1/dealer?&page=1&sortBy=${sortBy}&perpage=${pages.totalDocs}`; 
        
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then(async (result) => {
            
            if(result.status){
                 
                let data = result.data.docs.map(val => {
                    let bankingInfo = val.bankingInfo
                    let bankingName = bankingInfo ? bankingInfo.bankingName || "-" : "-"
                    let bookingNumber = bankingInfo ? bankingInfo.bookingNumber || "-" : "-"
                    let accountHolder = bankingInfo ? bankingInfo.accountHolder || "-" : "-"
                    let office = bankingInfo ? bankingInfo.office || "-" : "-"
                    let phonenumber = val.phonenumber || '-'
                    return {
                        'Dealer Code': val.dealerCode,
                        'Dealer Name TH': val.dealerNameTH,
                        'Dealer Name EN': val.dealerNameEN,
                        'Dealer Name Abb': val.dealerNameAbb,
                        'Zone': val.zone,
                        'Juristic Id': val.juristicId,
                        'Address': val.address,
                        'Road': val.road,
                        'Kwang/Tumbol': val.tumbol,
                        'Khet/Amphoe': val.amphoe,
                        'Province': val.province,
                        'Postcode': val.postcode,
                        'Tel': val.tel,
                        'Fax': val.fax,
                        'Status': val.status,
                        'Banking Name': bankingName,
                        'Banking No': bookingNumber,
                        'Account Name': accountHolder,
                        'Banking Office': office,
                        'Contact': val.contact || '-',
                        'Phonenumber': phonenumber,
                        'Remark': val.remark || '-'
                    }
                })

                let records = await Promise.all(data)
                const fields = [
                    'Dealer Code',
                    'Dealer Name TH' ,
                    'Dealer Name EN',
                    'Dealer Name Abb',
                    'Zone',
                    'Juristic Id',
                    'Address',
                    'Road',
                    'Kwang/Tumbol',
                    'Khet/Amphoe',
                    'Province',
                    'Postcode',
                    'Tel',
                    'Fax',
                    'Status',
                    'Banking Name',
                    'Banking No',
                    'Account Name',
                    'Banking Office',
                    'Contact',
                    'Phonenumber',
                    'Remark'
                 ]
                const opts = { fields };
                const parser = new Parser(opts);
                const csv = parser.parse(records);

                var d = new Date();
                var dd = (d.getDate() >= 10 ) ? d.getDate() : '0' + d.getDate()
                var mm = ((d.getMonth() +1) >= 10) ? d.getMonth() +1 : '0' + d.getMonth() +1
                var yyyy = d.getFullYear()
                var hh = d.getHours()
                var ii = d.getMinutes()
                var ss = d.getSeconds()
                var export_file = `export_dealer_${dd}${mm}${yyyy}_${hh}${ii}${ss}.csv`
                
                var hiddenElement = document.createElement('a');
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI("\ufeff"+csv);
                hiddenElement.target = '_blank';
                hiddenElement.download = export_file;
                hiddenElement.click();
            } else {
                this.handleError(result.message)
            }
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleReset(){
        this.setState({ sortBy : 'createdAt'})
        const {pageIndex} = this.state;
        this.handleFetch(pageIndex)
    }

    handleSaveClick(value, event){
        const {token ,zoneId,zone,zones,provinceId,province,provinces} = this.state;
        var zoneName = zone
        var provinceName = province
        if(!zoneName){
            var z = zones.filter(obj => {
                return obj.zoneId == zoneId
            })
            zoneName = z[0].description
        }
       
        if( !provinceName){
            var p = provinces.filter(obj => {
                return obj.provinceId == provinceId
            })
            provinceName = p[0].name
        }
        
        var obj = {
            id : document.getElementById("dealerId").value,
            dealerCode : document.getElementById("dealerCode").value,
            dealerNameTH : document.getElementById("dealerNameTH").value || " ",
            dealerNameEN : document.getElementById("dealerNameEN").value || " ",
            dealerNameAbb : document.getElementById("dealerNameAbb").value || " ",
            zoneId : zoneId,
            zone : zoneName,
            juristicId : document.getElementById("juristicId").value  || " ",
            address : document.getElementById("address").value  || " ",
            tumbol : document.getElementById("tumbol").value  || " ",
            amphoe : document.getElementById("amphoe").value  || " ",
            provinceId : provinceId,
            province : provinceName,
            postcode : document.getElementById("postcode").value  || " ",
            road : document.getElementById("road").value  || " ",
            tel :  document.getElementById("tel").value  || " ", 
            fax : document.getElementById("fax").value  || " ",
            status : document.getElementById("status").value,
            contact: document.getElementById('contact_name').value,
            phonenumber: document.getElementById('contact_phonenumber').value,
            remark: document.getElementById('contact_remark').value
        }

        let bankingName = document.getElementById("bankingName").value
        if (bankingName) obj["bankingName"] = bankingName

        let bookingNumber = document.getElementById("bookingNumber").value
        if (bookingNumber) obj["bookingNumber"] = bookingNumber

        let accountHolder = document.getElementById("accountHolder").value
        if (accountHolder) obj["accountHolder"] = accountHolder

        let office = document.getElementById("office").value
        if (office) obj["office"] = office
       
        var act = (obj.id == -1) ? 'create' : 'update'
        const url = `${config.BASE_URL}/v1/dealer/${act}`; 
        
        if(document.getElementById("dealerCode").value == "" || document.getElementById("dealerNameTH").value == ""){
            alert("Please fill require field")
            return
        }

        var emptyFiled = false;
        for (var k in obj) {
             if(obj[k] == "" || obj[k] == " "){
                emptyFiled = true
             }
        }

        if(emptyFiled){
            confirmAlert({
                title: 'Confirm',
                message: "Found empty field. Are you sure to save?",
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                        this.handleSave(url,token,obj)
                    }
                  },
                  {
                    label: 'No',
                    onClick: () => {
                        
                    }
                  }
                ]
              })
        }
        else{
            this.handleSave(url,token,obj)
        }
       
    }

    handleSave(url,token,user){
        const {editId,pageIndex, pages} = this.state

        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(user)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            //.log(result)
            if(result.status){
                //this.modalAction("modal-add-user","close")
                let index = pages.docs.findIndex(item => {
                    return item._id == editId
                })

                if (index != -1) {
                    var updated = pages.docs[index]
                    updated.id = user.id
                    updated.dealerCode = user.dealerCode
                    updated.dealerNameTH = user.dealerNameTH
                    updated.dealerNameEN = user.dealerNameEN
                    updated.dealerNameAbb = user.dealerNameAbb
                    updated.zoneId = user.zoneId
                    updated.zone = user.zone
                    updated.juristicId = user.juristicId
                    updated.address = user.address
                    updated.tumbol = user.tumbol
                    updated.amphoe = user.amphoe
                    updated.provinceId = user.provinceId
                    updated.province = user.province
                    updated.postcode = user.postcode
                    updated.road = user.road
                    updated.tel = user.tel
                    updated.fax = user.fax
                    updated.status = user.status
                    pages.docs[index] = updated

                }
                
                setTimeout(() => {
                    const {pageIndex} = this.state;
                    this.handleFetch(pageIndex)
                },500);
                //this.setState({ pages: pages, loading:false ,show : "table"});
            }
            else{
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleDeleteClick(id, event){
        const {token} = this.state;
        confirmAlert({
            title: 'Confirm',
            message: "Do you want to delete this dealer?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                   
                    const url = `${config.BASE_URL}/v1/dealer/delete`;
                
                    this.setState({ loading: true })
            
                     fetch(url, {
                        method: 'post',
                         headers: {
                            'authorization': `basic ${token}`,
                             'Content-Type': 'application/x-www-form-urlencoded',
                         },
                         body: `id=${id}`
                     }).then(function(response) {
                         return response.json();
                        }).then((result) => {
                            if(result.status){
                                setTimeout(() => {
                                    const {pageIndex} = this.state;
                                    this.handleFetch(pageIndex)
                                  },500);
                               
                            }
                            else{
                                this.handleError(result.message)
                            }
                               
                        }).catch(error => {
                            this.handleError(error)
                        });
                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                }
              }
            ]
        })
    }

    handleUnBlockClick(id, event){
        const {token, pages, pageIndex} = this.state;
        
        const url = `${config.BASE_URL}/v1/blacklist/remove`;
        this.setState({ loading: true })
        let configs = {
            method: 'post',
                headers: {
                'authorization': `basic ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `dealerCode=${id}`
        }
        fetch(url, configs).then(function(response) {
                return response.json();
        }).then((result) => {
            if(result.status){
                let index = pages.docs.findIndex(x => {
                    return x.dealerCode == id
                })
        
                let updateItem = pages.docs[index]
                updateItem["blocked"] = false 
                pages.docs[index] = updateItem
                this.setState({pages: pages, loading: false})
            }
            else{
                this.handleError(result.message)
            }   
        }).catch(error => {
            this.handleError(error)
        })
    }

    handleBlockClick(id, event){
        const {token, pageIndex, pages} = this.state;
        
        confirmAlert({
            title: 'Confirm',
            message: "Do you want to block this dealer?",
            buttons: [
              {
                label: 'Yes', onClick: () => {
                    const url = `${config.BASE_URL}/v1/blacklist/add`;
                
                    this.setState({ loading: true })
                    let configs = {
                        method: 'post',
                         headers: {
                            'authorization': `basic ${token}`,
                             'Content-Type': 'application/x-www-form-urlencoded',
                         },
                         body: `dealerCode=${id}`
                    }
                    fetch(url, configs).then(function(response) {
                         return response.json();
                    }).then((result) => {
                        if(result.status){
                            let index = pages.docs.findIndex(x => {
                                return x.dealerCode == id
                            })
                    
                            let updateItem = pages.docs[index]
                            updateItem["blocked"] = true 
                            pages.docs[index] = updateItem
                            this.setState({pages: pages, loading: false})
                        }
                        else{
                            this.handleError(result.message)
                        }   
                    }).catch(error => {
                        this.handleError(error)
                    })
                }
              },
              {
                label: 'No', onClick: () => {
                    
                }
              }
            ]
        })
    }

    onSetResult = (value, result) => {
        
        if (result["status"] == true){
            window.location.reload();
        }else{
            alert('Your request is not complete seriesname or email is already.'+JSON.stringify(result));
        }

        const { loading } = this.state;
        this.setState({ loading: !loading })
    }
    
    handleShowClick(show){
        this.setState({show : show});
    }

    modalAction(id,status){
        
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }

   

    handleFileUpload(value){
        const {  token } = this.state;
       
        const browserFiles = document.querySelector('input[type="file"]')
        const url = `${config.BASE_URL}/v1/dealer/upload`;
        var formData = new FormData()
       
        for (const file of browserFiles.files) {
            formData.append('file',file,file.name)
        }
        
        this.setState({ upload: true })

        fetch(url, {
            method: 'POST',
            headers: {
                'authorization': `basic ${token}`
             },
            body: formData
        }).then(function(response) {
            return response.json();
        }).then(result => this.onSetFileResult(value, result)).catch(error => {
            this.setState({ loading: false ,upload : false});
            alert(error)
        });
        
    }

    onSetFileResult = (value, result) => {
        this.setState({ upload: false })
        if (result["status"] == true){

            //alert("Successfully");
            this.modalAction("modal-import","close")
            const {pageIndex} = this.state;
            this.handleFetch(pageIndex)
        }else{
            this.handleError(result.message)
        }

    }

   
    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    render(){
        const { pages, pageIndex, loading ,searchKey,upload,zones,provinces ,dealer, permission, blacklist,show,title ,zoneId,provinceId,zone,csvData , export_file } = this.state;
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={pageIndex == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            console.log(permission)
            var contents = null
            if(pages.docs.length > 0){
                contents = []
                for(var index in pages.docs){
                    let item = pages.docs[index]
                    
                    contents.push(
                       
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{parseInt(pages.pageIndex) + parseInt(index)}</td> 
                        <td align="center">{item["dealerCode"]}</td>
                        <td align="left">{item["dealerNameTH"]}</td>
                        <td align="left">{item["dealerNameEN"]}</td>
                        <td align="center">{item["zone"]}</td>
                        <td align="center">{item["status"]}</td>
                        <td align="center"><a href="#" onClick={this.handleViewClick.bind(this,item["_id"])}>View</a></td>
                        <td align="center"className={(permission.edit) ? "" : "hidden"}>
                            <button type="button" onClick={this.handleEditClick.bind(this, item["_id"])} className="btn btn-block btn-warning">Edit</button>
                        </td> 

                        <td align="center" className={(permission.delete) ? "" : "hidden"}>
                            <button type="button" disable={loading.toString()} onClick={this.handleDeleteClick.bind(this, item["_id"])} className="btn btn-block btn-danger">Delete</button>
                        </td>
                        
                        <td align="center" className={(blacklist.edit) ? "" : "hidden"}>
                            <button type="button" disable={loading.toString()} 
                            onClick={
                                item["blocked"] == false ? 
                                this.handleBlockClick.bind(this, item["dealerCode"])
                                :
                                this.handleUnBlockClick.bind(this, item["dealerCode"])
                            } className="btn btn-block btn-danger">{item["blocked"] == false ? "Block":"Unblock"}</button>
                        </td>
                    </tr>
                    )
                    
                }
            }
        }
        
        var zone_options = zones.map(result => {
            return { value: result.zoneId, label: result.description }
        })
        
        var zoneValue = zone_options.filter(option => parseInt(option.value) == parseInt(zoneId) || option.label == zone)
        var province_options = []
        for(var i in provinces){
            province_options.push( { value: provinces[i].provinceId, label: provinces[i].name } )     
        }
        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                               
                                <h3 style={{float : "left"}}>Dealer Management</h3>

                                <div className={(permission.import) ? "" : "hidden"}>
                                <span style={{ float : "right",cursor: "pointer"}}> 
                                    <button type="button"  onClick={this.exportHandler.bind(this)} className="btn btn-success" style={{ width: '150px', height: '40px', marginTop: '16px',marginLeft: '8px'}}>
                                    <img src={excelImage}  style={{cursor:'pointer',width:'29px'}}/>
                                              <CSVLink id="csvdownload" data={csvData}  filename={export_file}></CSVLink>
                                              Export Data
                                    </button> 
                                </span>
                             
                                <span style={{ float : "right",cursor: "pointer"}}> 
                                <a href={`${config.BASE_URL}/v1/csv/dealer_form.csv`}>   
                                    <button type="button"  className="btn btn-success" style={{ width : "150px",marginTop : "16px"}}>
                                      <img src={exportPng} style={{width : '25px'}}/>
                                        Export Form
                                    </button> 
                                </a>
                                </span>
                                <span   style={{ float : "right",cursor: "pointer"}}>
                                    <button type="button"  className="btn btn-primary" style={{ width : "150px",marginTop : "16px",marginRight : "10px"}} onClick={this.modalAction.bind(this,"modal-import","open")}>
                                    <img src={importPng} style={{width : '25px'}}/>
                                        Import
                                    </button> 
                                </span>
                                
                                </div>
                            </div>
                          
                            <div >
                                <div  className={show == "table" ? "box" : "hidden"}>
                                    <div className="box-header" >
                                    <div className={(permission.create) ? "" : "hidden"}>
                                      <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleCreateClick.bind(this)}>
                                          <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px"}}>
                                              <i className="fa fa fa-plus"></i>
                                              <span>&nbsp;Create</span>
                                              <span className="pull-right-container"></span>
                                          </a>
                                      </div>
                                      </div>
                                    </div>

                                    <div className="box-header">
                                        <div className="pull-left">
                                          <div className="input-group mb-3">
                                              <input type="text" id="search-key" style={{height : "34px"}}/>
                                              
                                                  <button className="btn btn-outline-secondary btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                                  <button className="btn btn-outline-secondary btn-reset" onClick={this.handleReset.bind(this,1)} type="button">Reset</button>
                                          </div>
                                      </div>

                                      <div className="pull-right">
                                            <select  id="sort-key"  style={{width : "200px",  height : "30px",marginLeft : "5px"}}>
                                                <option>-- Sort By --</option>
                                                <option>Dealer Code</option>
                                                <option>Dealer Name TH</option>
                                                <option>Dealer Name EN</option>
                                                <option>Zone</option>
                                                <option>Status</option>
                                            </select>
                                            <button className="btn btn-outline-primary btn-submit" onClick={this.handleSortClick.bind(this,1)} type="button">Submit</button>
                                      </div>
                                    </div>

                                    <div className="box-header">
                                   <span className={searchKey ? "" : "hidden"}>Seach "{searchKey}" ,please use reset button to refresh query.</span>
                                   <p>
                                   <span>Found <b>{pages.totalDocs}</b> record(s).</span>
                                   </p>
                                </div>

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ loading ? "hidden" : "box-body"} style={{overflowX : 'auto'}}>
                                        <div className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <style>{'th {text-align: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                No.
                                                                </th>
                                                             
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Dealer Code
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Dealer Name TH
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Dealer Name EN
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Zone
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Status
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                View
                                                                </th>
                                                                
                                                                <th  className={(permission.edit) ? "sorting" : "hidden"} tabIndex="3"  rowSpan="1" colSpan="1"  align="center" style={{width : '80px'}}>
                                                                    <span className='fa fa-edit'/> Edit
                                                                </th>
                                                                
                                                                <th   className={(permission.delete) ? "sorting" : "hidden"} tabIndex="4"  rowSpan="1" colSpan="1" style={{width : '80px'}}>
                                                                    <span className='fa fa-bitbucket'/> Delete
                                                                </th>

                                                                <th className={(blacklist.edit) ? "sorting" : "hidden"} tabIndex="4"  rowSpan="1" colSpan="1" style={{width : '80px'}}>
                                                                    Block
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           { contents }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage - 1)} className={currentPage > 10 ? "pageButton" : "hidden"}>
                                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage + 1)} className={currentPage < totalPages && pageList.length == 10 && totalPages > 10 ? "pageButton" : "hidden"}>
                                                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     {/* Box Body  */}
                                    
                                    </div>
                                </div>
                                <div className="box">
                                    <div className={show == "add" ? "box-header" : "hidden"} >
                                   
                                      <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleShowClick.bind(this,"table")}>
                                          <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px",backgroundColor : "black"}}>
                                         
                                                <span style={{marginLeft : '5px'}}>Back</span>
                                              <span className="pull-right-container"></span>
                                          </a>
                                      </div>
                                      
                                    </div>
                                    
                                    <div className="modal" id="modal-import" tabIndex="-1" role="dialog">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Import</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalAction.bind(this,"modal-import","close")} style={{marginTop : '-23px'}}>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                </div>
                                                <div className="modal-body">
                                                {/* <span className="file btn btn-lg btn-primary upload-div" > */}
                                                    
                                                    <input type="file" id="csvfile" name="file"/>
                                                    
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.modalAction.bind(this,"modal-import","close")}>Close</button>
                                                    <button type="button" className="btn btn-primary" onClick={this.handleFileUpload.bind(this)}>
                                                    <i className={upload ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>
                                                        Upload
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={show == "add" ? "" : "hidden"} id="modal-add-user" tabIndex="-1" role="dialog">
                                                <div>
                                                    <div className="modal-content">
                                                        <div className="modal-body col-md-12" >
                                                    
                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Dealer Code<span className="required-label">*</span></label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" maxLength="5"  className="form-control" id="dealerCode"  style={{width: '20%'}} />
                                                                    <input type="hidden" className="form-control" id="dealerId"  />
                                                                </div>
                                                            </div>

                                                          
                                                            <div className="row modal-box">
                                                                <div className="col-md-2" >
                                                                    <label>Dealer Name TH<span className="required-label">*</span></label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="dealerNameTH"   />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Dealer Name EN</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control " id="dealerNameEN"    />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Dealer Name Abb.</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control " id="dealerNameAbb"   />
                                                                </div>
                                                            </div>
                                                                
                                                        
                                                            <div className="row modal-box" style={{marginTop : "15px"}}>
                                                                <div className="col-md-2 ">
                                                                    <label>Juristic Id</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="juristicId"   />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Zone</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                <Select 
                                                                        value={zoneValue[0]}
                                                                        options={zone_options}
                                                                        onChange= {value => this.handleZoneChange(value)}
                                                                    />
                                                                  
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row modal-box" style={{marginTop : "15px"}}>
                                                                <div className="col-md-2 ">
                                                                    <label>Address</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="address"  />
                                                                </div>
                                                                <div className="col-md-2 ">
                                                                    <label>Road</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="road"/>
                                                                </div>
                                                            </div>

                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Kwang/Tumbol</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                     <input type="text" className="form-control" id="tumbol"  />
                                                                </div>
                                                                <div className="col-md-2 ">
                                                                    <label>Khet/Amphoe</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="amphoe" />
                                                                </div>
                                                            </div>
                                                            
                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Province</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                <Select 
                                                                        value={province_options.filter(option => parseInt(option.value) == parseInt(provinceId))[0]}
                                                                        options={province_options}
                                                                        onChange= {value => this.handleProvinceChange(value)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-2 ">
                                                                    <label>Postcode</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="postcode"   />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row modal-box">
                                                                 <div className="col-md-2 ">
                                                                    <label>Tel</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="tel"    />
                                                                </div>
                                                                <div className="col-md-2 ">
                                                                    <label>Fax</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="fax"   />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Status</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                        <select className="form-control" id="status" >
                                                                            <option>Activated</option>
                                                                            <option>Inactivated</option>
                                                                        </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-body col-md-12" >
                                                            <div className="row modal-box">
                                                                 <div className="col-md-2 ">
                                                                    <label>ชื่อธนาคาร</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="bankingName"    />
                                                                </div>
                                                                <div className="col-md-2 ">
                                                                    <label>หมายเลขบัญชี</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="bookingNumber"   />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>ชื่อบัญชี</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="accountHolder"   />
                                                                </div>
                                                                <div className="col-md-2 ">
                                                                    <label>สาขา</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="office"   />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="modal-body col-md-12" >
                                                            <div className="row modal-box">
                                                                 <div className="col-md-2 ">
                                                                    <label>เจ้าหน้าที่การเงิน</label>
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>ชื่อ-นามสกุล</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="contact_name"   />
                                                                </div>
                                                                <div className="col-md-2 ">
                                                                    <label>เบอร์โทรศัพท์</label>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <input type="text" className="form-control" id="contact_phonenumber"   />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-1">
                                                                    <label>หมายเหตุ</label>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <input type="text" className="form-control" id="contact_remark"   />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="modal-footer" style={{textAlign : 'center'}}>
                                                            {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleShowClick.bind(this,"table")} style={{width : '150px',marginBottom : '20px'}}>Close</button> */}
                                                            <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : '150px',marginBottom : '20px'}}>
                                                                <i className="fa fa-save"></i>
                                                                <span style={{marginLeft : '5px'}}>Save</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                    <div className={show == "view" ? "" : "hidden"}  id="modal-view" tabIndex="-1" role="dialog">
                                        <div>
                                            <div className="modal-content">
                                                <div className="modal-body col-md-12" >
                                            
                                                    <div className="row modal-box">
                                                        <div className="col-md-10">
                                                            <span className="view-text-head">Dealer Code :</span> <span className="view-text">{ dealer.dealerCode || "-" }</span>
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-10" >
                                                        <span className="view-text-head">Dealer Name TH : </span><span className="view-text">{ dealer.dealerNameTH  || "-"}</span>
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-10 ">
                                                            <span className="view-text-head">Dealer Name EN: </span><span className="view-text">{ dealer.dealerNameEN || "-" }</span>
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-10 ">
                                                            <span className="view-text-head">Dealer Name Abb.: </span><span className="view-text">{ dealer.dealerNameAbb || "-" }</span>
                                                        </div>
                                                        
                                                    </div>
                                                        
                                                
                                                    <div className="row modal-box">
                                                        <div className="col-md-10 ">
                                                            <span className="view-text-head">Zone : </span><span className="view-text">{ dealer.zone || "-" }</span>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                        <div className="row modal-box" style={{marginTop : "15px"}}>
                                                        <div className="col-md-10">
                                                            <span className="view-text-head">Juristic Id : </span><span className="view-text">{ dealer.juristicId || "-" }</span>
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="row modal-box" style={{marginTop : "15px"}}>
                                                        <div className="col-md-10">
                                                            <span className="view-text-head">Address : </span><span className="view-text">{ dealer.address || "-" }</span>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div className="row modal-box">
                                                            <div className="col-md-10">
                                                            <span className="view-text-head">Road : </span><span className="view-text">{ dealer.road || "-" }</span>
                                                        </div>
                                                        
                                                    </div>

                                                        <div className="row modal-box">
                                                        <div className="col-md-10">
                                                            <span className="view-text-head">Kwang/Tumbol : </span><span className="view-text">{ dealer.tumbol || "-" }</span>
                                                        </div>
                                                        
                                                    </div>


                                                        <div className="row modal-box">
                                                        <div className="col-md-10">
                                                            <span className="view-text-head">Khet/Amphoe : </span><span className="view-text">{ dealer.amphoe || "-" }</span>
                                                        </div>
                                                        
                                                    </div>

                                                        <div className="row modal-box">
                                                        <div className="col-md-10">
                                                            <span className="view-text-head">Province : </span><span className="view-text">{ dealer.province || "-"}</span>
                                                        </div>
                                                        
                                                    </div>

                                                        <div className="row modal-box">
                                                        <div className="col-md-10">
                                                            <span className="view-text-head">Postcode : </span><span className="view-text">{ dealer.postcode || "-" }</span>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div className="row modal-box">
                                                            <div className="col-md-10">
                                                            <span className="view-text-head">Tel : </span><span className="view-text">{ dealer.tel || "-" }</span>
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-10">
                                                            <span className="view-text-head">Fax : </span><span className="view-text">{ dealer.fax || "-" }</span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row modal-box">
                                                        <div className="col-md-10">
                                                            <span className="view-text-head">Status : </span><span className="view-text">{ dealer.status }</span>
                                                        </div>
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-4">
                                                            <span className="view-text-head">ชื่อธนาคาร : </span><span className="view-text">{ dealer.bankingInfo ? dealer.bankingInfo.bankingName || "-" : "-" }</span>
                                                        </div>
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-4">
                                                            <span className="view-text-head">หมายเลขบัญชี : </span><span className="view-text">{ dealer.bankingInfo ? dealer.bankingInfo.bookingNumber || "-" : "-" }</span>
                                                        </div>
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-4">
                                                            <span className="view-text-head">ชื่อบัญชี : </span><span className="view-text">{ dealer.bankingInfo ? dealer.bankingInfo.accountHolder || "-" : "-" }</span>
                                                        </div>
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-4">
                                                            <span className="view-text-head">สาขา : </span><span className="view-text">{ dealer.bankingInfo ? dealer.bankingInfo.office || "-" : "-" }</span>
                                                        </div>
                                                    </div>

                                                    {/* เจ้าหน้าที่การเงิน */}
                                                    <div className="row modal-box">
                                                        <div className="col-md-4">
                                                            <span className="view-text-head">เจ้าหน้าทีการเงิน : </span><span className="view-text">{ dealer.contact || "-"}</span>
                                                        </div>

                                                        <div className="col-md-8">
                                                            <span className="view-text-head">เบอร์โทรศัพท์ : </span><span className="view-text">{ dealer.phonenumber || "-"}</span>
                                                        </div>
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-4">
                                                            <span className="view-text-head">หมายเหตุ : </span><span className="view-text">{ dealer.remark || "-"}</span>
                                                        </div>
                                                    </div>
                                                    
                                                    {
                                                        dealer.blocked == true ? (
                                                            <div className="row modal-box">
                                                                <div className="col-md-4">
                                                                    <span className="view-text-head" style={{color:"red"}}>Blacklist Dealer : </span><span className="view-text" style={{color:"red"}}>จ่ายผ่าน TLT</span>
                                                                </div>
                                                            </div>
                                                        ):null
                                                    }
                                                </div>
                                                <div className="modal-footer" style={{textAlign : 'center'}}>
                                                    <button type="button" className="btn btn-outline-primary btn-submit" data-dismiss="modal" onClick={this.handleShowClick.bind(this,"table")} style={{width : '150px',marginBottom : '20px'}}>Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}