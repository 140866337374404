import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select'
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import loadgif from '../../../img/loading.gif'
var config = require('../../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.email){
            window.location = config.LOGIN
        }

        this.state = {
            permission : userLogin.permission.email,
            token : userLogin.token,
            loading:false,
            upload : false,
            sortBy : "createdAt",
            title : "",
            show : "table",
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 50,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            }
        };

    }

    componentDidMount() {
        this.handleFetch(1)
    }

    handleFetch(page){
        const {pages,sortBy, token} = this.state;

        if(page < 1 || page > pages.totalPages){
            return
        }
      
        this.setState({loading:true})
       
        const url = `${config.BASE_URL}/v1/contact?&page=${page}&sortBy=${sortBy}&perpage=${pages.perPage}`; 
        //console.log("Fetch")
        console.log(url)
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            console.log(result)
            if(result.status){
                
           
                setTimeout(() => {
                    this.setState({pages: result.data});
                    this.setState({loading:false , show : "table"})
                  },1000);
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });

    }


    handlePageClick(page, event){ 
       
            this.handleFetch(page)
        
    
    }

    

    handleCreateClick(value, event){
        document.getElementById("contactId").value = -1
        document.getElementById("department").value = "TTT"
        document.getElementById("name").value = ""
        document.getElementById("email").value = ""
        document.getElementById("tel").value = ""
      
        this.setState({show : "add",title : "Create"})
       
    
    }

    handleEditClick(id, event){
        const { pages } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
      
        document.getElementById("contactId").value = result[0]._id
        document.getElementById("department").value = result[0].department
        document.getElementById("name").value = result[0].name
        document.getElementById("email").value = result[0].email
        document.getElementById("tel").value = result[0].tel
        
        this.setState({show : "add" , title : "Update"})
        //this.modalAction("modal-add-user","open")
    }

    handleReset(){
        this.setState({ sortBy : 'createdAt',show : 'table'})
        this.handleFetch(1)
    }

    handleSaveClick(value, event){
        const {token} = this.state;
        
        var obj = {
            id : document.getElementById("contactId").value,
            department : document.getElementById("department").value,
            name : document.getElementById("name").value,
            email : document.getElementById("email").value,
            tel : document.getElementById("tel").value,
           
        }
        console.log(obj)
       
        var act = (obj.id == -1) ? 'create' : 'update'
        const url = `${config.BASE_URL}/v1/contact/${act}`; 
       
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(obj)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            //.log(result)
            if(result.status){
                
                this.handleReset()
            }
            else{
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleDeleteClick(id, event){
        const {token} = this.state;
        confirmAlert({
            title: 'Confirm',
            message: "Do you want to delete this user?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                   
                    const url = `${config.BASE_URL}/v1/contact/delete`;
                
                    this.setState({ loading: true })
            
                     fetch(url, {
                        method: 'post',
                         headers: {
                            'authorization': `basic ${token}`,
                             'Content-Type': 'application/x-www-form-urlencoded',
                         },
                         body: `id=${id}`
                     }).then(function(response) {
                         return response.json();
                        }).then((result) => {
                            //console.log(result)
                            if(result.status){
                                //setTimeout(() => {
                                    this.handleReset()
                               //   },500);
                               
                            }
                            else{
                                this.handleError(result.message)
                            }
                               
                        }).catch(error => {
                            this.handleError(error)
                        });
                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                }
              }
            ]
          })
    }


    onSetResult = (value, result) => {
        
        if (result["status"] == true){
            window.location.reload();
        }else{
            alert('Your request is not complete.'+JSON.stringify(result));
        }

        const { loading } = this.state;
        this.setState({ loading: !loading })
    }
    
    handleShowClick(show){
        this.setState({show : show});
    }

    modalAction(id,status){
        
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }


    onSetFileResult = (value, result) => {
        this.setState({ upload: false })
        if (result["status"] == true){

            //alert("Successfully");
            this.modalAction("modal-import","close")
            this.handleFetch(1)
        }else{
            this.handleError(result.message)
        }

    }

   
    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    render(){
        const { pages, loading,permission ,title,show} = this.state;
        console.log(permission, "<<<<< permission")
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            var contents = null
            if(pages.docs.length > 0){
                contents = []
                for(var index in pages.docs){
                    let item = pages.docs[index]
                    
                    contents.push(
                       
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{parseInt(pages.pageIndex) + parseInt(index)}</td> 
                        <td align="center">{item["department"]}</td>
                        <td align="left">{item["name"]}</td>
                        <td align="left">{item["email"]}</td>
                        <td align="center">{item["tel"]}</td>
                       
                        
                        <td align="center"className={(permission.edit) ? "" : "hidden"}>
                            
                                <button type="button" onClick={this.handleEditClick.bind(this, item["_id"])} className="btn btn-block btn-warning">Edit</button>

                        </td> 
                        <td align="center" className={(permission.delete) ? "" : "hidden"}>
                            
                                <button type="button" disable={loading.toString()} onClick={this.handleDeleteClick.bind(this, item["_id"])} className="btn btn-block btn-danger">Delete</button>
                            
                        </td>
                    </tr>
                    )
                    
                }
            }
        }
       
        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                               
                                <h3 style={{float : "left"}}>Contact Management</h3>

                                
                            </div>
                          
                            <div >
                                <div  className={show == "table" ? "box" : "hidden"}>
                                    <div className="box-header" >
                                    <div className={(permission.create) ? "" : "hidden"}>
                                      <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleCreateClick.bind(this)}>
                                          <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px"}}>
                                              <i className="fa fa fa-plus"></i>
                                              <span>&nbsp;Create</span>
                                              <span className="pull-right-container"></span>
                                          </a>
                                      </div>
                                      </div>
                                  </div>

                                <div className="box-header">
                                  
                                    
                                </div>

                                
                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ loading ? "hidden" : "box-body"} style={{overflowX : 'auto'}}>
                                        <div className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <style>{'th {text-align: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                No.
                                                                </th>
                                                             
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Department
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Name
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                               Email
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Tel.
                                                                </th>

                                                              
                                                                
                                                                <th  className={(permission.edit) ? "sorting" : "hidden"} tabIndex="3"  rowSpan="1" colSpan="1"  align="center" style={{width : '80px'}}>
                                                                    <span className='fa fa-edit'/> Edit
                                                                </th>
                                                                
                                                                <th   className={(permission.delete) ? "sorting" : "hidden"} tabIndex="4"  rowSpan="1" colSpan="1" style={{width : '80px'}}>
                                                                    <span className='fa fa-bitbucket'/> Delete
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           { contents }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage - 1)} className={currentPage > 10 ? "pageButton" : "hidden"}>
                                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage + 1)} className={currentPage < totalPages && pageList.length == 10 && totalPages > 10 ? "pageButton" : "hidden"}>
                                                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     {/* Box Body  */}
                                    
                                    </div>
                                </div>
                                <div className="box">
                                    <div className={show == "add" ? "box-header" : "hidden"} >
                                   
                                      <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleShowClick.bind(this,"table")}>
                                          <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px",backgroundColor : "black"}}>
                                          <i className="fa fa fa-remove"></i>
                                                <span style={{marginLeft : '5px'}}>Close</span>
                                              <span className="pull-right-container"></span>
                                          </a>
                                      </div>
                                      
                                    </div>
                                    
                                   

                                    <div className={show == "add" ? "" : "hidden"} id="modal-add-user" tabIndex="-1" role="dialog">
                                                <div>
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title"><b>{title}</b></h5>
                                                                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalAction.bind(this,"modal-add-user","close")} style={{marginTop : '-23px'}}>
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button> */}
                                                        </div>
                                                        <div className="modal-body col-md-12" >
                                                    
                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Department<span className="required-label">*</span></label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    {/* <input type="text" className="form-control" id="department"   /> */}
                                                                    <select className="form-control" id="department">
                                                                        <option>TTT</option>
                                                                        <option>TLT</option>
                                                                        <option>TMT</option>
                                                                    </select>
                                                                    <input type="hidden" className="form-control" id="contactId"  />
                                                                </div>
                                                            </div>

                                                          
                                                            <div className="row modal-box">
                                                                <div className="col-md-2" >
                                                                    <label>Name</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="name"   />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Email</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control " id="email"    />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Tel.</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control " id="tel"   />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="modal-footer" style={{textAlign : 'center'}}>
                                                            {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleShowClick.bind(this,"table")} style={{width : '150px',marginBottom : '20px'}}>Close</button> */}
                                                            <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : '150px',marginBottom : '20px'}}>
                                                                <i className="fa fa-save"></i>
                                                                <span style={{marginLeft : '5px'}}>Save</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                           
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}