import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, HashRouter, Switch, Link, Router, Route} from 'react-router-dom';

import registerServiceWorker from './registerServiceWorker';

// import { sessionService } from 'redux-react-session';

import Dashboard from "./components/dashboard"
import Root from "./components/login"
import Login from "./components/login/login"
import Permission from "./components/permission"
import User from "./components/user"
import Tmtuser from "./components/tmtuser"
import DealerUser from "./components/dealer_user"
import SeriesMaster from "./components/series_master"
import Series from "./components/series"
import Color from "./components/color"
import Price from "./components/price"
import Profile from "./components/profile"
import Agreement from "./components/agreement"
import EmailBuyer from "./components/email/buyer"
import EmailSeller from "./components/email/seller"
import EmailUsedcar from "./components/email/usedcar"
import EmailDelivery from "./components/email/delivery"
import EmailContact from "./components/email/contact"
import Pool from "./components/pool"
import DeadStock from "./components/deadstock"
import Wts from "./components/wts"
import Wtb from "./components/wtb"
import Transport from "./components/transport"
import Zone from "./components/zone"
import Dealer from "./components/dealer"
import PoolTransaction from "./components/pool_transaction"
import LocatorTransaction from "./components/locator_transaction"
import ColorManager from "./components/color_manager"
import MasterTLT from "./components/master_tlt"
import Supply from "./components/supply"

var routes = require('./components/share-components/Config');

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path={routes.DASHBOARD} component={Dashboard}/>
            <Route path={routes.PERMISSION} component={Permission}/>
            <Route path={routes.MASTER_TLT} component={MasterTLT}/>
            <Route path={routes.SUPPLY} component={Supply}/>
            <Route path={routes.COLORS_MANAGER} component={ColorManager}/>
            <Route path={routes.USER} component={User}/>
            <Route path={routes.TMTUSER} component={Tmtuser}/>
            <Route path={routes.PROFILE} component={Profile}/>
            <Route path={routes.DEALERUSER} component={DealerUser}/>
            <Route path={routes.SERIESMASTER} component={SeriesMaster}/>
            <Route path={routes.SERIES} component={Series}/>
            <Route path={routes.PRICE} component={Price}/>
            <Route path={routes.AGREEMENT} component={Agreement}/>
            <Route path={routes.EMAIL_BUYER}  component={EmailBuyer}/>
            <Route path={routes.EMAIL_SELLER}  component={EmailSeller}/>
            <Route path={routes.EMAIL_USEDCAR}  component={EmailUsedcar}/>
            <Route path={routes.EMAIL_DELIVERY}  component={EmailDelivery}/>
            <Route path={routes.EMAIL_CONTACT}  component={EmailContact}/>
            <Route path={routes.POOL} component={Pool}/>
            <Route path={routes.DEADSTOCK} component={DeadStock}/>
            <Route path={routes.WTS} component={Wts}/>
            <Route path={routes.WTB} component={Wtb}/>
            <Route path={routes.COLOR} component={Color}/>
            <Route path={routes.TRANSPORT} component={Transport}/>
            <Route path={routes.DEALER} component={Dealer}/>
            <Route path={routes.ZONE} component={Zone}/>
            <Route path={routes.POOL_TRANSACTION} component={PoolTransaction}/>
            <Route path={routes.LOCATOR_TRANSACTION} component={LocatorTransaction}/>
            <Route path={routes.LOGIN} component={Login}/>
            <Route path={routes.Root} component={Root}/>

        </Switch>
    </BrowserRouter>
    ,
    document.getElementById('root')
);
// ReactDOM.render(<LoginPage />, document.getElementById('root'));
registerServiceWorker();
