import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select'
import { CSVLink } from "react-csv";
import {Parser} from 'json2csv'
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import loadgif from '../../img/loading.gif'
import importPng from '../../img/import_csv.png'
import exportPng from '../../img/export_csv.png'
import excelImage from '../../img/excel.png'
var config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.tlt){
            window.location = config.LOGIN
        }

        this.state = {
            permission : userLogin.permission.tlt,
            token : userLogin.token,
            loading:false,
            upload : false,
            searchKey : "",
            sortBy : "createdAt",
            zones : [],
            provinces : [],
            show : "add",
            title : "",
            zoneId :1,
            csvData : [],
            export_file : "",
            zone : "",
            provinceId : 2,
            province : "",
            pageIndex: 1,
            editId:"",
            dealer :  {
                masterCode : "",
                nameTH : "",
                nameEN : "",
                zone : "",
                juristicId : "",
                address : "",
                tumbol : "",
                amphoe : "",
                province : "",
                postcode : "",
                road : "",
                tel : "",
                fax : "",
                status : ""
            },
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 50,
                hasPrevPage : false,
                hasNextPage : true,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            }
        };

    }

    componentDidMount() {
        
        const {pageIndex} = this.state;
        this.handleFetch(pageIndex)
        this.getZone()
        this.getProvince()
    }

    handleFetch(page){
        const {pages,sortBy, token} = this.state;

        if(page < 1 || page > pages.totalPages){
            return
        }
        this.setState({loading:true , searchKey : "", pageIndex: page})
       
        const url = `${config.BASE_URL}/v1/masterTLT?&page=${page}&sortBy=${sortBy}&perpage=${pages.perPage}&fixPage=true`; 
        let configs = {
            headers: {
                'authorization': `basic ${token}`,
            }
        }
        fetch(url, configs).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                let item = result.data.docs[0] || {}
                console.log(item)
                this.setState({pages: result.data});
                setTimeout(() => {
                    this.handleEditClick(item._id, undefined)
                  },500);
            }
            else{
                this.handleError(result.message)
            }
        }).catch(error => {
            this.handleError(error)
        });

    }

    getZone(){
        const { token } = this.state;
        this.setState({loading:true , searchKey : ""})
       
        const url = `${config.BASE_URL}/v1/zone`; 
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({zones : result.data})
            }
        }).catch(error => {
            this.handleError(error)
        });

    }

    getProvince(){
        const { token } = this.state;
        this.setState({loading:true , searchKey : ""})
       
        const url = `${config.BASE_URL}/v1/province`; 
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            
            if(result.status){
                this.setState({provinces : result.data});
           
            }
           

        }).catch(error => {
            this.handleError(error)
        });
    }
    
    handleEditClick(id, event){
        const { pages,zones,provinces } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })

        console.log(result, " <------- result")
        this.setState({editId: id})
        try {
            document.getElementById("masterId").value = (result[0]._id) ? result[0]._id.trim() : ""
            document.getElementById("masterCode").value = (result[0].masterCode) ? result[0].masterCode.trim() : ""
            document.getElementById("nameTH").value = (result[0].nameTH) ? result[0].nameTH.trim() : ""
            document.getElementById("nameEN").value =  (result[0].nameEN) ? result[0].nameEN.trim(): ""
            document.getElementById("nameAbb").value = (result[0].nameAbb) ? result[0].nameAbb.trim() : ""
            //document.getElementById("zone").value = result[0].zone
            document.getElementById("juristicId").value = (result[0].juristicId) ? result[0].juristicId.trim() : ""
            document.getElementById("address").value = (result[0].address) ? result[0].address.trim() : ""
            document.getElementById("tumbol").value = (result[0].tumbol) ?result[0].tumbol.trim() : ""
            document.getElementById("amphoe").value = (result[0].amphoe) ? result[0].amphoe.trim() : ""
            //document.getElementById("province").value = result[0].province
            document.getElementById("postcode").value = (result[0].postcode) ? result[0].postcode.trim() : ""
            document.getElementById("road").value = (result[0].road) ? result[0].road.trim() : ""
            document.getElementById("tel").value = (result[0].tel) ? result[0].tel.trim() : ""
            document.getElementById("fax").value = (result[0].fax) ? result[0].fax.trim() : ""
            document.getElementById("status").value = result[0].status || "Active"

            document.getElementById("contact_name").value = result[0].contact || ""
            document.getElementById("contact_phonenumber").value = result[0].phonenumber || ""
            document.getElementById("contact_remark").value = result[0].remark || ""

            let bankingInfo = result[0]["bankingInfo"] || {}

            document.getElementById("bankingName").value = bankingInfo.bankingName || ""
            document.getElementById("bookingNumber").value = bankingInfo.bookingNumber || ""
            document.getElementById("accountHolder").value = bankingInfo.accountHolder || ""
            document.getElementById("office").value = bankingInfo.office || ""

            var zoneId = result[0].zoneId
            var zone = result[0].zone
            if( result[0].zoneId === undefined){
                var allZone = zones.filter(obj => {
                    return obj.description == zone
                })
                zone = allZone[0].zone
                zoneId = allZone[0].zoneId || 0
            }
            var provinceId = result[0].provinceId
            if( result[0].provinceId === undefined){
                var province = provinces.filter(obj => {
                    return obj.name == result[0].province
                })
                provinceId = province[0].provinceId
            }

            this.setState({zoneId : zoneId , zone: zone, provinceId : provinceId, show : "add" , title : "Update"})
        } catch (error) {
            
        }
    }

    handleZoneChange(val){
        this.setState({zoneId : val.value ,zone : val.label})
    }

    handleProvinceChange(val){
        this.setState({provinceId : val.value , province : val.label})
    }
    
    codeGenerate() {
        var number = Math.floor(100000 + Math.random() * 900000);   
        number = String(number);
        number = number.substring(0,4);
        return number
    }

    handleSaveClick(value, event){
        const {token ,zoneId,zone,zones,provinceId,province,provinces} = this.state;
        var zoneName = zone
        var provinceName = province
        if(!zoneName){
            var z = zones.filter(obj => {
                return obj.zoneId == zoneId
            })
            zoneName = z[0].description
        }
       
        if( !provinceName){
            var p = provinces.filter(obj => {
                return obj.provinceId == provinceId
            })
            provinceName = p[0].name
        }
        
        var obj = {
            id : document.getElementById("masterId").value,
            masterCode : document.getElementById("masterCode").value,
            nameTH : document.getElementById("nameTH").value || " ",
            nameEN : document.getElementById("nameEN").value || " ",
            nameAbb : document.getElementById("nameAbb").value || " ",
            zoneId : zoneId,
            zone : zoneName,
            juristicId : document.getElementById("juristicId").value  || " ",
            address : document.getElementById("address").value  || " ",
            tumbol : document.getElementById("tumbol").value  || " ",
            amphoe : document.getElementById("amphoe").value  || " ",
            provinceId : provinceId,
            province : provinceName,
            postcode : document.getElementById("postcode").value  || " ",
            road : document.getElementById("road").value  || " ",
            tel :  document.getElementById("tel").value  || " ", 
            fax : document.getElementById("fax").value  || " ",
            status : document.getElementById("status").value,
            contact: document.getElementById('contact_name').value,
            phonenumber: document.getElementById('contact_phonenumber').value,
            remark: document.getElementById('contact_remark').value
        }

        let bankingName = document.getElementById("bankingName").value
        if (bankingName) obj["bankingName"] = bankingName

        let bookingNumber = document.getElementById("bookingNumber").value
        if (bookingNumber) obj["bookingNumber"] = bookingNumber

        let accountHolder = document.getElementById("accountHolder").value
        if (accountHolder) obj["accountHolder"] = accountHolder

        let office = document.getElementById("office").value
        if (office) obj["office"] = office
       
        var act = (obj.id == -1) ? 'create' : 'update'
        const url = `${config.BASE_URL}/v1/masterTLT/${act}`; 
        
        if(document.getElementById("nameTH").value == ""){
            alert("Please fill require field")
            return
        }

        var emptyFiled = false;
        for (var k in obj) {
             if(obj[k] == "" || obj[k] == " "){
                emptyFiled = true
             }
        }

        if(emptyFiled){
            confirmAlert({
                title: 'Confirm',
                message: "Found empty field. Are you sure to save?",
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                        this.handleSave(url,token,obj)
                    }
                  },
                  {
                    label: 'No',
                    onClick: () => {
                        
                    }
                  }
                ]
            })
        } else {
            this.handleSave(url, token, obj)
        }
       
    }

    handleSave(url, token, user){
        const {editId, pageIndex, pages} = this.state
        
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(user)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                setTimeout(() => {
                    const {pageIndex} = this.state;
                    this.handleFetch(pageIndex)
                },500);
            }
            else{
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.handleError(error)
        });
    }
    
    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    render(){
        const { loading , searchKey, upload, zones, provinces, dealer, permission, show, title, zoneId, provinceId, zone, csvData, export_file } = this.state;
        
        var zone_options = zones.map(result => {
            return { value: result.zoneId, label: result.description }
        })
        
        var zoneValue = zone_options.filter(option => parseInt(option.value) == parseInt(zoneId) || option.label == zone)
        var province_options = provinces.map(province => {
            return { value: province.provinceId, label: province.name }
        })
        
        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>Master TLT</h3>
                            </div>
                            <div >
                                <div className="box">
                                    <div className={show == "add" ? "" : "hidden"} id="modal-add-user" tabIndex="-1" role="dialog">
                                        <div>
                                            <div className="modal-content">
                                                <div className="modal-body col-md-12" >
                                            
                                                    <div className="row modal-box">
                                                        <div className="col-md-2" >
                                                            <label>TLT Name TH<span className="required-label">*</span></label>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" id="nameTH" />
                                                            <input type="hidden" className="form-control" id="masterId"/>
                                                            <input type="hidden" className="form-control" id="masterCode"/>
                                                        </div>
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-2 ">
                                                            <label>TLT Name EN</label>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control " id="nameEN"    />
                                                        </div>
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-2 ">
                                                            <label>Dealer Name Abb.</label>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control " id="nameAbb"   />
                                                        </div>
                                                    </div>
                                                        
                                                
                                                    <div className="row modal-box" style={{marginTop : "15px"}}>
                                                        <div className="col-md-2 ">
                                                            <label>Juristic Id</label>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <input type="text" className="form-control" id="juristicId"   />
                                                        </div>
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-2 ">
                                                            <label>Zone</label>
                                                        </div>
                                                        <div className="col-md-10">
                                                        <Select 
                                                                value={zoneValue[0]}
                                                                options={zone_options}
                                                                onChange= {value => this.handleZoneChange(value)}
                                                            />
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row modal-box" style={{marginTop : "15px"}}>
                                                        <div className="col-md-2 ">
                                                            <label>Address</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input type="text" className="form-control" id="address"  />
                                                        </div>
                                                        <div className="col-md-2 ">
                                                            <label>Road</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input type="text" className="form-control" id="road"/>
                                                        </div>
                                                    </div>

                                                        <div className="row modal-box">
                                                        <div className="col-md-2 ">
                                                            <label>Kwang/Tumbol</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                                <input type="text" className="form-control" id="tumbol"  />
                                                        </div>
                                                        <div className="col-md-2 ">
                                                            <label>Khet/Amphoe</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input type="text" className="form-control" id="amphoe" />
                                                        </div>
                                                    </div>
                                                    
                                                        <div className="row modal-box">
                                                        <div className="col-md-2 ">
                                                            <label>Province</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                        <Select 
                                                                value={province_options.filter(option => parseInt(option.value) == parseInt(provinceId))[0]}
                                                                options={province_options}
                                                                onChange= {value => this.handleProvinceChange(value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-2 ">
                                                            <label>Postcode</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input type="text" className="form-control" id="postcode"   />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row modal-box">
                                                            <div className="col-md-2 ">
                                                            <label>Tel</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input type="text" className="form-control" id="tel"    />
                                                        </div>
                                                        <div className="col-md-2 ">
                                                            <label>Fax</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input type="text" className="form-control" id="fax"   />
                                                        </div>
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-2 ">
                                                            <label>Status</label>
                                                        </div>
                                                        <div className="col-md-10">
                                                                <select className="form-control" id="status" >
                                                                    <option>Activated</option>
                                                                    <option>Inactivated</option>
                                                                </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-body col-md-12" >
                                                    <div className="row modal-box">
                                                            <div className="col-md-2 ">
                                                            <label>ชื่อธนาคาร</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input type="text" className="form-control" id="bankingName"    />
                                                        </div>
                                                        <div className="col-md-2 ">
                                                            <label>หมายเลขบัญชี</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input type="text" className="form-control" id="bookingNumber"   />
                                                        </div>
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-2 ">
                                                            <label>ชื่อบัญชี</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input type="text" className="form-control" id="accountHolder"   />
                                                        </div>
                                                        <div className="col-md-2 ">
                                                            <label>สาขา</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input type="text" className="form-control" id="office"   />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal-body col-md-12" >
                                                    <div className="row modal-box">
                                                            <div className="col-md-2 ">
                                                            <label>เจ้าหน้าที่การเงิน</label>
                                                        </div>
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-2 ">
                                                            <label>ชื่อ-นามสกุล</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input type="text" className="form-control" id="contact_name"   />
                                                        </div>
                                                        <div className="col-md-2 ">
                                                            <label>เบอร์โทรศัพท์</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input type="text" className="form-control" id="contact_phonenumber"   />
                                                        </div>
                                                    </div>

                                                    <div className="row modal-box">
                                                        <div className="col-md-1">
                                                            <label>หมายเหตุ</label>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" id="contact_remark"   />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal-footer" style={{textAlign : 'center'}}>
                                                    <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : '150px',marginBottom : '20px'}}>
                                                        <i className="fa fa-save"></i>
                                                        <span style={{marginLeft : '5px'}}>Save</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}