import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import dateFormat from 'dateformat';
import DatePicker from "react-datepicker";
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import loadgif from '../../img/loading.gif'
import importPng from '../../img/import_csv.png'
import exportPng from '../../img/export_csv.png'
var config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.poolData){
            window.location = config.LOGIN
        }

        this.state = {
            permission : userLogin.permission.poolData,
            token : userLogin.token,
            loading:false,
            upload : false,
            roleId : "0",
            searchKey : "",
            sortBy : "createdAt",
            show : "table",
            title : "",
            perPage : 50,
            pageIndex: 1,
            editId:"",
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 0,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            },
          calendarStart : false,
          calendarStop : false,
          startDate : new Date(),
          stopDate : new Date()
        };

    }
    
    
    componentDidMount() {
        const {pageIndex} = this.state;
        this.handleFetch(pageIndex)
    }

    handleFetch(page){
        const {pages,sortBy, token ,perPage} = this.state;

        if(page < 1 || page > pages.totalPages){
            return
        }
        document.getElementById('search-key').value = ""
        this.setState({loading:true , searchKey : ""})
        
        const url = `${config.BASE_URL}/v1/price?page=${page}&sortBy=${sortBy}&perPage=${perPage}`; 
        //console.log("Fetch")
       // console.log(url)
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            console.log(result)
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false ,show : "table"})
                },500);
            }
            else{
                this.handleError(result.message)
            }
           
            
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleSearchClick(page){
        var key = document.getElementById("search-key").value;
        if(key == ""){
            return
        }

        this.setState({loading:true , searchKey : key})

        setTimeout(() => {
            this.handleSearch(1)
          },500);
        
    }

    handleSearch(page){
        const {pages,searchKey,sortBy, roleId,token,perPage} = this.state;
        const url = `${config.BASE_URL}/v1/price/search`; 
        this.setState({loading:true })

        var params ={
            "key" : searchKey ,
            "roleId" : roleId ,
            "page" : page ,
            "perPage" : perPage,
            "sortBy" : sortBy
        }
        this.setState({pageIndex: page})
      //  console.log("Search")
       console.log(params)
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false ,show : "table"})
                },500);
            }
            else{
                this.handleError(result.message)
            }
           
            
        }).catch(error => {
            this.handleError(error)
        });
    }

    handlePageClick(page, event){ 
        const {searchKey} = this.state;
       // console.log("search " + searchKey)
        if(searchKey){
            
            this.handleSearch(page)
        }
        else{
            this.handleFetch(page)
        }
    
    }

    handleSortClick(page, event){ 
        const {searchKey} = this.state;
        var sortKey = document.getElementById('sort-key').value
        var sortOptions={
            "Series" : "series",
            "Model"    : "model",
            "SFX"  : "sfx",
            "Color Code"  : "colorCode",
            "Color Type"  : "colorType",
            "Color Name"  : "colorName",
            "Price" : 'price'
        }
        
        var sortBy = sortOptions[sortKey]
        this.setState({sortBy:sortBy});
    
        setTimeout(() => {
            if(searchKey){
                this.handleSearch(page)
            }
            else{
                this.handleFetch(page)
            }
          },500);
        
        
    }

    handleCreateClick(value, event){

        document.getElementById("priceId").value = -1
        document.getElementById("serieName").value = ""
        document.getElementById("model").value =""
        document.getElementById("sfx").value =""
        document.getElementById("colorCode").value =""
        document.getElementById("colorType").value =""
        document.getElementById("colorName").value =""
        document.getElementById("priceValue").value = ""
        document.getElementById("airPrice").value =""
        
        this.setState({startDate :  Date.now(),stopDate : ""})
        this.setState({show : 'add',title : "Create"})
    
    }

    handleEditClick(id, event){
        const { pages } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
        
        document.getElementById("priceId").value = result[0]._id
        document.getElementById("serieName").value = result[0].series
        document.getElementById("model").value = result[0].model
        document.getElementById("sfx").value = result[0].sfx
        document.getElementById("colorCode").value = result[0].colorCode
        document.getElementById("colorType").value = result[0].colorType
        document.getElementById("colorName").value = result[0].colorName
        document.getElementById("priceValue").value =  result[0].price
        document.getElementById("airPrice").value = result[0].airPrice
        var startDate = ""
        var stopDate = ""

        if(result[0].fromDate && result[0].fromDate.trim()){
            startDate = new Date(result[0].fromDate)
        }
        if(result[0].toDate && result[0].toDate.trim()){
            stopDate = new Date(result[0].toDate)
        }
        this.setState({startDate :  startDate ,stopDate : stopDate, editId: id})
        this.setState({show : 'add',title : "Update"})
    }

    handleSaveClick(value, event){
        const {token ,startDate,stopDate, pageIndex, pages, editId} = this.state;
        var fromDate = ""
        var toDate = ""
        if(startDate){
            fromDate = dateFormat(startDate,'yyyy-mm-dd')
        }
        if(stopDate){
            toDate = dateFormat(stopDate,'yyyy-mm-dd')
        }
        var price = {
            id : document.getElementById("priceId").value,
            series : document.getElementById("serieName").value,
            model :document.getElementById("model").value,
            sfx : document.getElementById("sfx").value,
            colorCode : document.getElementById("colorCode").value,
            colorType : document.getElementById("colorType").value,
            colorName : document.getElementById("colorName").value,
            price : document.getElementById("priceValue").value,
            airPrice : document.getElementById("airPrice").value,
            fromDate :fromDate,
            toDate : toDate
          
        }
        console.log(price)
        if(price.toDate != ""){
            price.toDateTime = stopDate
        }
        console.log(price)
        if(price.series == "" || price.model == "" || price.sfx == "" || price.colorCode == "" || price.colorType == "" || price.colorName == "" || price.price == ""){
            alert("Please fill required filed.")
            return;
        }
       
        var act = (price.id == -1) ? 'create' : 'update'
        const url = `${config.BASE_URL}/v1/price/${act}`; 
        
        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(price)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            //console.log(result)
            if(result.status){
                //this.modalAction("modal-add-price","close")
                //this.handleFetch(pageIndex)

                let index = pages.docs.findIndex(item => {
                    return item._id == editId
                })
                
                if (index != -1) {
                    var updated = pages.docs[index]
                    updated.id = price.id
                    updated.series = price.series
                    updated.model = price.model
                    updated.sfx = price.sfx
                    updated.colorCode = price.colorCode
                    updated.colorType = price.colorType
                    updated.colorName = price.colorName
                    updated.price = price.price
                    updated.airPrice = price.airPrice
                    updated.fromDate = price.fromDate
                    updated.toDate = price.toDate

                    pages.docs[index] = updated

                }
                
                this.setState({ pages: pages, loading:false ,show : "table"});
            }
            else{
                this.handleError(result.message)
            }
               
        }).catch(error => {
            this.handleError(error)
        });
    }

    

    handleDeleteClick(id, event){
        const {token, pageIndex} = this.state;
        confirmAlert({
            title: 'Confirm',
            message: "Do you want to delete this user?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                   
                    const url = `${config.BASE_URL}/v1/price/delete`;
                
                    this.setState({ loading: true })
            
                     fetch(url, {
                        method: 'post',
                         headers: {
                            'authorization': `basic ${token}`,
                             'Content-Type': 'application/x-www-form-urlencoded',
                         },
                         body: `id=${id}`
                     }).then(function(response) {
                         return response.json();
                        }).then((result) => {
                            //console.log(result)
                            if(result.status){
                                this.handleFetch(pageIndex)
                            }
                            else{
                                this.handleError(result.message)
                            }
                               
                        }).catch(error => {
                            this.handleError(error)
                        });
                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                }
              }
            ]
          })
    }


    onSetResult = (value, result) => {
        
        if (result["status"] == true){
            window.location.reload();
        }else{
            alert('Your request is not complete pricename or email is already.'+JSON.stringify(result));
        }

        const { loading } = this.state;
        this.setState({ loading: !loading })
    }
    
    modalAction(id,status){
        
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }

    handleDownloadClick() {
       
        setTimeout(() => {
          const response = {
            file: `${config.BASE_URL}/v1/csv/prices.csv`
          };
         
          window.open(response.file);
         
        }, 100);
    }


    handleFileUpload(value){
        const {token} = this.state;
        this.setState({ upload: true })
        const browserFiles = document.querySelector('input[type="file"]')
      
        const url = `${config.BASE_URL}/v1/price/upload`;
        //console.log(url)
        
        var formData = new FormData()
       
        for (const file of browserFiles.files) {
           // console.log(file)
            formData.append('file',file,file.name)
            
        }
        
        
        fetch(url, {
            method: 'POST',
            headers: {
                'authorization': `basic ${token}`,
             },
            body: formData
        }).then(function(response) {
            return response.json();
        }).then(result => this.onSetFileResult(value, result)).catch(error => {
            this.setState({ loading: false ,upload : false});
            this.handleError(error)
        });
        
    }

    onSetFileResult = (value, result) => {
        const {pageIndex} = this.state
        this.setState({ upload: false })
        if (result["status"] == true){

            //alert("Successfully");
            this.modalAction("modal-import","close")
            this.handleFetch(pageIndex)
        }else{
            this.handleError(result.message)
        }

    }

    onStartDateChange(date){
        this.setState({startDate : date});
    }

    onStopDateChange(date){
        this.setState({stopDate : date});
    }

    handleShowClick(show){
        this.setState({show : show});
    }

    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    render(){
        const { pages, loading ,searchKey,startDate,stopDate,upload,permission,show,title, pageIndex} = this.state;
       
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            
            if (currentPage == undefined) {
                currentPage = pageIndex
            }

            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            var contents = null
            if(pages.docs.length > 0){
                contents = []
                for(var index in pages.docs){
                    let item = pages.docs[index]
                    let startDate = ""
                    let stopDate = ""
                    var expire = false;

                    if(item["fromDate"] && item["fromDate"].trim()){
                        startDate = dateFormat(item["fromDate"],'GMT:dd-mmm-yyyy')
                    }

                    if(item["toDate"] && item["toDate"].trim()){
                        stopDate = dateFormat(item["toDate"],'GMT:dd-mmm-yyyy')
                    }
                    
                    contents.push(
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{ parseInt(pages.pageIndex) + parseInt(index)}</td>
                        <td align="center">{item["series"]}</td>
                        <td align="center">{item["model"]}</td>
                        <td align="center">{item["sfx"]}</td>
                        <td align="center">{item["colorCode"]}</td>
                        <td align="center">{item["colorType"]}</td>
                        <td align="center">{item["colorName"]}</td>
                        <td align="center">{item["price"]}</td>
                        <td align="center">{item["airPrice"]}</td>
                        <td align="center">{startDate}</td>
                        <td align="center">{stopDate}</td>
    
                        <td align="center" className={(permission.edit) ? "" : "hidden"}>
                          
                            <button type="button" onClick={this.handleEditClick.bind(this, item["_id"])} className="btn btn-block btn-warning">Edit</button>
                            
                        </td> 
                        <td align="center" className={(permission.delete) ? "" : "hidden"}>
                            
                            <button type="button" disable={loading.toString()} onClick={this.handleDeleteClick.bind(this, item["_id"])} className="btn btn-block btn-danger">Delete</button>
                            
                        </td>
                    </tr>
                    )
                }
            }
    
        }
        
        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>Price Management</h3>
                                <div className={(permission.import) ? "" : "hidden"}>
                                <span style={{ float : "right",cursor: "pointer"}}>  
                                <a href={`${config.BASE_URL}/v1/csv/prices.csv`}>
                                    <button type="button"  className="btn btn-success" style={{ width : "150px",marginTop : "16px"}}>
                                      <img src={exportPng} style={{width : '25px'}}/>
                                        Export Form
                                    </button> 
                                </a>
                                </span>
                                <span style={{ float : "right",cursor: "pointer"}}>
                                    <button type="button"  className="btn btn-primary" style={{ width : "150px",marginTop : "16px",marginRight : "10px"}} onClick={this.modalAction.bind(this,"modal-import","open")}>
                                    <img src={importPng} style={{width : '25px'}}/>
                                        Import
                                    </button> 
                                </span>
                                </div>
                            </div>
                          
                            <div className={show== "table" ? "box" : "hidden"}>
                                <div className="box-header">
                                    <div className={(permission.create) ? "" : "hidden"}>
                                      <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleCreateClick.bind(this)}>
                                          <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px"}}>
                                              <i className="fa fa fa-plus"></i>
                                              <span>&nbsp;Create</span>
                                              <span className="pull-right-container"></span>
                                          </a>
                                      </div>
                                      </div>
                                  </div>

                                  <div className="box-header">
                                  <div className="pull-left">
                                          <div className="input-group mb-3">
                                              <input type="text" id="search-key" style={{height : "34px"}}/>
                                              
                                                  <button className="btn btn-outline-secondary btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                                  <button className="btn btn-outline-secondary btn-reset" onClick={this.handleFetch.bind(this,1)} type="button">Reset</button>
                                          </div>
                                      </div>

                                    <div className="pull-right">
                                          <label>Sort By</label>
                                              <select  id="sort-key" style={{width : "200px",  height : "30px",marginLeft : "5px"}}>
                                                <option>Series</option>
                                                <option>Model</option>
                                                <option>SFX</option>

                                                <option >Color Code</option>
                                                <option >Color Type</option>
                                                <option>Color Name</option>
                                                <option>Price</option>
                                              </select>
                                              <button className="btn btn-outline-primary btn-submit" onClick={this.handleSortClick.bind(this,1)} type="button">Filter</button>
                                    </div>
                                    
                                </div>
                                <div className="box-header">
                                   <span className={searchKey ? "" : "hidden"}>Seach "{searchKey}" ,please use reset button to refresh query.</span>
                                   <p>
                                   <span>Found <b>{pages.totalDocs}</b> record(s).</span>
                                   </p>
                                </div>

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ loading ? "hidden" : "box-body"} style={{overflowX : 'auto'}}>
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <style>{'th {text-align: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                No.
                                                                </th>
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Series
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Model
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                SFX
                                                                </th>

                                                         
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Color Code
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Color Type
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Color Name
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Price
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Air Price
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                From Date
                                                                </th>

                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                To Date
                                                                </th>

                                                            

                                                                <th  className={(permission.edit) ? "sorting" : "hidden"}  tabIndex="3"  rowSpan="1" colSpan="1" aria-label="Engine version: activate to sort column ascending" align="center" style={{width : '80px'}}>
                                                                    <span className='fa fa-edit'/> Edit
                                                                </th>
                                                                
                                                                <th  className={(permission.delete) ? "sorting" : "hidden"}   tabIndex="4"  rowSpan="1" colSpan="1" aria-label="CSS grade: activate to sort column ascending" style={{width : '80px'}}>
                                                                    <span className='fa fa-remove'/> Delete
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           { contents }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                            
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage - 1)} className={currentPage > 10 ? "pageButton" : "hidden"}>
                                                            <i className="fa fa-backward" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage + 1)} className={currentPage < totalPages && pageList.length == 10 && totalPages > 10 ? "pageButton" : "hidden"}>
                                                            <i className="fa fa-forward" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                          
                                        </div>
                                    </div>
                                </div>
                            
                            <div className="modal" id="modal-import" tabIndex="-1" role="dialog">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Import</h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalAction.bind(this,"modal-import","close")} style={{marginTop : '-23px'}}>
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                        </div>
                                                        <div className="modal-body">
                                                       {/* <span className="file btn btn-lg btn-primary upload-div" > */}
							                               
							                                <input type="file" id="csv_file" name="file"/>
                                                           
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.modalAction.bind(this,"modal-import","close")}>Close</button>
                                                            <button type="button" className="btn btn-primary" onClick={this.handleFileUpload.bind(this)}>
                                                            <i className={upload ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>
                                                                Upload
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                            <div  className={show== "add" ? "box" : "hidden"}>
                                <div className= "box-header" >
                                   
                                   <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleShowClick.bind(this,"table")}>
                                       <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px",backgroundColor : "black"}}>
                                       <i className="fa fa fa-remove"></i>
                                             <span style={{marginLeft : '5px'}}>Close</span>
                                           <span className="pull-right-container"></span>
                                       </a>
                                   </div>
                                   
                                 </div>

                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title"><b>{title}</b></h5>
                                    </div>
                                    <div className="modal-body col-md-12" style={{height : "auto"}}>
                                                   
    
                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Series</label><span className="require-label">*</span>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="serieName"    />
                                                                    <input type="hidden" className="form-control" id="priceId"    />
                                                                </div>
                                                            </div>

                                                            
                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Model<span className="require-label">*</span></label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="model"   />
                                                                </div>
                                                                
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Sfx<span className="require-label">*</span></label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                        <input type="text" className="form-control" id="sfx"   />
                                                                </div>
                                                            </div>

                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Color Code<span className="require-label">*</span></label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="colorCode"   />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Color Type<span className="require-label">*</span></label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="colorType" />
                                                                </div>
                                                                
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label style={{fontSize : '13px'}}>Color Name<span className="require-label">*</span></label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="text" className="form-control" id="colorName" />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Price<span className="require-label">*</span></label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="number" className="form-control" id="priceValue"  />
                                                                </div>
                                                            </div>

                                                            <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Air Price</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <input type="number" className="form-control" id="airPrice"  />
                                                                </div>
                                                            </div>

                                                             <div className="row modal-box">
                                                                <div className="col-md-2 ">
                                                                    <label>Start Date</label>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <DatePicker
                                                                        selected={startDate}
                                                                        onChange={this.onStartDateChange.bind(this)}
                                                                        dateFormat = "dd-MM-yyyy"
                                                                    />
                                                                    
                                                                </div>
                                                            </div>

                                                             <div className="row modal-box">
                                                                 <div className="col-md-2 ">
                                                                    <label>End Date</label>
                                                                </div>
                                                                <div  className="col-md-10">
                                                                <DatePicker
                                                                    selected={stopDate}
                                                                    onChange={this.onStopDateChange.bind(this)}
                                                                    dateFormat = "dd-MM-yyyy"
                                                                />
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                        <div className="modal-footer" style={{textAlign : 'center'}}>
                                        {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleShowClick.bind(this,"table")} style={{width : '150px',marginBottom : '20px'}}>Close</button> */}
                                        <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : '150px',marginBottom : '20px',marginTop : '30px'}}>
                                            <i className="fa fa-save"></i>
                                            <span style={{marginLeft : '5px'}}>Save</span>
                                        </button>
                                    </div>
                                </div>
                            
                            </div>             

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}