import React, {Component} from 'react';
import { Redirect } from 'react-router-dom'

var Config = require('./Config');

export default class SideBar extends Component {
    
    constructor(props) {
        super(props);
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        var permission = userLogin.permission
        
        var menu = localStorage.getItem('menu')
        this.state = {
            roleId : 0,
            menu : menu,
            permission:permission
        }

    }
    

    handleClick = (e) => {
        e.preventDefault();
        this.setState({
            menu: e.target.id
        })
    }
    
    handleActive(id){
    
        this.setState({
            menu: id
        })
    }
    

    render(){
        const { roleId,menu ,permission } = this.state
        let dashboard = (
            <li className={menu === "dashboard" ? "active" : ""} key={menu+Math.random().toString(36).substring(7)}>
                <a href={Config.DASHBOARD}  className='a'>
                    <i className="fa  fa-tachometer"></i>
                    <span>Dashboard</span>
                    <span className="pull-right-container">
                    </span>
                </a>
            </li>
        )
        
        let systemAdmin = (
            <li id="main_user" className ={ menu == "permission" || menu == "user" ? "treeview active":"treeview"} key={Math.random().toString(36).substring(7)} >
                <a href="#">
                    <i className="fa fa-user-circle-o"></i>
                    <span>System Admin</span>
                    <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                    </span>
                </a>
                <ul className="treeview-menu">
                    <li className={menu == "permission" ? "active" : ""} key={Math.random().toString(36).substring(7)}>
                        <a href={Config.PERMISSION}  className='a' >
                            <i className="fa fa-user-circle-o"></i> Permission
                        </a>
                    </li>
                    <li className={menu == "user" ? "active" : ""} key={Math.random().toString(36).substring(7)}>
                        <a href={Config.USER}  className='a' >
                            <i className="fa fa-user-circle-o"></i> User
                        </a>
                    </li>
                 
                </ul>
            </li>
        )

        let dealer = (
            <li className={menu === "dealer" ? "active" : ""} key={menu+Math.random().toString(36).substring(7)}>
                 <a href={Config.DEALER}  className='a' >
                    <i className="fa fa-address-card"></i>
                    <span>Dealer Data</span>
                    <span className="pull-right-container">
                    </span>
                </a>
            </li>
        )

        let serieData = (
            <li id="main_user" className ={menu == "series_master" || menu == "series" || menu === "color" ? "treeview active":"treeview"} key={Math.random().toString(36).substring(7)} >
                <a href="#">
                    <i className="fa fa-car"></i>
                    <span>Series Data</span>
                    <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                    </span>
                </a>
                <ul className="treeview-menu">
                    <li className={menu === "series_master" ? "active" : ""} key={menu+Math.random().toString(36).substring(7)}>
                        <a href={Config.SERIESMASTER}  className='a' >
                            <i className="fa fa-car"></i> Master
                        </a>
                    </li>
                    <li className={menu === "series" ? "active" : ""} key={menu+Math.random().toString(36).substring(7)}>
                        <a href={Config.SERIES}  className='a' >
                            <i className="fa fa-car"></i> Series
                        </a>
                    </li>

                    <li className={menu === "color" ? "active" : ""} key={menu+Math.random().toString(36).substring(7)}>
                        <a href={Config.COLOR}  className='a' >
                            <i className="fa fa-pie-chart"></i> Color
                        </a>
                    </li>
                   
                </ul>
            </li>
        )

        let poolData = (
            <li id="main_user" className ={ menu == "pool" || menu === "deadstock" || menu === "price" ? "treeview active":"treeview"} key={Math.random().toString(36).substring(7)} >
                <a href="#">
                    <i className="fa fa-file-text-o"></i>
                    <span>Pool Data</span>
                    <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                    </span>
                </a>
                <ul className="treeview-menu">
                    <li className={menu === "pool" ? "active" : ""} key={menu+Math.random().toString(36).substring(7)}>
                    <a href={Config.POOL}  className='a' >
                         <i className="fa fa-file-text-o"></i> Pool Data
                        </a>
                    </li>
                    <li className={menu === "deadstock" ? "active" : ""} key={menu+Math.random().toString(36).substring(7)}>
                         <a href={Config.DEADSTOCK}  className='a' >
                             <i className="fa fa-file-text-o"></i> Deadstock Data
                         </a>
                    </li>
                    <li className={menu === "price" ? "active" : ""} key={menu+Math.random().toString(36).substring(7)}>
                        <a href={Config.PRICE}  className='a' >
                            <i className="fa fa-money"></i> Price
                        </a>
                    </li>
                </ul>
            </li>
        )

        let userData = (
            <li id="main_user" className ={ menu == "tmt_user" || menu === "dealer_user"  ? "treeview active":"treeview"} key={Math.random().toString(36).substring(7)} >
                <a href="#">
                    <i className="fa fa-user"></i>
                    <span>User Data</span>
                    <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                    </span>
                </a>
                <ul className="treeview-menu">
                    <li className={menu == "tmt_user" ? "active" : ""} key={Math.random().toString(36).substring(7)}>
                         <a href={Config.TMTUSER}  className='a' >
                            <i className="fa fa-user"></i> TMT User
                        </a>
                    </li>
                    <li className={menu == "dealer_user" ? "active" : ""} key={Math.random().toString(36).substring(7)}>
                        <a href={Config.DEALERUSER}  className='a' >
                            <i className="fa fa-user"></i> Dealer User
                        </a>
                    </li>
                </ul>
            </li>
        )

        let locatorData = (
            <li  className ={ menu == "wts" || menu === "wtb"  ? "treeview active":"treeview"} key={Math.random().toString(36).substring(7)} >
                <a href="#">
                    <i className="fa fa-list-alt"></i>
                    <span>Locator Data</span>
                    <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                    </span>
                </a>
                <ul className="treeview-menu">
                    <li className={menu == "wts" ? "active" : ""} key={Math.random().toString(36).substring(7)}>
                         <a href={Config.WTS}  className='a' >
                            <i className="fa fa-list-alt"></i> Want To Sale
                        </a>
                    </li>
                    <li className={menu == "wtb" ? "active" : ""} key={Math.random().toString(36).substring(7)}>
                        <a href={Config.WTB}  className='a' >
                            <i className="fa fa-list-alt"></i> Want To Buy
                        </a>
                    </li>
                </ul>
            </li>
        )

        let agreement = (
            <li className={menu === "agreement" ? "active" : ""} key={menu+Math.random().toString(36).substring(7)}>
                  <a href={Config.AGREEMENT}  className='a' >
                    <i className="fa  fa-commenting-o"></i>
                    <span>Agreement</span>
                    <span className="pull-right-container">
                    </span>
                </a>
            </li>
           
        )
      

        let email = (
            <li  className ={ menu.indexOf("email") > -1 ? "treeview active":"treeview"} key={Math.random().toString(36).substring(7)} >
                <a href="#">
                <i className="fa fa-envelope-open"></i>
                    <span>Email</span>
                    <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                    </span>
                </a>
                <ul className="treeview-menu">
                    <li className={menu == "email_buyer" ? "active" : ""} key={Math.random().toString(36).substring(7)}>
                         <a href={Config.EMAIL_BUYER}  className='a' >
                            <i className="fa fa-envelope-open-o"></i> Buyer
                        </a>
                    </li>
                    <li className={menu == "email_seller" ? "active" : ""} key={Math.random().toString(36).substring(7)}>
                        <a href={Config.EMAIL_SELLER}  className='a' >
                            <i className="fa fa-envelope-open-o"></i> Seller
                        </a>
                    </li>
                    <li className={menu == "email_usedcar" ? "active" : ""} key={Math.random().toString(36).substring(7)}>
                        <a href={Config.EMAIL_USEDCAR}  className='a' >
                            <i className="fa fa-envelope-open-o"></i> Trailer
                        </a>
                    </li>
                    <li className={menu == "email_delivery" ? "active" : ""} key={Math.random().toString(36).substring(7)}>
                        <a href={Config.EMAIL_DELIVERY}  className='a' >
                            <i className="fa fa-envelope-open-o"></i> TLT
                        </a>
                    </li>
                    <li className={menu == "email_contact" ? "active" : ""} key={Math.random().toString(36).substring(7)}>
                        <a href={Config.EMAIL_CONTACT}  className='a' >
                            <i className="fa fa-user"></i> Contact
                        </a>
                    </li>
                </ul>
            </li>
        )

        let transport = (
            <li className={menu === "transport" ? "active" : ""} key={menu+Math.random().toString(36).substring(7)}>
                   <a href={Config.TRANSPORT}  className='a' >
                   
                    <i className="fa fa-truck"></i>
                    <span>Transport</span>
                    <span className="pull-right-container">
                    </span>
                </a>
            </li>
           
        )
        
        let zone = (
            <li className={menu === "zone" ? "active" : ""} key={menu+Math.random().toString(36).substring(7)}>
                <a href={Config.ZONE}  className='a' >
                    <i className="fa fa-tachometer"></i> Zone
                </a>
            </li>
           
        )

        let transaction = (
            <li  className ={ menu.indexOf("transaction") > -1 ? "treeview active":"treeview"} key={Math.random().toString(36).substring(7)} >
                <a href="#">
                <i className="fa fa-cart-arrow-down"></i>
                    <span>Transaction</span>
                    <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                    </span>
                </a>
                <ul className="treeview-menu">
                    {permission.poolData && permission.poolData.view == true ? (
                        <li className={menu == "pool_transaction" ? "active" : ""} key={Math.random().toString(36).substring(7)}>
                                <a href={Config.POOL_TRANSACTION}  className='a' >
                                <i className="fa   fa-cart-arrow-down"></i> Pool Transaction
                            </a>
                        </li>
                    ):null}
                    {permission.locatorTransaction && permission.locatorTransaction.view == true ? (
                        <li className={menu == "locator_transaction" ? "active" : ""} key={Math.random().toString(36).substring(7)}>
                            <a href={Config.LOCATOR_TRANSACTION}  className='a' >
                                <i className="fa   fa-cart-arrow-down"></i> Locator Transaction
                            </a>
                        </li>
                    ):null}
                    
                </ul>
            </li>
        )
        
        let master_tlt = (
            <li className={menu === "master_tlt" ? "active" : ""} key={menu+Math.random().toString(36).substring(7)}>
                <a href={Config.MASTER_TLT}  className='a'>
                    <i className="fa fa-address-card"></i>
                    <span>Master TLT</span>
                    <span className="pull-right-container">
                    </span>
                </a>
            </li>
        )

        let master_supply = (
            <li className={menu === "master_supply" ? "active" : ""} key={menu+Math.random().toString(36).substring(7)}>
                <a href={Config.SUPPLY}  className='a'>
                    <i className="fa fa-address-card"></i>
                    <span>Master Supply</span>
                    <span className="pull-right-container">
                    </span>
                </a>
            </li>
        )

        var menu_html = [];
        menu_html.push(dashboard);
        if(permission.dealerData && permission.dealerData.view == true){
            menu_html.push(dealer);
        }

        if(permission.tlt && permission.tlt.view == true){
            menu_html.push(master_tlt); // master tlt
        }

        if(permission.supply && permission.supply.view == true){
            menu_html.push(master_supply); // master tlt
        }
        
        if(permission.seriesData && permission.seriesData.view == true) {
            menu_html.push(serieData)
            // menu_html.push(color_manager)
        }   
            
        if(permission.poolData && permission.poolData.view == true){
            menu_html.push(poolData);
        }
            
        if(permission.locatorData && permission.locatorData.view == true){
            menu_html.push(locatorData);
        }
            
        if(permission.poolTransaction && permission.poolTransaction.view == true){
            menu_html.push(transaction);
        }
        
        if(permission.transport && permission.transport.view == true){
            menu_html.push(transport);
        }
            
        if(permission.agreement && permission.agreement.view == true){
            menu_html.push(agreement)
        }
            
        if(permission.email && permission.email.view == true){
            menu_html.push(email);
        }
            
        if(permission.userData && permission.userData.view == true){
            menu_html.push(userData);
        }
            
        if(permission.systemAdmin && permission.systemAdmin.view == true){
            menu_html.push(systemAdmin);
        }
              
           // menu_html.push(zone);
           
        
        let html = (
            <aside className="main-sidebar">
                <section className="sidebar">
                 
                    <ul className="sidebar-menu" data-widget="tree" key={111}>
                        <li className="header"></li>
                        {menu_html}
                    </ul>
                </section>
            </aside> 
        )
        return (html)
    }
}
