import React, {Component} from 'react';
import dateFormat from 'dateformat';
import '../css/style.css'
import loadgif from '../../../img/loading.gif'
import renderHTML from 'react-render-html';
import Select from 'react-select'
import { Config } from '@jest/types';

const config = require('../../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.email){
            window.location = config.LOGIN
        }

        this.state = {
            permission : userLogin.permission.email,
            userId : userLogin.userId,
            token : userLogin.token,
            loading:false,
            update: false,
            pages : null,
            paymentType: 1,
            emailState: 0,
            emailType : 'seller',
            emailData : {},
            show : "table",
            template : "",
            docNo  : "",
            serie : "",
            model : "",
            bodyNumber : "",
            engineNumber: "",
            color : "",
            email_content : "",
            email : "",
            emails : [],
            now : dateFormat(new Date, "dd/mm/yyyy")
        };

    }

    componentDidMount() {
        this.handleFetch(1)
        this.handleEmailFetch()
        this.state.template  = ` <table width="800" border="0">
        <tbody>
        <tr>
            <td width="500">
                <img src={LOCATOR-IMG}/>
            </td>
            <td valign="top" align="left" width="150">
                <div>เอกสารอ้างอิงเลขที่</div>
                <div>วันที่</div>
            </td>
            <td valign="top" align="left" width="150">
                <div>{DOCNO}</div>
                <div>{DATE}</div>
                
            </td>
        </tr>
        </tbody>
    </table>
    <table width="800" border="0">
        <tbody>
        <tr>
            <td align="center">
                <h4><u>ใบสั่งซื้อรถยนต์ระหว่างตัวแทนจำหน่ายผ่านทางระบบ TOYOTA LOCATOR (ผู้ขาย)</u></h4>
            </td>
        </tr>
        </tbody>
    </table>     
    <table width="800" border="0"> 
        <tbody>       
        <tr>
            <td colSpan="2" height="50">
                {TOPIC1}
            </td>
        </tr>
        <tr>
            <td width="50" height="50">&nbsp;</td>
            <td >
            {TOPIC2}
               
            </td>
        </tr>
        </tbody>   
    </table>

    <table width="800" border="1" cellSpacing="0">
        <tbody>
        <tr>
            <td height="30" align="center">รายการ</td>
            <td align="center">รายละเอียด</td>
        </tr>
        <tr>
            <td height="30" > รุ่นรถ</td>
            <td>
            {SERIE}
            </td>
        </tr>

        <tr>
            <td height="30" >แบบรถ</td>
            <td >
            {MODEL}
            </td>
        </tr>

        <tr>
            <td height="30" >หมายเลขตัวถัง</td>
            <td >
            {BODY-NUMBER}
            </td>
        </tr>


         <tr>
            <td height="30" >หมายเลขเครื่องยนต์</td>
            <td >
            {ENGINE-NUMBER}
            </td>
        </tr>

        <tr>
            <td height="30" >สีรถ</td>
            <td >
            {COLOR}
            </td>
        </tr>

        <tr>
            <td height="30" >วันที่ออกใบแจ้งจำหน่าย</td>
            <td >
            {CREATE-DATE}
            </td>
        </tr>

        <tr>
            <td height="30" >ราคาซื้อ-ขาย รวมภาษี</td>
            <td >
            {PRICE11}
            </td>
        </tr>

        <tr>
            <td height="30" width="250">ค่าชุดอุปกรณ์ตกแต่งพร้อมค่าติดตั้ง</td>
            <td >
            {PRICE12}
            </td> 
         </tr>

        <tr>
            <td height="30" >อื่นๆ</td>
            <td >
            {PRICE13}
            </td>
        </tr>

        <tr>
            
            <td height="30" >ราคาซื้อ-ขาย รวมสุทธิ</td>
            <td >
            {PRICE14}
            </td>
          
        </tr>
        </tbody>
    </table>

    <table width="800">
        <tbody>
        <tr>
            <td height="50" valign="bottom">รายละเอียดการชำระเงิน กับทาง บริษัท โตโยต้า ลีสซิ่ง (ประเทศไทย) จํากัด</td>
        </tr>
        </tbody>
    </table> 

    <table width='800'  border="1" cellSpacing="0">
        <tbody>
        <tr>
            <td width="350"  height="30" align="center">รายการสินค้า</td>
            <td width="50"   align="center">จำนวน</td>
            <td width="200"   align="center">ราคาต่อหน่วย</td>
            <td width="200"   align="center">ราคารวม</td>
        </tr>

        <tr>
            <td>
                <table border="0" width="100%">
                    <tbody>
                        <tr>
                            <td  height="30" >รุ่นรถ</td>
                            <td>{SERIE}</td>
                        </tr>
                        <tr>
                            <td  height="30" >แบบรถ</td>
                            <td>{MODEL}</td>
                        </tr>
                        <tr>
                            <td  height="30" >หมายเลขตัวถัง</td>
                            <td>{BODY-NUMBER}</td>
                        </tr>
                        <tr>
                            <td width="150px" height="30" >หมายเลขเครื่องยนต์</td>
                            <td>{ENGINE-NUMBER}</td>
                        </tr>
                        <tr>
                            <td  height="30" >สีรถ</td>
                            <td>{COLOR}</td>
                        </tr>
                    </tbody>
                </table>
            </td>
          
            <td align="center" valign="top">
                <span>1</span>
            </td>
            <td align="center" valign="top">
                {PRICE21}
            </td>
            <td  align="center" valign="top">
                {PRICE22}
            </td>
           
        </tr>

        <tr>
            <td colSpan="2">
                หมายเหตุ :
                <div>
                    {REMARK}
                </div>
              
            </td>
            <td colSpan="2" >
                <table border="0" width="100%">
                    <tbody>
                        <tr>
                            <td  height="30"  align="right">จำนวนเงิน</td>
                            <td align="right">{PRICE31}</td>
                        </tr>
                        <tr>
                            <td  height="30"  align="right">หักส่วนลด</td>
                            <td align="right">{PRICE32}</td>
                        </tr>
                        <tr>
                            <td  height="30"  align="right">ยอดเงินหลังหักส่วนลด</td>
                            <td align="right">{PRICE33}</td>
                        </tr>
                        <tr>
                            <td  height="30"  align="right">ภาษีมูลค่าเพิ่ม 7 %</td>
                            <td align="right">{PRICE34}</td>
                        </tr>
                        <tr>
                            <td  height="30"  align="right">จำนวนเงินรวมทั้งสิ้น</td>
                            <td align="right">{PRICE35}</td>
                        </tr>
                    </tbody>
                </table>
            </td>
            
        </tr>

         
        </tbody>
    </table>`
    }

    handleCreateClick(){
        document.getElementById("serie").value = ""
        document.getElementById("model").value = ""
        document.getElementById("t1").value = "เรียน  ผู้จัดการฝายขาย บริษัท           จำกัด "
        document.getElementById("t2").value = "ตามที่ บริษัท         จำกัด  ได้มีการตกลงขายรถยนต์  จำนวน 1  คัน มีรายละเอียดดังต่อไปนี้"
        document.getElementById("body-number").value = ""
        document.getElementById("engine-number").value = ""
        document.getElementById("color").value = ""
        document.getElementById("create-date").value = ""
        document.getElementById("price11").value = ""
        document.getElementById("price12").value = ""
        document.getElementById("price13").value = ""
        document.getElementById("price14").value = ""
        document.getElementById("price21").value = ""
        document.getElementById("price22").value = ""
        document.getElementById("price31").value = ""
        document.getElementById("price32").value = ""
        document.getElementById("price33").value = ""
        document.getElementById("price34").value = ""
        document.getElementById("price35").value = ""
        document.getElementById("remark").value = ""
        this.setState({show : 'create'})
    }

    handleChange(val){
        if(val == "serie"){
            this.setState({serie : document.getElementById("serie").value})
        }
        if(val == "model"){
            this.setState({model : document.getElementById("model").value})
        }
        if(val == "bodyNumber"){
            this.setState({bodyNumber : document.getElementById("body-number").value})
        }
        if(val == "engineNumber"){
            this.setState({engineNumber : document.getElementById("engine-number").value})
        }
        if(val == "color"){
            this.setState({color : document.getElementById("color").value})
        }
    }


    handleFetch(page){
        const {token, emailType, emailState} = this.state;
        this.setState({loading:true})
      
        let url = `${config.BASE_URL}/v1/email?type=${emailType}&page=${page}`; 
        if (emailState != 2) {
            url = `${url}&state=${emailState}`
        }

        fetch(url, {
            headers: {
                'authorization': `basic ${token}`
            },
        }).then((response) => {
            return response.json();
        }).then((json) => {
            
            setTimeout(() => {
                let result = json["data"]
                console.log(result)
                 
                if(result){
                   
                    this.setState({show : "table",loading:false ,pages : result})
                    
                }
                else{
                    this.setState({show : "table",loading:false})
                }

              },500);
      
        }).catch(error => {
            this.handleError(error)
        });
    }
    
    handleEmailFetch(){
        const {token} = this.state;

        this.setState({loading:true})
       
        const url = `${config.BASE_URL}/v1/contact?&page=1&sortBy=createAt&perpage=1000`; 
        //console.log("Fetch")
        console.log(url)
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            console.log(result)
            if(result.status){
                var docs = result.data.docs
              
                this.setState({emails : docs})
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });

    }

    handleEmailChange(val){
        this.setState({email : val.value})
    }

    handleSaveClick(value, event){
        const {token ,template,now ,emailType} = this.state
        var serie = document.getElementById("serie").value
        var model = document.getElementById("model").value
        var t1 = document.getElementById("t1").value
        var t2 = document.getElementById("t2").value
        var bodyNumber = document.getElementById("body-number").value
        var engineNumber = document.getElementById("engine-number").value
        var color = document.getElementById("color").value
        var createDate = document.getElementById("create-date").value
        var price11 = document.getElementById("price11").value
        var price12 = document.getElementById("price12").value
        var price13 = document.getElementById("price13").value
        var price14 = document.getElementById("price14").value
        var price21 = document.getElementById("price21").value
        var price22 = document.getElementById("price22").value
        var price31 = document.getElementById("price31").value
        var price32 = document.getElementById("price32").value
        var price33 = document.getElementById("price33").value
        var price34 = document.getElementById("price34").value
        var price35 = document.getElementById("price35").value
        var remark = document.getElementById("remark").value

        var content = template
                        .replace("{LOCATOR-IMG}",`${config.BASE_URL}/v1/images/locator/locator.png`)
                        .replace('{TOPIC1}',t1)
                        .replace('{TOPIC2}',t2)
                        .replace(/\{SERIE\}/g,serie)
                        .replace(/\{MODEL\}/g,model)
                        .replace(/\{BODY-NUMBER\}/g,bodyNumber)
                        .replace(/\{ENGINE-NUMBER\}/g,engineNumber)
                        .replace(/\{COLOR\}/g,color)
                        .replace('{CREATE-DATE}',createDate)
                        .replace('{PRICE11}',price11)
                        .replace('{PRICE12}',price12)
                        .replace('{PRICE13}',price13)
                        .replace('{PRICE14}',price14)
                        .replace('{PRICE21}',price21)
                        .replace('{PRICE22}',price22)
                        .replace('{PRICE31}',price31)
                        .replace('{PRICE32}',price32)
                        .replace('{PRICE33}',price33)
                        .replace('{PRICE34}',price34)
                        .replace('{PRICE35}',price35)
                        .replace('{REMARK}',remark)
                        
        
        const url = `${config.BASE_URL}/v1/email/create`; 
       
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify({  type : emailType ,content : content})
        }).then((response) => {
            return response.json();
        }).then((result) => {
            console.log(result)
            if(result.status){
                var email_content = result.data.content.replace("{DATE}",dateFormat(result.data.createdAt, "dd/mm/yyyy") ).replace(".png/", ".png")
                this.setState({show : "save",docNo : result.data.docNo , email_content : email_content})
            }
                  
                this.setState({loading : false})
                     
        }).catch(error => {
           
            this.handleError(error)
        });
        
    }

    handleViewClick(id){
        const { pages} = this.state
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
        console.log(result[0].content)
        var email_content = result[0].content.replace("{DATE}",dateFormat(result[0].createdDate, "dd/mm/yyyy") ).replace(".png/", ".png").replace(".png/", ".png")
        this.setState({ show : 'view',email_content : email_content})
    }

    handleShowClick(val){
        this.setState({show : val})
    }

    handleSendClick(id){
        const { pages} = this.state
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
        var email_content = result[0].content.replace("{DATE}",dateFormat(result[0].createdDate, "dd/mm/yyyy") ).replace(".png/", ".png")
        this.setState({ show : 'save',email_content : email_content,docNo : result[0].docNo})
    }

    handleResetClick(){
        document.getElementById("search-key").value = ""
        this.handleFetch(1)
    }

    handleSearchClick(){
        const { emailType ,token} = this.state
        var key = document.getElementById("search-key").value;

        const url = `${config.BASE_URL}/v1/email/search`; 
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify({key : key,type : emailType})
        }).then((response) => {
            return response.json();
        }).then((json) => {
            let result = json["data"]
                console.log(result)
                 
                if(result){
                   
                    this.setState({show : "table",loading:false ,pages : result})
                    
                }
                else{
                    this.setState({show : "table",loading:false})
                }
                     
        }).catch(error => {
           
            this.handleError(error)
        });
        
    }

    handlePageClick(page, event){ 
       
            this.handleFetch(page)
        
    
    }

    handleSendMailClick(docNo){
        const { token ,email } = this.state
        
        const url = `${config.BASE_URL}/v1/email/send`;
        fetch(url, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `basic ${token}`
            },
            body: JSON.stringify({ docNo : docNo,mailTo : email})
        }).then((response) => {
            return response.json();
        }).then((result) => {
                console.log(result)
                 if(result.status){
                    this.setState({email : ""})
                    this.handleFetch(1)
                 }
                  
                this.setState({loading : false})
                     
        }).catch(error => {
           
            this.handleError(error)
        });
    }

    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }
    
    handleFilterClick(page, event){ 
        const {searchKey} = this.state;
        var sortKey = document.getElementById('sort-key').value

        let sortOptions = {
            "หลังรับรถ (TLT)" : 0,
            "หลังรับรถ" : 1,
            "ตกลงซื้อขาย" : 2
        }

        let emailState = sortOptions[sortKey]
        this.setState({emailState: emailState});
        
        setTimeout(() => {
            this.handleFetch(page)
        },500);
    }

    render(){
        const { loading ,pages ,email_content,permission,show ,docNo,email,emails,update} = this.state;
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;

            if(pages.docs.length > 0 &&  currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            var contents = null
            if(pages.docs.length > 0){
                contents = []
                for(var index in pages.docs){
                    let item = pages.docs[index]
                    
                    contents.push(
                       
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{ parseInt(pages.pagingCounter) + parseInt(index)}</td>
                        <td align="center">{item["docNo"]}</td>
                        <td align="center">{item["mailTo"]}</td>
                        <td align="center">{dateFormat(item["createDate"], "GMT:dd/mm/yyyy") }</td>
                        <td align="center">
                            <span>
                                <a href={item["status"] == "save" ? "#":`${config.BASE_URL}/v1/xls/${item["docNo"]}`} ><i className="fa fa-download" aria-hidden="true" style={{color: item["status"] == "save" ? "#808080" : "#000000"}}></i></a>
                            </span>
                        </td>
                        <td align="center">
                            <span className={ item["status"] == "save" ? "" : "hidden"}>
                               
                                <button type="button" className="btn btn-success" onClick={this.handleSendClick.bind(this, item["_id"])}>        
                                    Wait For Send
                                </button>
                            </span>
                            <span className={ item["status"] != "save" ? "" : "hidden"}>
                                Completed
                            </span>
                        </td>
                    </tr>
                    )
                    
                }
            }
    
        }

        var email_options = []
     
        for(var i in emails){
            email_options.push( { value: emails[i].email, label: emails[i].email} )     
        }

        return (
            
            <div className="content-wrapper">
               
                <style>{'.pageButton { border: none; outline: none; background-color: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { background-color: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>Email Seller</h3>
                            </div>
                          
                            <div >
                                <div className="box">
                                
                                <div className="box-header">
                                    <div className={(permission.create && show == "table") ? "" : "hidden"}>
                                        <div className='pull-left' style={{cursor : "pointer"}} onClick={this.handleCreateClick.bind(this)}>
                                            <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px"}}>
                                                <i className="fa fa fa-plus"></i>
                                                <span>Create Email</span>
                                                <span className="pull-right-container"></span>
                                            </a>
                                        </div>

                                        <div className="pull-left">
                                            <div className={ show == "table" ? "input-group mb-8" : "hidden"}>
                                                <input type="text" id="search-key" style={{height : "34px",marginLeft:'10px'}}/>
                                                <button className="btn bg-navy btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                                <button className="btn bg-navy btn-reset" onClick={this.handleResetClick.bind(this)} type="button">Reset</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={show == "create" || show == "view" || show == "save" ? "" : "hidden"}>
                                        <div className='pull-left' style={{cursor : "pointer"}}  onClick={this.handleShowClick.bind(this,"table")}>
                                            <a href="#" className='navLink btn btn-success btn-sm ad-click-event' style={{width : "150px",backgroundColor : "black"}}>
                                                <i className="fa fa fa-remove"></i>
                                                    <span style={{marginLeft : '5px'}}>Close</span>
                                                <span className="pull-right-container"></span>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="pull-right">
                                        <select  id="sort-key"  style={{width : "200px",  height : "30px",marginLeft : "5px"}}>
                                            <option>หลังรับรถ (TLT)</option>
                                            <option>หลังรับรถ</option>
                                            <option>ตกลงซื้อขาย</option>
                                        </select>
                                        <button className="btn bg-navy margin" onClick={this.handleFilterClick.bind(this,1)} type="button">Submit</button>
                                    </div>

                                </div>
                                   
                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ show == 'table' ? "box-body" : "hidden"}>
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>
                                            <div>
                                                <div className="col-sm-12">
                                                    <style>{'th {text-align: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                No.
                                                                </th>
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Doc No
                                                                </th>
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Email To
                                                                </th>
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Create
                                                                </th>
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Download
                                                                </th>
                                                                <th className="sorting" tabIndex="2"  rowSpan="1" colSpan="1" >
                                                                Action
                                                                </th>
                                                            

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           { contents }
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className={totalDocs || loading ? "hidden" : "col-md-12"} style={{textAlign : "center"}}>
                                                    Not have data.
                                                </div>
                                            </div>{/* End Row Table */}
                                            
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage - 1)} className={currentPage > 10 ? "pageButton" : "hidden"}>
                                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage + 1)} className={currentPage < totalPages && pageList.length == 10 && totalPages > 10 ? "pageButton" : "hidden"}>
                                                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                          
                                        </div>
                                    </div>

                                    <div className={show == "create" ? "box-body" : "hidden"} >
                                        <div  className="dataTables_wrapper form-inline dt-bootstrap">
                                           <div className="col-md-12">
  
                                               <div className="row modal-box">
                                                    <div className="col-md-12 ">
                                                    <table width="800" border="0">
                                                        <tbody>
                                                        <tr>
                                                            <td width="500">
                                                                <img src={`${config.BASE_URL}/v1/images/locator/locator.png`}/>
                                                            </td>
                                                            <td valign="top" align="left" width="150">
                                                                <div>เอกสารอ้างอิงเลขที่</div>
                                                                <div>วันที่</div>
                                                            </td>
                                                            <td valign="top" align="left" width="150">
                                                                <div></div>
                                                                <div></div>
                                                                
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <table width="800" border="0">
                                                        <tbody>
                                                        <tr>
                                                            <td align="center">
                                                                <h4><u>ใบสั่งซื้อรถยนต์ระหว่างตัวแทนจำหน่ายผ่านทางระบบ TOYOTA LOCATOR (ผู้ขาย)</u></h4>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>     
                                                    <table width="800" border="0"> 
                                                        <tbody>       
                                                        <tr>
                                                            <td colSpan="2" height="50">
                                                                <input type="text" id="t1"  size="100"/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="50" height="50">&nbsp;</td>
                                                            <td >
                                                                <input type="text" id="t2"  size="100"/>
                                                               
                                                            </td>
                                                        </tr>
                                                        </tbody>   
                                                    </table>
  
                                                    <table width="800" border="1" cellSpacing="0">
                                                        <tbody>
                                                        <tr>
                                                            <td height="30" align="center">รายการ</td>
                                                            <td align="center">รายละเอียด</td>
                                                        </tr>
                                                        <tr>
                                                            <td height="30" > รุ่นรถ</td>
                                                            <td>
                                                                <input type="text" id="serie" size="80" onChange={this.handleChange.bind(this,'serie')}/>
                                                            </td>
                                                        </tr>
  
                                                        <tr>
                                                            <td height="30" >แบบรถ</td>
                                                            <td >
                                                            <input type="text" id="model" size="80" onChange={this.handleChange.bind(this,'serie')}/>
                                                            </td>
                                                        </tr>
  
                                                        <tr>
                                                            <td height="30" >หมายเลขตัวถัง</td>
                                                            <td >
                                                            <input type="text" id="body-number" size="80" onChange={this.handleChange.bind(this,'bodyNumber')}/>
                                                            </td>
                                                        </tr>
  		

                                                         <tr>
                                                            <td height="30" >หมายเลขเครื่องยนต์</td>
                                                            <td >
                                                            <input type="text" id="engine-number" size="80" onChange={this.handleChange.bind(this,'engineNumber')}/>
                                                            </td>
                                                        </tr>
  
                                                        <tr>
                                                            <td height="30" >สีรถ</td>
                                                            <td >
                                                                <input type="text" id="color" size="80" onChange={this.handleChange.bind(this,'color')}/>
                                                            </td>
                                                        </tr>
  
                                                        <tr>
                                                            <td height="30" >วันที่ออกใบแจ้งจำหน่าย</td>
                                                            <td >
                                                            <input type="text" id="create-date" size="80"/>
                                                            </td>
                                                        </tr>
  
                                                        <tr>
                                                            <td height="30" >ราคาซื้อ-ขาย รวมภาษี</td>
                                                            <td >
                                                            <input type="text" id="price11" size="80"/>
                                                            </td>
                                                        </tr>
  
                                                        <tr>
                                                            <td height="30" width="250">ค่าชุดอุปกรณ์ตกแต่งพร้อมค่าติดตั้ง</td>
                                                            <td >
                                                            <input type="text" id="price12" size="80"/>
                                                            </td> 
                                                         </tr>
  
                                                        <tr>
                                                            <td height="30" >อื่นๆ</td>
                                                            <td >
                                                            <input type="text" id="price13" size="80"/>
                                                            </td>
                                                        </tr>
  
                                                        <tr>
                                                            
                                                            <td height="30" >ราคาซื้อ-ขาย รวมสุทธิ</td>
                                                            <td >
                                                            <input type="text" id="price14" size="80"/>
                                                            </td>
                                                          
                                                        </tr>
                                                        </tbody>
                                                    </table>
  
                                                    <table width="800">
                                                        <tbody>
                                                        <tr>
                                                            <td height="50" valign="bottom">รายละเอียดการชำระเงิน กับทาง บริษัท โตโยต้า ลีสซิ่ง (ประเทศไทย) จํากัด</td>
                                                        </tr>
                                                        </tbody>
                                                    </table> 
 
                                                    <table width='800'  border="1" cellSpacing="0">
                                                        <tbody>
                                                        <tr>
                                                            <td width="350"  height="30" align="center">รายการสินค้า</td>
                                                            <td width="50"   align="center">จำนวน</td>
                                                            <td width="200"   align="center">ราคาต่อหน่วย</td>
                                                            <td width="200"   align="center">ราคารวม</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <table border="0" width="100%">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td  height="30" >รุ่นรถ</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td  height="30" >แบบรถ</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td  height="30" >หมายเลขตัวถัง</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="150px" height="30" >หมายเลขเครื่องยนต์</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td  height="30" >สีรถ</td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                          
                                                            <td align="center" valign="top">
                                                                <span>1</span>
                                                            </td>
                                                            <td align="center" valign="top">
                                                                 <input type="text" id="price21"/>
                                                            </td>
                                                            <td  align="center" valign="top">
                                                                <input type="text" id="price22"/>
                                                            </td>
                                                           
                                                        </tr>
  
                                                        <tr>
                                                            <td colSpan="2">
                                                                หมายเหตุ :
                                                                <div>
                                                                    <textarea id="remark" style={{width :'100%',height : '100px'}}></textarea>
                                                                </div>
                                                              
                                                            </td>
                                                            <td colSpan="2" >
                                                                <table border="0" width="100%">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td  height="30"  align="right">จำนวนเงิน</td>
                                                                            <td align="right"><input type="text" id="price31"/></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td  height="30"  align="right">หักส่วนลด</td>
                                                                            <td align="right"><input type="text" id="price32"/></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td  height="30"  align="right">ยอดเงินหลังหักส่วนลด</td>
                                                                            <td align="right"><input type="text" id="price33"/></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td  height="30"  align="right">ภาษีมูลค่าเพิ่ม 7 %</td>
                                                                            <td align="right"><input type="text" id="price34"/></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td  height="30"  align="right">จำนวนเงินรวมทั้งสิ้น</td>
                                                                            <td align="right"><input type="text" id="price35"/></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            
                                                        </tr>
  
                                                         
                                                        </tbody>
                                                    </table>

                                                    </div>
                                                                
                                                </div>
        
                                                <div className="row modal-box">
                                                   <div className="col-md-12" style={{textAlign : "center"}}>
                                                        <div className={(permission.create) ? "" : "hidden"}>
                                                        <button type="button" className="btn btn-primary" onClick={this.handleSaveClick.bind(this)} style={{width : "300px",height : "46px"}}>
                                                             <i className={update ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>
                                                                Save
                                                        </button>
                                                        </div>
                                                    </div> 
                                                </div>
                                                    
                                            </div>
                                            
                                            

                                        </div>
                                    </div>
                                    
                                    <div  className={show == "view" ? "box-body" : "hidden"}>
                                        <div className="col-md-12">
                                             {renderHTML(email_content)}
                                        </div>
                                    </div>

                                    <div  className={show == "save" ? "box-body" : "hidden"} >
                                        <div className="col-md-12" style={{marginBottom: "15px"}}>
                                           
                                            {renderHTML(email_content)}
                                        </div>
                                        <div className="row"  style={{marginBottom: "15px"}}>
                                            <div  style={{textAlign : "center",width : "800px"}}>
                                                <span style={{color:'red'}}>*</span>
                                                <span style={{fontWeight: "bold"}}>Send Email To : </span>
                                               
                                                
                                            </div>
                                           
                                        </div>
                                        <div className="row" >
                                            <div className="col-md-12" style={{textAlign : "center"}}>
                                                <div  style={{textAlign : "left",width : "80%"}}>
                                                <Select 
                                                    value={email_options.filter(option => option.label == email)}
                                                    options={email_options}
                                                    onChange= {value => this.handleEmailChange(value)}
                                                />
                                                </div>    
                                             </div> 
                                            
                                             
                                        </div>
                                       
                                        <div className="row" style={{marginTop : "20px"}}>
                                            
                                            <div className="col-md-12" style={{textAlign : "center"}}>
                                                <div  style={{textAlign : "center",width : "800px"}}>
                                                    <button type="button" className="btn btn-success" onClick={this.handleSendMailClick.bind(this,docNo)} style={{width : "300px",height : "46px"}}>
                                                        <i className="fa fa-envelope-open-o" aria-hidden="true" style={{marginRight : "5px"}}></i>
                                                            Send
                                                        <i className="fa fa-arrow-circle-o-right" aria-hidden="true" style={{marginLeft : "5px"}}></i>
                                                    </button>
                                                </div>    
                                             </div> 
                                             
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}