import React, {Component} from 'react';
import dateFormat from 'dateformat';
import {Parser} from 'json2csv'
import styled from 'styled-components'
// const { Parser } = require('json2csv')
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import './css/style.css'
import loadgif from '../../img/loading.gif'
import { CSVLink } from "react-csv";
import Papa from "papaparse"
import excelImage from '../../img/excel.png'
import Select from 'react-select'

var config = require('../share-components/Config');

export default class Content extends Component {
    
    constructor() {
        super();
        var userLogin = JSON.parse(localStorage.getItem('session_login_user'))
        if(!userLogin || !userLogin.permission.locatorData){
            window.location = config.LOGIN
        }

        let imagePositions = {
            "FC": "มุมหน้า",
            "BC": "มุมหลัง",
            "F": "ด้านหน้า",
            "B": "ด้านหลัง",
            "E": "เครื่องยนต์",
            "TL": "กระโปรงท้าย",
            "FR": "หน้าขวา",
            "FL": "หน้าซ้าย",
            "BS": "เบาะหลัง",
            "C": "คอนโซล",
            "CLF": "คอนโซล ซ้าย/ขวา",
            "G": "เกียร์",
            "FI": "ฟอร์มตรวจรถ",
        }

        this.state = {
            imagePositions: imagePositions,
            permission : userLogin.permission.locatorData,
            token : userLogin.token,
            loading:false,
            upload : false,
            
            preview : false,
            photoIndex: 0,

            setPassword : false,
            roleId : "1",
            searchKey : "",
            sortBy : "createdAt",
            type : "wts",
            sortType : -1,
            userId : -1,
            csvData : [],
            export_file : "",
            seriesCode : "",
            allStatus : {},
            status :"",
            perPage : 50,
            pages :{
                docs : {},
                totalDocs : 0,
                perPage : 0,
                hasPrevPage : false,
                hasNextPage : true,
                currentPage : 1,
                totalPages : 1000,
                pageIndex: 1,
                prevPage: null,
                nextPage : 2
            },
            showItems:{}
        };

    }
  
    sortOptions={
        "Type" : "type",
        "Price" : "price",
        "Status" : "status",
        "Create"    : "create",
    }

    componentDidMount() {
        this.handleFetch(1)
        this.handleGetMaster()
    }

    handlePhotoOnChange() {
        //console.log('change')
    }
    handleFetch(page){
        const {pages,sortBy,sortType, token ,type ,perPage} = this.state;
        //console.log(pages)
        if(page < 1 || page > pages.totalPages){
            return
        }
        document.getElementById('search-key').value = ""
        this.setState({loading:true , searchKey : ""})
       
        const url = `${config.BASE_URL}/v1/product/search?page=${page}&sortBy=${sortBy}&sortType=${sortType}&perPage=${perPage}&type=${type}`; 
        //console.log(url)
        fetch(url,{
            headers: {
                'authorization': `basic ${token}`
            },
        }).then((response) => {
            return response.json();
        }).then((result) => {

            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
           
            
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleSearchClick(page){
        var key = document.getElementById("search-key").value;
        if(key == ""){
            return
        }

        this.setState({loading:true , searchKey : key})

        setTimeout(() => {
            this.handleSearch(1)
          },500);
        
    }

    downloadCSV(args) {  

        let filename = args.filename || 'export.csv';
        let columns = args.columns || null;
        
        let csv = Papa.unparse({ data: args.data, fields: columns},{skipEmptyLines: "greedy"})
        if (csv == null) return;
        
        var blob = new Blob([csv]);
        
        if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
            window.navigator.msSaveBlob(blob, args.filename);
        else
        {
            //console.log(csv)
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(blob, {type: "data:application/csv;charset=utf-8;"});
            a.download = filename;
            document.body.appendChild(a);
            a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(a);
        }
    }

      
    handleExport(){
        const {pages,sortBy,sortType, token ,type ,status,seriesCode} = this.state;

        const url = `${config.BASE_URL}/v1/product/search?page=1&sortBy=${sortBy}&sortType=${sortType}&perPage=${pages.totalDocs}&type=${type}`; 
        
        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            
            if(result.status){
                 
                 var data = []
                 var index = 1
                 result.data.docs.forEach(val => {
                     var insert = true;
                     var pid_date = ""
                    if(val["pidDate"]){
                        pid_date = dateFormat(val.pidDate,'GMT:dd-mmm-yyyy')
                       
                    }
                    
                    let create_date = dateFormat(val.createdAt,'GMT:dd-mmm-yyyy')
                       
                    var dealerCode = "-"
                    var dealerNamed = "-"
                    
                    let vinNo = val["bodyNumber"]
                    if (val["dealerInfo"]) {
                        dealerCode = val["dealerInfo"]["dealerCode"]
                        dealerNamed = val["dealerInfo"]["dealerNameTH"]
                    }

                    var username = "-"
                    if (val["userInfo"]) {
                        username = val["userInfo"]["username"]
                    }

                    let obj = {
                        'No': index,
                        'User Name': username,
                        'Dealer Code': `="${dealerCode}"`,
                        'Dealer Name':dealerNamed,
                        'Vin No.': vinNo,
                        'SFX': `="${val.sfx}"`,
                        'Series': val.series,
                        'Model': val.model,
                        'Color Code': `="${val.colorCode}"`,
                        'Color Name': val["colorInfo"]["color"],
                        'Invoice Date': pid_date,
                        'Price': config.formatter( parseInt(val.price), 0)||"0",
                        'Type': type,
                        'Status': val.statusInfo.status.toUpperCase(),
                        'Create Date': create_date,
                        'Stock status': val.stockInfo.name,
                        'Payment Chanel': val.paymentInfo.name,
                        'หมายเหตุ': val.remark || "-"
                    }
                    
                    if(seriesCode != 'All' && seriesCode != '' && val.series != seriesCode){
                        insert = false
                    }
                    if(status != 'All' && status !='' && val.statusInfo.status != status){
                        insert = false
                    }
                    if(insert){
                        index++;
                        ////console.log(obj)
                        data.push(obj)
                    }
                        
                })

                if(data.length == 0){
                    alert("ไม่พบรายการที่ท่านเลือก")
                    return "";
                } else {
                    const fields = [
                        'No',
                        'User Name',
                        'Dealer Code',
                        'Dealer Name',
                        'Vin No.' ,
                        'SFX',
                        'Series' ,
                        'Model',
                        'Color Code',
                        'Color Name',
                        'Invoice Date',
                        'Price',
                        'Type',
                        'Status',
                        'Create Date',
                        'Stock status',
                        'Payment Chanel',
                        'หมายเหตุ'
                    ];
                    const opts = { fields };
                    
                    try {
                        const parser = new Parser(opts);
                        const csv = parser.parse(data);
                        var d = new Date();
                        var dd = (d.getDate() >= 10 ) ? d.getDate() : '0' + d.getDate()
                        var mm = ((d.getMonth() +1) >= 10) ? d.getMonth() +1 : '0' + d.getMonth() +1
                        var yyyy = d.getFullYear()
                        var hh = d.getHours()
                        var ii = d.getMinutes()
                        var ss = d.getSeconds()
                        var filename = `export_locator_wts_${dd}${mm}${yyyy}_${hh}${ii}${ss}.csv`
                        
                        var hiddenElement = document.createElement('a');
                        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI("\ufeff"+csv);
                        hiddenElement.target = '_blank';
                        hiddenElement.download = filename;
                        hiddenElement.click();
                        
                    } catch (err) {
                        console.error(err);
                    }
                    
                }
              
            }
            else{
                this.handleError(result.message)
            }
           
            
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleSearch(page){
        const {perPage,searchKey,sortBy, roleId ,token ,type} = this.state;
        const url = `${config.BASE_URL}/v1/product/search/key`; 
        this.setState({loading:true })

        var params ={
            "key" : searchKey ,
            "roleId" : roleId ,
            "page" : page ,
            "perPage" : perPage,
            "sortBy" : sortBy,
            "type" : type
        }
       //console.log("Search")
       //console.log(params)
        fetch(url, {
            method: "post",
            headers: {
                'authorization': `basic ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
                this.setState({pages: result.data});
           
                setTimeout(() => {
                    this.setState({loading:false})
                  },500);
            }
            else{
                this.handleError(result.message)
            }
      
        }).catch(error => {
            this.handleError(error)
        });
    }

     
    handleGetMaster(){
        const { token ,seriesCode } = this.state;

        const url = `${config.BASE_URL}/v1/seriesMaster?page1&sortBy=&perpage=2000&status=Active`; 

        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.status){
             
                this.setState({master: result.data.docs , seriesCode : result.data.docs[0].seriesCode});
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });

    }

    handleModalExport(){
        this.modalAction("modal-export","open")
    }

    handleSeriesChange(val){
    
        this.setState({ seriesCode : val.value})
    }

    handleStatusChange(val){
        
        this.setState({ status : val.value})
    }
     
    modalAction(id,status){
        
        if(status == "open")
            document.getElementById(id).style.display = 'inline'
        else
            document.getElementById(id).style.display = 'none'
    }

    handlePageClick(page, event){ 
        const {searchKey} = this.state;
        ////console.log("search " + searchKey)
        if(searchKey){
            
            this.handleSearch(page)
        }
        else{
            this.handleFetch(page)
        }
    
    }

    handleSortClick(page, event){ 
        const {searchKey} = this.state;
        var sortKey = document.getElementById('sort-key').value
        var sortBy = this.sortOptions[sortKey]
        this.setState({sortBy:sortBy,sortType : 1});
    
        setTimeout(() => {
            if(searchKey){
                this.handleSearch(page)
            }
            else{
                this.handleFetch(page)
            }
          },500);
        
        
    }

    handleCreateClick(value, event){
       
        document.getElementById("postId").value = -1
        document.getElementById("series").value = ""
        document.getElementById("sfx").value = ""
        document.getElementById("model").value =""
        document.getElementById("colorName").value = ""
        document.getElementById("colorCode").value = ""
        document.getElementById("pid_date").value =""
        document.getElementById("carCondition").value = ""
        document.getElementById("bodyNumber").value = ""
        document.getElementById("engineNumber").value = ""
        document.getElementById("blame").value = ""
        document.getElementById("blameDetail").value = ''
        document.getElementById("price").value = ""
        document.getElementById("payCondition").value=""
        document.getElementById("type").value=""
        document.getElementById("remark").value=""
        document.getElementById("status").value=""

        this.setState({userId: -1 ,setPassword : true})
        this.modalAction("modal-add-user","open")
    
    }

    handleEditClick(id, event){
        const { pages } = this.state;
        var result = pages.docs.filter(obj => {
            return obj._id == id
        })
    
        document.getElementById("postId").value = result[0]._id
        document.getElementById("series").value = result[0].series
        document.getElementById("sfx").value = result[0].sfx
        document.getElementById("model").value = result[0].model
        document.getElementById("colorName").value = result[0].colorName
        document.getElementById("colorCode").value = result[0].colorCode
        document.getElementById("pid_date").value = result[0].pid_date
        document.getElementById("carCondition").value = result[0].carCondition
        document.getElementById("bodyNumber").value = result[0].bodyNumber
        document.getElementById("engineNumber").value = result[0].engineNumber
        document.getElementById("blame").value = result[0].blame
        document.getElementById("blameDetail").value = result[0].blameDetail
        document.getElementById("price").value = result[0].price
        document.getElementById("payCondition").value= result[0].payCondition
        document.getElementById("type").value= result[0].type
        document.getElementById("remark").value= result[0].remark
        document.getElementById("status").value= result[0].status

        this.modalAction("modal-add-user","open")
    }

    handleSaveClick(value, event){
        
    }

    didPreviewPhotoClick(value, event){
        const { token , seriesCode, pages } = this.state;
        var params = pages.docs[value] || {}
        
        const url = `${config.BASE_URL}/v1/product/information?id=${pages.docs[value]["_id"]}`; 

        fetch(url, {
            headers: {
                'authorization': `basic ${token}`,
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            if(result.statusCode == 200){
                let images = result["data"][0]["imageInfo"] || []
                var showItems = images.map(x => {
                    return {
                        original: `${config.ROOTURL}${x["imagePath"]}`,
                        thumbnail: `${config.ROOTURL}${x["imagePath"]}`,
                        positionCode: x["positionCode"]
                    }
                })
                params["imageInfo"] = showItems
                //console.log(result)

                this.setState({showItems: params});
            }
            else{
                this.handleError(result.message)
            }
           
      
        }).catch(error => {
            this.handleError(error)
        });
    }

    handleFileUpload(value){
        const { roleId,token } = this.state;
        const browserFiles = document.querySelector('input[type="file"]')
      
        const url = `${config.BASE_URL}/v1/postsale/upload`;
        
        
        var formData = new FormData()
        formData.append('roleId',roleId)
        for (const file of browserFiles.files) {
          //  //console.log(file)
            formData.append('file',file,file.name)
            
        }
        
        this.setState({upload : true })
        fetch(url, {
            method: 'POST',
            headers: {
                'authorization': `basic ${token}`
             },
            body: formData
        }).then(function(response) {
            return response.json();
        }).then(result => this.onSetFileResult(value, result)).catch(error => {
            this.setState({ upload: false });
            alert(error)
        });
        
    }

    onSetFileResult = (value, result) => {
        
        if (result["status"] == true){
            setTimeout(() => {
                this.setState({upload:false})
                this.modalAction("modal-import","close")
                this.handleFetch(1)
               
           },1000)
        }else{
            this.setState({upload:false})
            this.handleError(result.message)
        }

    }

    handleSetPasswordClick(){
        const {setPassword} = this.state;
        this.setState({setPassword: !setPassword})
    }

    handleError(error){
        alert(`${error}`);
        if(error == "token has expire."){
            localStorage.clear()
            window.location = config.LOGIN
        }
    }

    rangeOfMonth(distance = 0) {
        if (distance >= 0 && distance <= 3) {
            return "0-3 เดือน"
        }else if (distance > 3 && distance <= 6) {
            return "4-6 เดือน"
        }else if (distance > 6 && distance <= 9) {
            return "7-9 เดือน"
        }else if (distance > 9 && distance <= 12) {
            return "10-12 เดือน"
        }

        return ">12 เดือน"
    }

    render(){
        const { pages, preview, imagePositions, showItems, photoIndex, loading ,searchKey,upload,permission ,csvData,export_file,master,seriesCode,status} = this.state;
        var startPage = 1;
        var stopPage = 10;
        var pageList = [];
        var contents = null;
        var currentPage = 0;
        var totalPages = 0;
        var totalDocs = 0;
        
        const HoverText = styled.p`
            opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
            background: #eee;
            margin: 10px;
            padding: 10px;
            border-radius: 2px; 
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            :hover {
                cursor: pointer;
                opacity: 0.9;
            }
        `

        var images = showItems["imageInfo"] || []
        let displayImages = images.map(x=> {
            return (
                <div key={Math.random().toString(36).substring(7)}>
                    <img src={x.original}/>
                    <HoverText>{imagePositions[`${x.positionCode}`]}</HoverText>
                </div>
            )
        })

        if(pages){
            currentPage = pages.page;
            totalPages = pages.totalPages;
            totalDocs = pages.totalDocs;
            //console.log(pages.page)
            if(currentPage > 10){
                startPage = currentPage
                stopPage = currentPage + 9
            } 
    
            if(stopPage > totalPages){
                stopPage = totalPages
            }
            var pageList = [];
            for (var i = startPage; i <= stopPage ; i++) {
                
                pageList.push(
                    <li key={Math.random().toString(36).substring(7)}>
                        <button type="button" key={ 'p' + i} onClick={this.handlePageClick.bind(this, i)} className={currentPage == i ? "pageButton active":"pageButton"}>{ i }</button>
                    </li>
                );
            } 
            
            var contents = null
            if(pages.docs.length > 0){
                contents = []
                for(var index in pages.docs){
                    let item = pages.docs[index]
                    var dealerCode = "-"
                    var dealerNamed = "-"
                    let vinNo = item["bodyNumber"]
                    if (item["dealerInfo"]) {
                        
                        dealerCode = item["dealerInfo"]["dealerCode"]
                        dealerNamed = item["dealerInfo"]["dealerNameTH"]
                    }
                    
                    let pid_date = ""
                    if(item["pidDate"]){
                        pid_date = dateFormat(item["pidDate"],'GMT:dd-mmm-yyyy')
                       
                    }
                   
                    let create_date = dateFormat(item["createdAt"],'GMT:dd-mmm-yyyy')
                    contents.push(
                       
                        <tr bgcolor={index % 2 == 0 ? "#f9f9f9":"#FFFFFF"} role="row" className="odd" key={Math.random().toString(36).substring(7)}>
                        <td align="center">{ parseInt(pages.pagingCounter) + parseInt(index)}</td>
                        <td align="center">{item["userInfo"]["username"] || '-'}</td>
                        <td align="left">{dealerCode}</td>
                        <td align="left">{dealerNamed}</td>
                        <td align="left">{vinNo}</td>
                        <td align="center">{item["series"]}</td>
                        <td align="center">{item["model"]}</td>
                        <td align="center">{item["sfx"]}</td>
                        <td align="center">{item["colorCode"]}</td>
                        <td align="left">{item["colorInfo"]["color"]}</td>
                        <td align="center">{pid_date}</td>
                        <td align="center">{config.formatter( parseInt(item["price"]), 0)}</td>
                        <td align="center">{item["type"]}</td>
                        <td align="center">{(item["statusInfo"]["status"] || "-").toUpperCase()}</td>
                        <td align="center">{create_date}</td>
                       
                        <td align="center">
                            <button type="button" id={item["_id"]} onClick={this.didPreviewPhotoClick.bind(this, index)} className="btn btn-default" data-toggle="modal" data-target="#modal-default">
                                <i className="fa fa-eye" aria-hidden="true"></i>
                            </button>
                        </td>
                    </tr>
                    )
                    
                }
            }
        }
        
        var serie_options = []
        serie_options.push( { value:'All', label: "All" } )     
        for(var i in master){
            serie_options.push( { value: master[i].seriesCode, label: master[i].seriesCode } )     
        }

        var status_options = [
            {value : "All" , label : "All"},
            {value : "online" , label : "Online"},
            {value : "saled" , label : "Saled"},
            {value : "cancel" , label : "Cancel"},
            {value : "expire" , label : "Expire"},
            {value : "reject" , label : "Reject"}
        ]
       
        let dateDistance = parseFloat(showItems["dateDistance"] || "0")
        var displayDateRange = this.rangeOfMonth(dateDistance)
        
        return (
            
            <div className="content-wrapper">
                <style>{'.pageButton { border: none; outline: none; backgroundColor: #f1f1f1; cursor: pointer;} .active, .pageButton:hover { backgroundColor: blue; color: white;}'}</style>
                <section className="content-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-header">
                                <h3 style={{float : "left"}}>Want To Sale</h3>
                                <div className={(permission.import) ? "" : "hidden"}>
                                <span style={{ float : "right",cursor: "pointer"}}> 
                                <button type="button" onClick={this.handleModalExport.bind(this)} className="btn btn-success" style={{ width: '150px', height: '40px', marginTop: '16px',marginLeft: '8px'}}>
                                    <img src={excelImage}  style={{cursor:'pointer',width:'29px'}}/>
                                    Export Data
                                    </button> 
                                </span>
                                </div>
                            </div>
                          
                            <div>
                                <div className="box">
                                  <div className="box-header">
                                  <div className="pull-left">
                                          <div className="input-group mb-3">
                                              <input type="text" id="search-key" style={{height : "34px"}}/>
                                              
                                                  <button className="btn btn-outline-secondary btn-search" onClick={this.handleSearchClick.bind(this,1)} type="button">Search</button>
                                                  <button className="btn btn-outline-secondary btn-reset" onClick={this.handleFetch.bind(this,1)} type="button">Reset</button>
                                          </div>
                                      </div>
                                    <div className="pull-right">
                                          <label>Sort By</label>
                                              <select  id="sort-key"  style={{width : "200px",  height : "30px",marginLeft : "5px"}}>
                                                  <option>Type</option>
                                                  <option>Price</option>
                                                  <option>Status</option>
                                                  <option>Create</option>
                                                  
                                              </select>
                                              <button className="btn btn-outline-primary btn-submit" onClick={this.handleSortClick.bind(this,1)} type="button">Submit</button>
                                    </div>
                                    
                                </div>
                                <div className="box-header">
                                   <span className={searchKey ? "" : "hidden"}>Seach "{searchKey}" ,please use reset button to refresh query.</span>
                                   <p>
                                   <span>Found <b>{pages.totalDocs}</b> record(s).</span>
                                   </p>
                                </div>

                                    <div className={loading ? "" : "hidden"}>
                                        <div className="spinner-border" role="status" style={{textAlign : 'center'}}>
                                            <img src={loadgif}/>
                                        </div>
                                    </div>
                                    
                                    <div className={ loading ? "hidden" : "box-body"} style={{overflowX : 'auto'}}>
                                        <div id="example2_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                                            <div className="row">
                                                <div className="col-sm-6"></div>
                                                <div className="col-sm-6"></div>
                                            </div>

                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <style>{'th {textAlign: center;}'}</style>
                                                    <table id="example2" className="table table-bordered table-hover dataTable" role="grid" aria-describedby="example2_info">
                                                        <thead>
                        
                                                            <tr bgcolor="#d3d3d3" role="row">
                                                                <th width="3%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                No
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                User name
                                                                </th>

                                                                <th width="5%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Dealer Code
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Dealer Name
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Vin No.
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Series
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                 Model
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                SFX
                                                                </th>
                                                             
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Color Code
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Color Name
                                                                </th>
                                                                
                                                                <th width="8%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                               Invoice Date
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Price
                                                                </th>

                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Type
                                                                </th>
                                                                
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Status
                                                                </th>

                                                                <th width="8%" className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Create
                                                                </th>
   
                                                                <th className="sorting" tabIndex="1"  rowSpan="1" colSpan="1" >
                                                                Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           { contents }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>{/* End Row Table */}
                                        
                                            <div className={totalDocs ? "row" : "hidden"}>
                                                <div className="col-sm-12">
                                                    <div className="dataTables_info" className={totalPages > 0 ? "dataTables_info" : "hidden"} role="status" aria-live="polite">
                                                        <span>Showing {currentPage} of {totalPages} Pages</span>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="dataTables_paginate paging_simple_numbers" >
                                                        <ul className="pagination">
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage - 1)} className={currentPage > 10 ? "pageButton" : "hidden"}>
                                                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                            </button>
                                                            {pageList}
                                                            <button type="button" onClick={this.handlePageClick.bind(this, currentPage + 1)} className={currentPage < totalPages && pageList.length == 10 && totalPages > 10 ? "pageButton" : "hidden"}>
                                                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                            </button>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="modal" id="modal-import" tabIndex="-1" role="dialog">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Import</h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalAction.bind(this,"modal-import","close")} style={{marginTop : '-23px'}}>
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                        </div>
                                                        <div className="modal-body">
                                                       {/* <span className="file btn btn-lg btn-primary upload-div" > */}
							                               
							                                <input type="file" id="csv_file" name="file"/>
                                                           
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.modalAction.bind(this,"modal-import","close")}>Close</button>
                                                            <button type="button" className="btn btn-primary" onClick={this.handleFileUpload.bind(this)}>
                                                                <i className={upload ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>   
                                                                Upload
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="modal" id="modal-export" tabIndex="-1" role="dialog">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Export Filter</h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.modalAction.bind(this,"modal-export","close")} style={{marginTop : '-23px'}}>
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div>Series</div>
                                                            <Select 
                                                                        value={serie_options.filter(option => option.value == seriesCode)}
                                                                        options={serie_options}
                                                                        onChange= {value => this.handleSeriesChange(value)}
                                                                       
                                                                    />

                                                            <div style={{marginTop : '20px'}}>Status</div>
                                                            <Select 
                                                                        value={status_options.filter(option => option.value == status)}
                                                                        options={status_options}
                                                                        onChange= {value => this.handleStatusChange(value)}
                                                                       
                                                                    />
                                                        </div>
                                                        <CSVLink id="csvdownload" data={csvData}  filename={export_file}></CSVLink>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary" style={{width : '120px'}} data-dismiss="modal" onClick={this.modalAction.bind(this,"modal-export","close")}>Close</button>
                                                            <button type="button" className="btn btn-primary" style={{width : '200px'}} onClick={this.handleExport.bind(this)}>
                                                                <i className={upload ? "fa fa-spinner fa-spin fa-3x fa-fw" : "hidden"} style={{fontSize : "14px"}}></i>   
                                                                Export
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={preview ? "modal fade in":"modal fade"} id="modal-default" style={{display: preview ? 'block':'none'}}>
                                <div className="modal-dialog" style={{width:"45%"}}>
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span></button>
                                        <h3 className="modal-title">รายละเอียด</h3>
                                    </div>
                                    
                                    <div className="modal-body">
                                        <p><b>User name :</b> {showItems["userInfo"] == undefined ? "-":showItems["userInfo"]["username"]}</p>
                                        <p><b>Dealer :</b> {showItems["dealerInfo"] == undefined ? undefined:`(${showItems["dealerInfo"]["dealerCode"]}) ${showItems["dealerInfo"]["dealerNameTH"]}`}</p>
                                        <p><b>Series :</b> {showItems["series"] || "-"}</p>
                                        <p><b>Model :</b> {showItems["model"] || "-"}</p>
                                        <p><b>SFX :</b> {showItems["sfx"] || "-"}</p>
                                        <p><b>Color :</b> {showItems["colorInfo"] == undefined ? undefined :`${showItems["colorInfo"]["color"]}(${showItems["colorInfo"]["colorCode"]})` || "-"}</p>
                                        <p><b>หมายเลขตัวถัง :</b> {showItems["bodyNumber"] || "-"}</p>
                                        <p><b>หมายเลขเครื่องยนต์ :</b> {showItems["engineNumber"] || "-"}</p>
                                        <p><b>วันที่ออกรถ (Invoice Date) :</b> {`${showItems["pid_date"] || "-"} ( ${displayDateRange} )`}</p>
                                        <p><b>สภาพรถ :</b> {parseInt(showItems["blame"]) == 0 ? (showItems["carCondition"] || "-"): "<100%"}</p>
                                        <p><b>จำนวนตำหนิ :</b> {parseInt(showItems["blame"]) == 0 ? "ไม่มี":`${parseInt(showItems["blame"])} ตำแหน่ง`}</p>
                                        <p><b>รายละเอียดตำหนิ :</b> {showItems["blameDetail"] || "ไม่ระบุ"}</p>
                                        <p><b>รูปถ่าย :</b></p>
                                        {
                                            displayImages.length != 0 ? (
                                                <div style={{width: "100%"}}>
                                                    <Carousel onChange={this.handlePhotoOnChange} dynamicHeight={true} showIndicators={true} showThumbs={false}>
                                                        {displayImages}
                                                    </Carousel>
                                                </div>
                                            ):(
                                                <div align="center" style={{"backgroundColor":"#f9f9f9", "height": "250px", "width": "100%", "display": "table"}}> 
                                                <p style={{"textAlign": "center", "verticalAlign": "middle", "display": "table-cell"}}>ไม่มีข้อมูลรูปภาพ</p>
                                                </div>
                                            )
                                        }
                                        <br/>
                                        <p><b>ช่องทางการชำระเงิน :</b> {showItems["paymentInfo"] == undefined ? undefined :`${showItems["paymentInfo"]["name"]}` || "-"}</p>
                                        <p><b>สถานะ Supply :</b> {showItems["stockInfo"] == undefined ? undefined :`${showItems["stockInfo"]["name"]}` || "-"}</p>
                                        <p><b>หมายเหตุ :</b><br/> {showItems["remark"] || "-"}</p>
                                        <p><b>ราคาขาย/บาท :</b> {`${config.formatter( parseInt(showItems["price"]) )}` || "-"}</p>
                                        <p><b>เงื่อนไขการชำระเงิน :</b> {showItems["payCondition"] || "-"}</p>
                                        <p><b>วันเวลาที่สร้าง :</b> { showItems["createdAt"] != undefined ? dateFormat(showItems["createdAt"], 'GMT:dd-mmm-yyyy HH:MM'): "-"}</p>
                                        {
                                            showItems["admin_cancel_remark"] != undefined ? (
                                                <p><b>สถานะ :</b> {showItems["statusInfo"] != undefined ? `${showItems["statusInfo"]["status"].toUpperCase()} ( ${showItems['admin_cancel_remark']} )`:'-'}</p>
                                            ):(
                                                <p><b>สถานะ :</b> {showItems["statusInfo"] != undefined ? `${(showItems["statusInfo"]["status"] || "-").toUpperCase()} ${showItems["cancelRemark"] == undefined || showItems["cancelRemark"].length == 0 ? "":`( ${showItems["cancelRemark"]} )`}`:undefined || "-"}</p>
                                            )
                                        }
                                        
                                        <p><b>วันเวลาสถานะ :</b> {showItems["statusInfo"] != undefined ? dateFormat(showItems["updatedAt"],'GMT:dd-mmm-yyyy HH:MM'):undefined || "-"}</p>
                                    </div>
                                    {/* <div className="modal-footer">
                                        <button type="button" className="btn btn-primary">Save changes</button>
                                    </div> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        
        )
    }   
}